<template>
  <div class="general-class-customer">
    <div @click="openConfirm('normal')" v-if="isPopupCustomer || isNewUser"
         :class="{'show-counter': isPopupCustomerCounters, 'show-contracts': isPopupCustomerContracts, 'confirm-exit__show': isConfirmExit}"
         class="mask"></div>
    <transition name="popup">
      <section v-if="isPopupCustomer" class="create-customer">
        <transition name="opacity">
          <button style="border-radius: 0 0 3px 0"
                  v-if="isPopupCustomer && !isPopupCustomerContracts && !isPopupCustomerCounters"
                  @click.prevent="createAll"
                  class="blue-button fixed-button">
            Alle daten speichern
          </button>
        </transition>
        <transition name="opacity">
          <button v-if="isPopupCustomer && !isPopupCustomerContracts && !isPopupCustomerCounters"
                  @click="openConfirm('all')" style="right: 178px; border-radius: 0 0 0 3px;"
                  class="border-button fixed-button">Abbrechen
          </button>
        </transition>
        <div class="create-customer__breadcrumb">
          <h4 v-if="!isPopupEditCustomer">Kunde anlegen</h4>
          <h4 v-if="isPopupEditCustomer">Kunde bearbeiten</h4>
          <span v-if="isPopupEditCustomer"></span>
          <p v-if="isPopupEditCustomer">#{{ customer.customer_id }}</p>
        </div>
        <div class="create-customer__navbar">
          <div style="display: flex">
            <a
              @click.prevent="isActive = 'address'"
              :class="{
                'create-customer__navbar-link-active': isActive === 'address',
                'create-customer__navbar-link-invalid': ($v.customer.type_client.$dirty && !$v.customer.type_client.required) ||
                                                        ($v.customer.addressing_customers.$dirty && !$v.customer.addressing_customers.required) ||
                                                        ($v.customer.name.$dirty && !$v.customer.name.required) ||
                                                        ($v.customer.surname.$dirty && !$v.customer.surname.required) ||
                                                        ($v.customer.accommodation_street.$dirty && !$v.customer.accommodation_street.required) ||
                                                        ($v.customer.accommodation_house_number.$dirty && !$v.customer.accommodation_house_number.required) ||
                                                        ($v.customer.accommodation_postcode.$dirty && !$v.customer.accommodation_postcode.required) ||
                                                        ($v.customer.accommodation_city.$dirty && !$v.customer.accommodation_city.required)
              }"
              href="#">
              Adresse
            </a>
            <a
              @click.prevent="isActive = 'contact'"
              :class="{
                'create-customer__navbar-link-active': isActive === 'contact',
                'create-customer__navbar-link-invalid': ($v.customer.personal_phone.$dirty && !$v.customer.personal_phone.required) ||
                                                        ($v.customer.email.$dirty && !$v.customer.email.required) ||
                                                        ($v.customer.date_of_birth.$dirty && !$v.customer.date_of_birth.required)
              }"
              href="#">
              Kontakt
            </a>
            <a
              @click.prevent="isActive = 'counter'"
              :class="{'create-customer__navbar-link-active': isActive === 'counter'}"
              href="#">
              Zähler
            </a>
          </div>
          <a v-if="isActive === 'counter'" @click.prevent="showCounters"
             class="create-customer__navbar-new-counter" href="#"><span></span> Neuen Zähler hinzufügen</a>
        </div>
        <form class="create-customer__form">
          <transition name="popup">
            <div v-if="isActive === 'address'" class="create-customer__block create-customer__address">
              <div class="create-customer__block-group-inputs">
                <div class="create-customer__block_50_50">
                  <div>
                    <base-field-input label="KUNDENTYP">
                      <v-select
                        placeholder="Kundentyp"
                        class="vSelect"
                        v-model="customer.type_client"
                        :options="['Privat', 'Firma']"
                      ></v-select>
                      <field-error-required
                        v-if="($v.customer.type_client.$dirty && !$v.customer.type_client.required)"/>
                    </base-field-input>
                  </div>
                  <div>
                    <base-field-input label="ANREDE">
                      <v-select
                        placeholder="Anrede"
                        class="vSelect"
                        v-model="customer.addressing_customers"
                        :options="['Herr', 'Frau', 'Firma', 'Familie']"
                      ></v-select>
                      <field-error-required
                        v-if="($v.customer.addressing_customers.$dirty && !$v.customer.addressing_customers.required)"/>
                    </base-field-input>
                  </div>
                </div>
                <div v-if="customer.type_client !== 'Privat'">
                  <base-field-input label="FIRMA">
                    <input v-model="customer.company" type="text" placeholder="Firma">
                  </base-field-input>
                </div>
                <div class="create-customer__block_50_50">
                  <div>
                    <base-field-input label="VORNAME">
                      <input v-model="customer.name" type="text" placeholder="Vorname">
                      <field-error-required
                        v-if="($v.customer.name.$dirty && !$v.customer.name.required)"/>
                    </base-field-input>
                  </div>
                  <div>
                    <base-field-input label="NAME">
                      <input v-model="customer.surname" type="text" placeholder="Name">
                      <field-error-required
                        v-if="($v.customer.surname.$dirty && !$v.customer.surname.required)"/>
                    </base-field-input>
                  </div>
                </div>
                <div class="create-customer__block_35_65">
                  <div>
                    <base-field-input label="PLZ">
                      <v-select
                        class="vSelect"
                        v-model="customer.accommodation_postcode"
                        :options="addresses.postcode"
                        placeholder="PLZ"
                        @search="getAddresses($event, customer.accommodation_postcode, customer.accommodation_city)"
                        @open="getAddresses($event, customer.accommodation_postcode, customer.accommodation_city)"
                      >
                        <template #no-options="{ search, searching, loading }">
                          Der Download ist in Arbeit...
                        </template>
                      </v-select>
                      <field-error-required
                        v-if="($v.customer.accommodation_postcode.$dirty && !$v.customer.accommodation_postcode.required)"/>
                    </base-field-input>
                  </div>
                  <div>
                    <base-field-input label="Ort">
                      <!--                      <input v-model="customer.accommodation_city" type="text" placeholder="Ort">-->
                      <v-select
                        class="vSelect"
                        v-model="customer.accommodation_city"
                        :options="addresses.city"
                        placeholder="Ort"
                        @search="getAddresses($event, customer.accommodation_postcode, customer.accommodation_city)"
                        @open="getAddresses($event, customer.accommodation_postcode, customer.accommodation_city)"
                      >
                        <template #no-options="{ search, searching, loading }">
                          Der Download ist in Arbeit...
                        </template>
                      </v-select>
                      <field-error-required
                        v-if="($v.customer.accommodation_city.$dirty && !$v.customer.accommodation_city.required)"/>
                    </base-field-input>
                  </div>
                </div>
                <div class="create-customer__block_65_35">
                  <div>
                    <base-field-input label="Straße">
                      <input v-model="customer.accommodation_street" type="text"
                             placeholder="Straße">
                      <field-error-required
                        v-if="($v.customer.accommodation_street.$dirty && !$v.customer.accommodation_street.required)"/>
                    </base-field-input>
                  </div>
                  <div>
                    <base-field-input label="HNr.">
                      <input v-model="customer.accommodation_house_number" type="text"
                             placeholder="HNr.">
                      <field-error-required
                        v-if="($v.customer.accommodation_house_number.$dirty && !$v.customer.accommodation_house_number.required)"/>
                    </base-field-input>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="isActive === 'contact'" class="create-customer__block create-customer__address">
              <div class="create-customer__block-group-inputs">
                <div class="create-customer__block_50_50">
                  <div>
                    <base-field-input label="MOBIL">
                      <input v-model="customer.personal_phone" type="text" placeholder="Mobil">
                      <field-error-required
                        v-if="($v.customer.personal_phone.$dirty && !$v.customer.personal_phone.required)"/>
                    </base-field-input>
                  </div>
                  <div>
                    <base-field-input label="TELEFON PRIVAT">
                      <input v-model="customer.private_phone" type="text"
                             placeholder="Telefon Privat">
                    </base-field-input>
                  </div>
                </div>
                <div class="create-customer__block_50_50">
                  <div>
                    <base-field-input label="EMAIL KUNDENKORRESPONDENZ">
                      <input v-model="customer.email" type="text" placeholder="Email Kundenkorrespondenz">
                      <field-error-required
                        v-if="($v.customer.email.$dirty && !$v.customer.email.required)"/>
                    </base-field-input>
                  </div>
                  <div>
                    <base-field-input label="TELEGRAM ID">
                      <input class="disabled-input" disabled v-model="customer.telegram_id" type="text"
                             placeholder="Telegram ID">
                    </base-field-input>
                  </div>
                </div>
                <div class="create-customer__block_50_50">
                  <div>
                    <base-field-input label="EMAIL Versorgerkorrespondenz">
                      <input v-model="customer.email_private" type="text"
                             placeholder="Email Versorgerkorrespondenz">
                    </base-field-input>
                  </div>
                  <div>
                    <base-field-input label="EMAIL Versorgerkorrespondenz PASSWORT">
                      <input v-model="customer.email_private_password" type="text"
                             placeholder="Email Versorgerkorrespondenz passwort">
                    </base-field-input>
                  </div>
                </div>
                <div class="create-customer__block_65_35">
                  <div>
                    <base-field-input label="NAME">
                      <input disabled class="disabled-input" v-model="fullName" type="text"
                             placeholder="Name">
                    </base-field-input>
                  </div>
                  <div>
                    <base-field-input label="GEBURTSDATUM">
                      <input
                        type="text"
                        v-model="customer.date_of_birth"
                        @input="validation('customer', 'date_of_birth')"
                        placeholder="DD.MM.YYYY"
                        @keydown="filterInput"
                      >
                      <field-error-required
                        v-if="($v.customer.date_of_birth.$dirty && !$v.customer.date_of_birth.required)"/>
                    </base-field-input>
                  </div>
                </div>
                <div>
                  <base-field-input label="EMPFEHLUNGSGEBER">
                    <input v-model="customer.recommender" type="text"
                           placeholder="Empfehlungsgeber">
                  </base-field-input>
                </div>
              </div>
            </div>
            <div v-else-if="isActive === 'counter'" ref="scrollPopupCounters"
                 @scroll="handleScrollTableCounters"
                 class="table create-customer__table">
              <table v-if="customer.counters.length !== 0">
                <thead>
                <tr>
                  <td>Status</td>
                  <td>Zähler</td>
                  <td>address</td>
                  <td>IBAN</td>
                  <td>Name der Bank</td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(counter, index) in customer.counters" :key="index + '_counter'">
                  <td class="create-customer__td-status"><span
                    :class="{'doc-active': counter.status === 'Active', 'doc-close': counter.status === 'Closed'}"></span>
                  </td>
                  <td class="create-customer__td-counter">
                    <div>
                      <a href="#"
                         @click.prevent="$emit('showEditCounter', {index: index, counter: counter, editTable: true})">#{{
                          counter.counters_number
                        }}</a>
                      <p>{{ counter.counters_type }}</p>
                    </div>
                  </td>
                  <td class="create-customer__td-address">
                    {{ counter.counter_street }} {{ counter.counter_house_number }}, <br>
                    {{ counter.counter_postcode }} {{ counter.counter_city }}
                  </td>
                  <td>
                    {{ counter.iban }}
                  </td>
                  <td>
                    {{ counter.banks_name }}
                  </td>
                </tr>
                </tbody>
              </table>
              <div class="warn-blank-table" v-else>Die Tabelle enthält keine Daten</div>
              <div v-if="isLoadingCountersPopup" class="loader"><span></span></div>
            </div>
          </transition>
          <div v-if="isActive !== 'counter'" class="create-customer__block-group-buttons">
            <button @click.prevent="stepContact" class="border-button" v-if="isActive === 'address'">Weiter</button>
            <button v-if="isActive === 'contact'" class="border-button" @click.prevent="showCounters">Zähler
              hinzufügen
            </button>
            <button v-if="isPopupEditCustomer && isActive !== 'address'" @click.prevent="$emit('editCustomer')"
                    class="blue-button">
              Bearbeiten Kunde
            </button>
            <button v-else-if="isActive !== 'address'" @click.prevent="createCustomer" class="blue-button">
              Kunden Erstellen
            </button>
            <a @click.prevent="openConfirm('normal')" href="#">Abbrechen</a>
          </div>
        </form>
      </section>
    </transition>
    <transition name="opacity">
      <section v-if="isPopupCustomerCounters" class="create-counter">
        <transition name="opacity">
          <button style="border-radius: 0 0 3px 0" v-if="isPopupCustomer && !isPopupCustomerContracts"
                  @click.prevent="createAll"
                  class="blue-button fixed-button">
            Alle daten speichern
          </button>
        </transition>
        <transition name="opacity">
          <button v-if="isPopupCustomer && !isPopupCustomerContracts" @click="openConfirm('all')"
                  style="right: 178px; border-radius: 0 0 0 3px;"
                  class="border-button fixed-button">Abbrechen
          </button>
        </transition>
        <div class="create-customer__breadcrumb">
          <h4 v-if="!isPopupEditCustomer">Zähler erstellen</h4>
          <h4 v-if="isPopupEditCustomer">Zähler bearbeiten</h4>
          <span v-if="isPopupEditCustomer"></span>
          <p v-if="isPopupEditCustomer">#{{ customer.customer_id }}</p>
        </div>
        <div class="create-customer__navbar">
          <div style="display: flex">
            <a
              @click.prevent="isActiveCounter = 'Counter Data'"
              :class="{
                'create-customer__navbar-link-active': isActiveCounter === 'Counter Data',
                'create-customer__navbar-link-invalid': ($v.counters.counters_type.$dirty && !$v.counters.counters_type.required) ||
                                                        ($v.counters.counters_number.$dirty && !$v.counters.counters_number.required) ||
                                                        ($v.counters.counter_postcode.$dirty && !$v.counters.counter_postcode.required) ||
                                                        ($v.counters.counter_city.$dirty && !$v.counters.counter_city.required) ||
                                                        ($v.counters.counter_street.$dirty && !$v.counters.counter_street.required) ||
                                                        ($v.counters.counter_house_number.$dirty && !$v.counters.counter_house_number.required)
              }"
              href="#">
              Zählerdaten
            </a>
            <a
              @click.prevent="isActiveCounter = 'Contracts'"
              :class="{'create-customer__navbar-link-active': isActiveCounter === 'Contracts'}"
              href="#">
              Verträge
            </a>
          </div>
          <a @click.prevent="$emit('showContracts')" v-if="isActiveCounter === 'Contracts'"
             class="create-customer__navbar-new-counter" href="#">
            <span></span>Neuen Vertrag hinzufügen
          </a>
        </div>
        <form class="create-counter__form">
          <div style="width: 100%; height: 100%;">
            <div v-if="isActiveCounter === 'Counter Data'" class="create-counter__block">
              <div class="create-customer__block_50_50">
                <div>
                  <base-field-input label="Zählertyp"
                                    :src_img="require(`@/assets/images/counter-type_${counters.counters_type.includes('Strom') ? 'strom' : 'gas'}.svg`)">
                    <v-select
                      placeholder="Zählertyp"
                      class="vSelect"
                      v-model="counters.counters_type"
                      :options="['Strom (Allgemein)', 'Strom (Tag/Nacht)', 'Strom RLM (Allgemein)', 'Strom RLM (Tag/Nacht)', 'Gas RLM', 'Gas']"
                    ></v-select>
                    <field-error-required
                      v-if="($v.counters.counters_type.$dirty && !$v.counters.counters_type.required)"/>
                  </base-field-input>
                </div>
                <div>
                  <base-field-input label="Zählernummer">
                    <input v-model="counters.counters_number" type="text"
                           placeholder="Zählernummer">
                    <field-error-required
                      v-if="($v.counters.counters_number.$dirty && !$v.counters.counters_number.required)"/>
                  </base-field-input>
                </div>
              </div>
              <div class="create-counter__line"></div>
              <div class="create-customer__block_50_50">
                <div>
                  <base-field-input label="MALO-ID">
                    <input v-model="counters.mao_id" type="text" placeholder="Malo-id">
                  </base-field-input>
                </div>
                <div></div>
              </div>
              <div class="create-counter__line"></div>
              <div
                style="display: flex; align-items: center; font-style: italic; color: rgba(153, 153, 153, 1); margin-bottom: 12px; justify-content: flex-end">
                <span style="margin-right: 8px; font-size: 14px; font-weight: 600;">Stimmt die Adresse des Zählers mit der Adresse des Kunden überein?</span>
                <div class="checkbox">
                  <input v-model="checkAddressCounter" id="checkAddressCounter" type="checkbox">
                  <label style="margin: 0;" for="checkAddressCounter"></label>
                </div>
              </div>
              <div class="create-customer__block_35_65">
                <div>
                  <base-field-input label="PLZ">
                    <!--                    <input v-model="counters.counter_postcode" type="text" placeholder="PLZ">-->
                    <!--                    <field-error-required-->
                    <!--                      v-if="($v.counters.counter_postcode.$dirty && !$v.counters.counter_postcode.required)"/>-->
                    <v-select
                      class="vSelect"
                      v-model="counters.counter_postcode"
                      :options="addresses.postcode"
                      placeholder="PLZ"
                      @search="getAddresses($event, counters.counter_postcode, counters.counter_city)"
                      @open="getAddresses($event, counters.counter_postcode, counters.counter_city)"
                    >
                      <template #no-options="{ search, searching, loading }">
                        Der Download ist in Arbeit...
                      </template>
                    </v-select>
                    <field-error-required
                      v-if="($v.counters.counter_postcode.$dirty && !$v.counters.counter_postcode.required)"/>
                  </base-field-input>
                </div>
                <div>
                  <base-field-input label="Ort">
                    <!--                    <input v-model="counters.counter_city" type="text" placeholder="Ort">-->
                    <v-select
                      class="vSelect"
                      v-model="counters.counter_city"
                      :options="addresses.city"
                      placeholder="Ort"
                      @search="getAddresses($event, counters.counter_postcode, counters.counter_city)"
                      @open="getAddresses($event, counters.counter_postcode, counters.counter_city)"
                    >
                      <template #no-options="{ search, searching, loading }">
                        Der Download ist in Arbeit...
                      </template>
                    </v-select>
                    <field-error-required
                      v-if="($v.counters.counter_city.$dirty && !$v.counters.counter_city.required)"/>
                    <!--                    <field-error-required-->
                    <!--                      v-if="($v.counters.counter_city.$dirty && !$v.counters.counter_city.required)"/>-->
                  </base-field-input>
                </div>
              </div>
              <div class="create-customer__block_65_35">
                <div>
                  <base-field-input label="Straße">
                    <input v-model="counters.counter_street" type="text"
                           placeholder="Straße">
                    <field-error-required
                      v-if="($v.counters.counter_street.$dirty && !$v.counters.counter_street.required)"/>
                    <!--                    <field-error-required-->
                    <!--                      v-if="($v.counters.counter_street.$dirty && !$v.counters.counter_street.required)"/>-->
                  </base-field-input>
                </div>
                <div>
                  <base-field-input label="HNr.">
                    <input v-model="counters.counter_house_number" type="text" placeholder="HNr.">
                    <!--                    <field-error-required-->
                    <!--                      v-if="($v.counters.counter_house_number.$dirty && !$v.counters.counter_house_number.required)"/>-->
                    <field-error-required
                      v-if="($v.counters.counter_house_number.$dirty && !$v.counters.counter_house_number.required)"/>
                  </base-field-input>
                </div>
              </div>
              <div class="create-counter__line"></div>
              <div class="create-customer__block_65_35">
                <div>
                  <base-field-input label="IBAN">
                    <input style="text-transform: uppercase" v-model="counters.iban" @input="getIbanData" type="text"
                           placeholder="Iban">
                    <div v-if="!iban.valid" class="popup-customer__error">{{ iban.text }}</div>
                  </base-field-input>
                </div>
                <div>
                  <base-field-input label="BIC">
                    <input v-model="counters.bic" type="text" placeholder="Bic">
                  </base-field-input>
                </div>
              </div>
              <div class="create-customer__block_65_35">
                <div>
                  <base-field-input label="BANK">
                    <input v-model="counters.banks_name" type="text" placeholder="Bank">
                  </base-field-input>
                </div>
                <div></div>
              </div>
              <div style="align-items: center" class="create-customer__block_65_35">
                <div>
                  <base-field-input label="KONTOINHABER">
                    <input :disabled="account_owner_disabled" v-model="accountOwner"
                           :class="{'disabled-input': account_owner_disabled}"
                           type="text" placeholder="Kontoinhaber">
                  </base-field-input>
                </div>
                <div class="create-counter__checkbox">
                  <div class="checkbox">
                    <input v-model="account_owner_disabled" id="accountOwnerDisabled"
                           type="checkbox">
                    <label for="accountOwnerDisabled"></label>
                  </div>
                  Wie der Kunde
                </div>
              </div>
            </div>
            <div v-else-if="isActiveCounter === 'Contracts'" class="table create-customer__table"
                 ref="scrollPopupContracts" @scroll="handleScrollTableContracts"
            >
              <table>
                <thead>
                <tr>
                  <td></td>
                  <td>Zähler</td>
                  <td>Kundennummer</td>
                  <td>Start/Aktualisierung</td>
                  <td>cons:</td>
                  <td>unterzeichnet</td>
                  <td>beendet</td>
                  <td>Energieversorger</td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(contract, index) in counters.contracts" :key="index + '_doc'">
                  <td class="create-customer__td-status"><span
                    :class="{'doc-active': contract.contract_status === 'Active', 'doc-close': contract.contract_status === 'Closed'}"></span>
                  </td>
                  <td>
                    <a href="#"
                       @click.prevent="$emit('showEditContract', {index: index, contract: contract})">#{{
                        counters.counters_number
                      }}</a>
                  </td>
                  <td>
                    {{ contract.customer_number }}
                  </td>
                  <td class="create-customer__td-start_update">
                    <div>
                      <span class="start">{{ contract.start_count || '---' }}</span>
                      <span class="update">{{ contract.end_count || '---' }}</span>
                    </div>
                  </td>
                  <td>{{ contract.consumption }} kWh</td>
                  <td>{{ contract.contract_start_date }}</td>
                  <td>{{ contract.contract_end_date }}</td>
                  <td>{{ contract.operator_name }}</td>
                </tr>
                </tbody>
              </table>
              <div v-if="isLoadingContractsPopup" class="loader"><span></span></div>
            </div>
          </div>
          <div v-if="isActiveCounter !== 'Contracts'" class="create-customer__block-group-buttons">
            <button class="border-button" @click.prevent="$emit('showContracts')">Einen Vertrag hinzufügen</button>
            <button v-if="isPopupCustomerEditCounters" @click.prevent="$emit('editCounter')"
                    class="blue-button">
              Zähler bearbeiten
            </button>
            <button v-else @click.prevent="$emit('addCounter')" class="blue-button">Zähler erstellen
            </button>
            <a @click.prevent="openConfirm('normal')" href="#">Abbrechen</a>
          </div>
        </form>
      </section>
    </transition>
    <transition name="opacity">
      <section v-if="isPopupCustomerContracts" class="create-documents">
        <transition name="opacity">
          <button style="border-radius: 0 0 3px 0" v-if="isPopupCustomer" @click.prevent="createAll"
                  class="blue-button fixed-button">
            Alle daten speichern
          </button>
        </transition>
        <transition name="opacity">
          <button v-if="isPopupCustomer" @click="openConfirm('all')" style="right: 178px; border-radius: 0 0 0 3px;"
                  class="border-button fixed-button">Abbrechen
          </button>
        </transition>
        <div class="create-customer__breadcrumb">
          <div style="width: 100%;" class="create-customer__navbar">
            <div style="display: flex">
              <a
                @click.prevent="isActiveContract = 'Kunde'"
                :class="{
                'create-customer__navbar-link-active': isActiveContract === 'Kunde'
                }"
                href="#">
                Kunde
              </a>
              <a
                @click.prevent="isPopupEditCustomer && isPopupCustomerEditContracts && !counters.counters_type.includes('RLM') ? isActiveContract = 'Angebot' : ''"
                :class="{'create-customer__navbar-link-active': isActiveContract === 'Angebot', 'create-customer__navbar-link-disable': (!isPopupEditCustomer  && !isPopupCustomerEditContracts) || (this.counters.counters_type && this.counters.counters_type.includes('RLM'))}"
                href="#">
                Angebot
              </a>
              <a
                @click.prevent="isPopupEditCustomer && isPopupCustomerEditContracts && !counters.counters_type.includes('RLM') ? isActiveContract = 'Bericht' : ''"
                :class="{'create-customer__navbar-link-active': isActiveContract === 'Bericht', 'create-customer__navbar-link-disable': (!isPopupEditCustomer  && !isPopupCustomerEditContracts) || (this.counters.counters_type && this.counters.counters_type.includes('RLM'))}"
                href="#">
                Bericht
              </a>
            </div>
          </div>
        </div>
        <div style="display: flex; align-items: flex-start; justify-content: space-between">

          <div style="width: 510px">
            <div v-if="isActiveContract === 'Kunde'">
              <div class="create-customer__block_50_50">
                <div class="create-documents__field">
                  <base-field-input label="KUNDENNUMMER">
                    <div style="display: flex; align-items: center">
                      <input
                        v-model="contract.customer_number"
                        type="text"
                        placeholder="Kundennummer"
                      >
                    </div>
                  </base-field-input>
                </div>
                <div class="create-documents__field">
                  <base-field-input label="OPERATOR">
                    <div style="display: flex; align-items: center">
                      <v-select
                        placeholder="Operator"
                        class="vSelect"
                        v-model="contract.operator_name"
                        :options="operators"
                      ></v-select>
                    </div>
                  </base-field-input>
                </div>
              </div>
              <div class="create-customer__block_50_50">
                <div>
                  <base-field-input label="Grundpreis pro Monat	">
                    <input type="text"
                           placeholder="€"
                           @keydown="filterInput"
                           v-model="contract.cost_monthly"
                    >
                  </base-field-input>
                </div>
                <div>
                  <base-field-input label="VORJGHRESVERBRAUCH">
                    <input v-model="contract.prior_year_consumption" type="text"
                           placeholder="Vorjahresverbrauch">
                  </base-field-input>
                </div>
              </div>
              <div class="create-customer__block_50_50">
                <div>
                  <base-field-input label="Arbeitspreis pro kWh / HT	">
                    <input type="text"
                           placeholder="kWh €"
                           @keydown="filterInput"
                           v-model="contract.cost_per_day"
                    >
                  </base-field-input>
                </div>
                <div>
                  <base-field-input label="Arbeitspreis pro kWh / NT"
                                    v-if="counters.counters_type.includes('Tag/Nacht')">
                    <input v-model="contract.cost_per_night" type="text"
                           placeholder="kWh €">
                  </base-field-input>
                </div>
              </div>
              <div class="create-customer__block_50_50">
                <div>
                  <base-field-input label="Lieferbeginn">
                    <input
                      type="text"
                      v-model="contract.contract_start_date"
                      @input="validation('contract', 'contract_start_date')"
                      placeholder="Date (DD.MM.YYYY)"
                      @keydown="filterInput"
                    >
                  </base-field-input>
                </div>
                <div>
                  <base-field-input label="Lieferende">
                    <input
                      type="text"
                      v-model="contract.contract_end_date"
                      @input="validation('contract', 'contract_end_date')"
                      placeholder="Date (DD.MM.YYYY)"
                      @keydown="filterInput"
                    >
                  </base-field-input>
                </div>
              </div>
              <div class="create-customer__block_50_50">
                <div>
                  <base-field-input
                    :label="counters.counters_type.includes('Tag/Nacht') ? 'Anfangszählerstand HT': 'Anfangszählerstand'">
                    <input v-model="contract.start_count" type="text"
                           @keydown="filterInput"
                           placeholder="Anfangszählerstand">
                  </base-field-input>
                </div>
                <div>
                  <base-field-input
                    :label="counters.counters_type.includes('Tag/Nacht') ? 'Endzählerstand HT': 'Endzählerstand'">
                    <input v-model="contract.end_count" type="text" placeholder="Endzählerstand"
                           @keydown="filterInput"
                    >
                  </base-field-input>
                </div>
              </div>
              <div v-if="counters.counters_type.includes('Tag/Nacht')"
                   class="create-customer__block_50_50">
                <div>
                  <base-field-input
                    label="Anfangszählerstand NT">
                    <input
                      v-model="contract.start_count_night"
                      type="text"
                      placeholder="Anfangszählerstand NT"
                      @keydown="filterInput"
                    >
                  </base-field-input>
                </div>
                <div>
                  <base-field-input
                    label="Endzählerstand NT">
                    <input
                      v-model="contract.end_count_night"
                      type="text"
                      placeholder="Endzählerstand NT"
                      @keydown="filterInput"
                    >
                  </base-field-input>
                </div>
              </div>
              <div v-if="counters.counters_type === 'Gas' || counters.counters_type === 'Gas RLM'"
                   class="create-customer__block_50_50">
                <div>
                  <base-field-input label="ZUSTANDSZAHL">
                    <input v-model="contract.condition_number" type="text"
                           :class="{'disabled-input': counters.counters_type !== 'Gas' && counters.counters_type !== 'Gas RLM'}"
                           placeholder="Zustandszahl"
                           :disabled="counters.counters_type !== 'Gas' && counters.counters_type !== 'Gas RLM'"
                           @keydown="filterInput"
                    >
                  </base-field-input>
                </div>
                <div>
                  <base-field-input label="BRENNWERT">
                    <input v-model="contract.calorific_value" type="text"
                           :class="{'disabled-input': counters.counters_type !== 'Gas' && counters.counters_type !== 'Gas RLM'}"
                           placeholder="Brennwert"
                           :disabled="counters.counters_type !== 'Gas' && counters.counters_type !== 'Gas RLM'"
                           @keydown="filterInput"
                    >
                  </base-field-input>
                </div>
              </div>
              <div class="create-customer__block_50_50">
                <div class="create-documents__field">
                  <base-field-input :label="counters.counters_type.includes('Tag/Nacht') ? 'VERBRAUCH HT' : 'VERBRAUCH'"
                                    :src_img="require(`@/assets/images/counter-consumption_${counters.counters_type.includes('Strom') ? 'strom' : 'gas'}.svg`)">
                    <input v-model="consumptionTotal" type="text" class="disabled-input show-icon"
                           placeholder="----"
                           disabled>
                  </base-field-input>
                </div>
                <div v-if="counters.counters_type.includes('Tag/Nacht')"
                     class="create-documents__field">
                  <base-field-input label="VERBRAUCH NT"
                                    :src_img="require(`@/assets/images/counter-consumption_${counters.counters_type.includes('Strom') ? 'strom' : 'gas'}.svg`)">
                    <input v-model="consumptionTotalNight" type="text" class="disabled-input show-icon"
                           placeholder="----"
                           disabled>
                  </base-field-input>
                </div>
              </div>
              <div class="create-customer__block_50_50">
                <div class="create-documents__field">
                  <base-field-input label="STATUS">
                    <div style="display: flex; align-items: center">
                      <v-select
                        class="vSelect"
                        v-model="contract.contract_status"
                        :options="statusContractOptions"
                        label="label"
                        index="index"
                        :reduce="option => option.index"
                        :class="{'active-select': contract.contract_status === 'Active', 'closed-select': contract.contract_status === 'Closed', 'opened-select': contract.contract_status === 'Pending'}"
                      ></v-select>
                    </div>
                  </base-field-input>
                </div>
              </div>
            </div>
            <div v-else-if="isActiveContract === 'Angebot'">
              <div class="create-customer__block_50_50">
                <div>
                  <base-field-input label="Zählertyp"
                                    :src_img="require(`@/assets/images/counter-type_${counters.counters_type.includes('Strom') ? 'strom' : 'gas'}.svg`)">
                    <v-select
                      placeholder="Zählertyp"
                      v-model="offer.offer_counters_type"
                      class="vSelect"
                      :options="['Strom (Allgemein)', 'Strom (Tag/Nacht)', 'Gas']"
                    ></v-select>
                    <field-error-required
                      v-if="($v.offer.offer_counters_type.$dirty && !$v.offer.offer_counters_type.required)"/>
                  </base-field-input>
                </div>
                <div>
                  <base-field-input label="Grundpreis pro Monat	">
                    <input type="text"
                           placeholder="€"
                           @keydown="filterInput"
                           v-model="offer.offer_cost_monthly"
                    >
                    <field-error-required
                      v-if="($v.offer.offer_cost_monthly.$dirty && !$v.offer.offer_cost_monthly.required)"/>
                  </base-field-input>
                </div>
              </div>
              <div class="create-customer__block_50_50">
                <div>
                  <base-field-input label="Arbeitspreis pro kWh / HT	">
                    <input type="text"
                           placeholder="kWh €"
                           @keydown="filterInput"
                           v-model="offer.offer_cost_per_day"
                    >
                    <field-error-required
                      v-if="($v.offer.offer_cost_per_day.$dirty && !$v.offer.offer_cost_per_day.required)"/>
                  </base-field-input>
                </div>
                <div>
                  <base-field-input label="Arbeitspreis pro kWh / NT"
                                    v-if="offer.offer_counters_type && offer.offer_counters_type.includes('Tag/Nacht')">
                    <input v-model="offer_cost_per_night" type="text"
                           placeholder="kWh €" @keydown="filterInput">
                    <field-error-required
                      v-if="($v.offer_cost_per_night.$dirty && !$v.offer_cost_per_night.required)"/>
                  </base-field-input>
                </div>
              </div>
              <div class="create-customer__block_50_50">
                <div>
                  <base-field-input label="Anzahl der Monate">
                    <v-select
                      placeholder="Anzahl der Monate"
                      class="vSelect"
                      v-model="offer.qty_month"
                      :options="[10, 11, 12]"
                    ></v-select>
                    <field-error-required
                      v-if="($v.offer.qty_month.$dirty && !$v.offer.qty_month.required)"/>
                  </base-field-input>
                </div>
                <div>
                  <base-field-input label="Bonus">
                    <input type="text"
                           placeholder="Bonus"
                           @keydown="filterInput"
                           v-model="offer.bonus"
                    >
                  </base-field-input>
                </div>
              </div>
              <div class="create-customer__block_50_50">
                <div>
                  <base-field-input label="Preisgarantie">
                    <v-select
                      placeholder="Preisgarantie"
                      class="vSelect"
                      v-model="offer.number_of_months_warranty"
                      :options="Array.from({length: 48 - 12 + 1}, (_, i) => i + 12)"
                    ></v-select>
                    <field-error-required
                      v-if="($v.offer.number_of_months_warranty.$dirty && !$v.offer.number_of_months_warranty.required)"/>
                  </base-field-input>
                </div>
                <div></div>
              </div>
              <div class="create-customer__block_50_50" style="align-items: center">
                <div class="create-documents__field">
                  <base-field-input label="OPERATOR">
                    <v-select
                      v-if="!offerToggleOperator"
                      placeholder="Operator"
                      class="vSelect"
                      v-model="offer.offer_operator"
                      :options="operators"
                    ></v-select>
                    <input
                      v-else
                      type="text"
                      placeholder="Operator"
                      v-model="offer.offer_operator"
                    >
                    <field-error-required
                      v-if="($v.offer.offer_operator.$dirty && !$v.offer.offer_operator.required)"/>
                  </base-field-input>
                </div>
                <div class="create-documents__field">
                  <div
                    style="display: flex; align-items: center; font-style: italic; color: rgba(153, 153, 153, 1)">
                    <span style="margin-right: 8px; font-size: 14px;">Neuer Operator</span>
                    <div class="checkbox" style="width: auto">
                      <input v-model="offerToggleOperator" id="toggleTypeOfTask" type="checkbox">
                      <label @click="offer.offer_operator = ''" style="margin: 0;" for="toggleTypeOfTask"></label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="report__generate">
                <button @click.prevent="generateOffer"
                        :class="['blue-button ', {'success-button': successGenerate.offer, 'invalid-button': offer_doc_url === 'error'}]">
                  Generate document
                </button>
                <a target="_blank"
                   class="document-button"
                   v-if="offer_doc_url !== '' && offer_doc_url !== 'error'"
                   :href="url + '/' + offer_doc_url"
                >
                  Dokument öffnen
                </a>
              </div>
              <p v-if="successGenerate.offer" style="margin-top: 16px; color: green">Dokument erfolgreich erstellt</p>
              <p v-if="offer_doc_url === 'error'"
                 style="margin-top: 16px; color: red; max-width: 400px; line-height: 1.4">Es ist ein Fehler aufgetreten,
                bitte überprüfen Sie die Richtigkeit der eingegebenen Daten und versuchen Sie es erneut</p>

            </div>


            <div v-else-if="isActiveContract === 'Bericht'">
              <div class="create-customer__block_50_50">
                <div>
                  <base-field-input label="Anzeige der Daten">
                    <v-select
                      v-model="report_type"
                      placeholder="Anzeige der Daten"
                      class="vSelect"
                      label="label"
                      index="value"
                      :reduce="option => option.value"
                      :options="[{label: 'Gleicher Betrag', value: 'equal_amount'}, {label: 'Für jeden Monat', value: 'each_month'}]"
                    ></v-select>
                    <field-error-required
                      v-if="($v.report_type.$dirty && !$v.report_type.required)"/>
                  </base-field-input>
                </div>
                <div>
                  <base-field-input label="Datum des Beginns">
                    <datepicker
                      v-model="report_start_data"
                      format="MM.YYYY"
                      title-format="MM.YYYY"
                      placeholder="Date (MM.YYYY)"
                      type="month"
                      @change="handleDateChange"
                    ></datepicker>
                    <field-error-required
                      v-if="($v.report_start_data.$dirty && !$v.report_start_data.required)"/>
                  </base-field-input>
                </div>
              </div>
              <div class="create-customer__block_50_50 report__inputs" style="align-items: flex-end;">
                <div class="report__input">
                  <base-field-input label="Start Zählerstand bei">
                    <datepicker
                      v-model="report.start_meter_read_date"
                      format="DD.MM.YYYY"
                      title-format="DD.MM.YYYY"
                      placeholder="Date (DD.MM.YYYY)"
                      :lang="$datepickerLocale"
                    ></datepicker>
                  </base-field-input>
                </div>
                <div class="report__input">
                  <span class="report__label"></span>
                  <base-field-input>
                    <input type="text"
                           placeholder="Zählerstand"
                           @keydown="filterInput"
                           v-model="report.start_meter_read_value"
                    >
                  </base-field-input>
                </div>
                <div class="report__input">
                  <base-field-input label="Ende Zählerstand am">
                    <datepicker
                      v-model="report.end_meter_read_date"
                      format="DD.MM.YYYY"
                      title-format="DD.MM.YYYY"
                      placeholder="Date (DD.MM.YYYY)"
                      :lang="$datepickerLocale"
                    ></datepicker>
                  </base-field-input>
                </div>
                <div class="report__input">
                  <span class="report__label"></span>
                  <base-field-input>
                    <input type="text"
                           placeholder="Zählerstand"
                           @keydown="filterInput"
                           v-model="report.end_meter_read_value"
                    >
                  </base-field-input>
                </div>
              </div>
              <div class="create-customer__block_50_50">
                <div>
                  <base-field-input label="Grundpreis pro Monat" v-if="report_type === 'equal_amount'">
                    <input type="text"
                           placeholder="€"
                           @keydown="filterInput"
                           v-model="report_amount_month"
                    >
                  </base-field-input>
                </div>
                <div></div>
              </div>
              <div class="table report__table"
                   v-if="report.monthly_amounts.length && report_type === 'each_month'">
                <table>
                  <thead>
                  <tr>
                    <th>Monat</th>
                    <th>Betrag €</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(date, index) in report.monthly_amounts" :key="`monthly_${index}`">
                    <td>
                      {{ date.label }}
                    </td>
                    <td class="report__td-input">
                      <input type="text" v-model="date.value">
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="report__generate">
                <button @click.prevent="generateReport"
                        :class="['blue-button ', {'success-button': successGenerate.report, 'invalid-button': report_doc_url === 'error'}]">
                  Generate document
                </button>
                <a target="_blank"
                   class="document-button"
                   v-if="report_doc_url !== '' && report_doc_url !== 'error'"
                   :href="url + '/' + report_doc_url"
                >
                  Dokument öffnen
                </a>
              </div>
              <p v-if="successGenerate.report" style="margin-top: 16px; color: green">Dokument erfolgreich erstellt</p>
              <p v-if="report_doc_url === 'error'"
                 style="margin-top: 16px; color: red; max-width: 400px; line-height: 1.4">Es ist ein Fehler aufgetreten,
                bitte überprüfen Sie die Richtigkeit der eingegebenen Daten und versuchen Sie es erneut</p>
            </div>


          </div>
          <div class="create-documents__block-group-buttons">
            <button v-if="isPopupCustomerEditContracts" @click.prevent="$emit('editContractInCounter')"
                    class="blue-button">Vertrag bearbeiten
            </button>
            <button v-else @click.prevent="$emit('addContractInCounter')" class="blue-button">Vertrag
              erstellen
            </button>
            <a @click.prevent="openConfirm('normal')" href="#">Abbrechen</a>
            <p
              style="max-width: 400px; line-height: 1.5; margin-top: 60px; font-size: 14px; position: absolute; bottom: 36px; right: 38px;"
              v-if="isActiveContract === 'Bericht' || isActiveContract === 'Angebot'">
              <b>Achtung:</b> Wenn Sie folgende Felder nicht ausgefüllt haben: <b>GRUNDPREIS PRO MONAT,
              VORJGHRESVERBRAUCH,
              ARBEITSPREIS PRO KWH / HT, ARBEITSPREIS PRO KWH / NT oder ARBEITSPREIS PRO KWH</b> (wenn es sich um
              Allgemeinen Strom oder Gas handelt), wird das Dokument nicht erstellt und die Schaltfläche wird rot.
              <b>Füllen Sie die fehlenden Felder aus und speichern Sie die Änderungen des Kunden vollständig ab, dann
                können Sie den Beleg erstellen.</b>
            </p>
          </div>
        </div>
      </section>
    </transition>
    <transition name="opacity">
      <section v-if="isConfirmExit" class="confirm-exit">
        <h2>Warnung</h2>
        <p>Wollen Sie die Erstellung/Änderung des Mandanten wirklich abbrechen? Ihre Daten werden nicht gespeichert.</p>
        <div>
          <button class="border-button" @click="isConfirmExit = false">Nein</button>
          <button style="background: rgb(255,57,57); border-color: rgb(255,57,57)" class="blue-button"
                  @click="closePopup">Ja
          </button>
        </div>
      </section>
    </transition>
    <transition name="opacity">
      <section v-if="isNewUser && !isPopupEditCustomer" style="height: auto" class="confirm-exit telegram-text">
        <h2 style="text-align: center; margin-bottom: 16px; padding-top: 16px;">An den Kunden auf Telegram senden</h2>
        <p>{{ messageNewUser }}</p>
        <copy-button
          :copy="messageNewUser"
        />
        <button @click.prevent="closePopup" class="popup__close-btn"></button>
      </section>
    </transition>
  </div>
</template>

<script>
import BaseFieldInput from "@/components/fields/BaseFieldInput.vue";
import BaseCheckbox from "@/components/fields/BaseCheckbox.vue";
import validations from "@/mixins/customers/validations";
import fieldErrorRequired from "@/components/fieldErrorRequired.vue";
import lazyLoadScrollTable from "@/mixins/components/lazyLoadScrollTable";
import {debounce} from "lodash";
import eventDate from "@/mixins/components/_eventDate";
import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select'
import eventInteger from "@/mixins/components/_eventInteger";
import CopyButton from "@/components/copyButton.vue";
import moment from 'moment';

export default {
  name: "createCustomer",
  mixins: [validations, lazyLoadScrollTable, eventDate, eventInteger],
  components: {
    CopyButton,
    vSelect,
    BaseFieldInput,
    BaseCheckbox,
    fieldErrorRequired,
  },
  data() {
    return {
      successGenerate: {
        report: false,
        offer: false
      },
      url: window.location.origin,
      offer_doc_url: '',
      report_doc_url: '',
      report_start_data: null,
      report_amount_month: '',
      report_type: 'equal_amount',
      report: {
        monthly_amounts: [],
        start_meter_read_date: '',
        start_meter_read_value: '',
        end_meter_read_date: '',
        end_meter_read_value: '',

      },
      offerToggleOperator: false,
      offer: {
        offer_counters_type: null,
        offer_cost_monthly: null,
        offer_cost_per_day: null,
        qty_month: null,
        bonus: null,
        offer_operator: null,
        number_of_months_warranty: null
      },
      offer_cost_per_night: null,
      addresses: {
        postcode: [],
        city: [],
        street: []
      },
      iban: {
        valid: true,
        text: null
      },
      isNewUser: false,
      messageNewUser: null,
      isConfirmExit: false,
      isTypeDelete: '',
      checkAddressCounter: false,
      isActive: 'address',
      isActiveCounter: 'Counter Data',
      isActiveContract: 'Kunde',
      account_owner_disabled: true,
      statusContractOptions: [
        {
          label: 'Anhängig',
          index: 'Pending'
        },
        {
          label: 'Aktiver Status',
          index: 'Active'
        },
        {
          label: 'Abgeschlossener Status',
          index: 'Closed'
        },
      ],
    }
  },
  props: {
    telegramCustomerId: Number,
    checkHeightContractsPopup: Boolean,
    isLoadingContractsPopup: Boolean,
    searchContractsPopup: Boolean,
    countNextPageContractsPopup: Number,
    checkHeightCountersPopup: Boolean,
    isLoadingCountersPopup: Boolean,
    searchCountersPopup: Boolean,
    operators: Array,
    isPopupEditCustomer: Boolean,
    isPopupCustomerEditCounters: Boolean,
    isPopupCustomerEditContracts: Boolean,
    isPopupCustomer: Boolean,
    isPopupCustomerCounters: Boolean,
    isPopupCustomerContracts: Boolean,
    customer: {
      customer_id: String,
      type_client: String,
      addressing_customers: String,
      company: String,
      surname: String,
      name: String,
      accommodation_street: String,
      accommodation_house_number: String,
      accommodation_postcode: String,
      accommodation_city: String,
      private_phone: String,
      personal_phone: String,
      email: String,
      email_private_password: String,
      email_private: String,
      telegram_id: String,
      date_of_birth: String,
      recommender: String,
    },
    counters: {
      counter_id: String,
      counters_type: String,
      counters_number: String,
      counter_street: String,
      counter_house_number: String,
      counter_postcode: String,
      counter_city: String,
      iban: String,
      bic: String,
      banks_name: String,
      account_owner: String,
      mao_id: String
    },
    contract: {
      contract_id: String,
      counters_id: String,
      operator_name: String,
      customer_number: String,
      prior_year_consumption: String,
      contract_start_date: String,
      contract_end_date: String,
      start_count: String,
      end_count: String,
      start_count_night: String,
      end_count_night: String,
      condition_number: String,
      calorific_value: String,
      consumption: String,
      consumption_night: String,
      contract_status: String,
      cost_per_day: String,
      cost_per_night: String,
      cost_monthly: String
    }
  },
  computed: {
    accountOwner: {
      get() {
        if (this.account_owner_disabled && this.counters.account_owner === '') {
          this.counters.account_owner = `${this.customer.surname} ${this.customer.name}`
          return `${this.customer.surname} ${this.customer.name}`
        } else {
          return this.counters.account_owner
        }
      },
      set(string) {
        this.counters.account_owner = string
      }
    },
    consumptionTotal() {
      if (this.counters.counters_type.includes('Allgemein') || this.counters.counters_type.includes('Tag/Nacht')) {
        const amount = Number(this.contract.end_count) - Number(this.contract.start_count)
        if (Number(amount) < 0) {
          this.contract.consumption = 0
        } else {
          this.contract.consumption = amount || 0
        }
        return this.contract.consumption
      } else if (this.counters.counters_type === 'Gas' || this.counters.counters_type === 'Gas RLM') {
        let amount = (Number(this.contract.end_count) - Number(this.contract.start_count)) * Number(this.contract.condition_number) * Number(this.contract.calorific_value)
        if (Number(amount) < 0) {
          this.contract.consumption = 0
        } else {
          this.contract.consumption = amount
        }
        return this.contract.consumption
      }
    },
    consumptionTotalNight() {
      if (this.counters.counters_type.includes('Tag/Nacht')) {
        const amount = Number(this.contract.end_count_night) - Number(this.contract.start_count_night)
        if (Number(amount) < 0) {
          this.contract.consumption_night = 0
        } else {
          this.contract.consumption_night = amount || 0
        }
        return this.contract.consumption_night
      }
    },
    fullName() {
      return this.customer.surname + ' ' + this.customer.name
    },
  },
  methods: {
    async getContractDocuments() {
      const response = await this.$services.getContractDocuments.callMethod({contract_id: this.contract.contract_id})
      const result = await response.json()
      if (result.last_offer) {
        this.offer_doc_url = result.last_offer.file
      }
      if (result.last_report) {
        this.report_doc_url = result.last_report.file
      }
    },
    handleDateChange(newDate) {
      if (this.report_type === 'each_month') {
        const startMonth = moment(newDate).locale('de');
        this.generateMonthArray(startMonth);
      }
    },
    generateMonthArray(startMonth) {
      console.log(startMonth)
      const months = [];
      const startMonthly = moment(this.report.start_meter_read_date).month();
      const endMonthly = moment(this.report.end_meter_read_date).month();
      console.log(Number(startMonth.clone().add(1, 'months').format('MM')))
      console.log(this.report_type)
      for (let i = 0; i < 12; i++) {
        const month = startMonth.clone().add(i, 'months').format('MM')
        let value;
        if (Number(month) >= startMonthly && Number(month) <= endMonthly && this.report_type === 'equal_amount') {
          value = Number(this.report_amount_month)
        } else {
          value = null
        }

        months.push({
          month,
          year: startMonth.clone().add(i, 'months').format('YYYY'),
          label: startMonth.clone().add(i, 'months').format('MMMM YYYY'),
          value
        });
      }
      this.report.monthly_amounts = months
    },
    async generateOffer() {
      const validOffer = this.$v.offer.$invalid
      const validPerNight = this.offer.offer_counters_type === 'Strom (Tag/Nacht)' && this.$v.offer_cost_per_night.$invalid
      if ((validPerNight && validOffer)) {
        this.$v.offer_cost_per_night.$touch()
        this.$v.offer.$touch()
        return
      } else if (validOffer) {
        this.$v.offer.$touch()
        return
      } else {
        const data = this.offer
        data.type_document = 'offer'
        data.contract_id = this.contract.contract_id
        data.bonus = Number(this.offer.bonus)
        data.offer_cost_per_day = Number(this.offer.offer_cost_per_day)
        data.offer_cost_monthly = Number(this.offer.offer_cost_monthly)
        data.qty_month = Number(this.offer.qty_month)
        if (this.offer.offer_counters_type === 'Strom (Tag/Nacht)') {
          data.offer_cost_per_night = Number(this.offer_cost_per_night)
        } else {
          delete data.offer_cost_per_night
        }
        const response = await this.$services.sendOfferData.callMethod(data)
        const result = await response.json()
        this.offer_doc_url = result
        if (typeof result === 'string') {
          this.offer_doc_url = result
          this.successGenerate.offer = true;
          setTimeout(() => {
            this.successGenerate.offer = false;
          }, 5000)
        } else {
          this.offer_doc_url = 'error'
          setTimeout(() => {
            this.offer_doc_url = ''
          }, 5000)
        }
      }
    },
    async generateReport() {
      if (this.$v.report_type.$invalid || this.$v.report_start_data.$invalid) {
        this.$v.report_type.$touch()
        this.$v.report_start_data.$touch()
        return;
      } else {
        if (this.report_type === 'equal_amount') {
          this.generateMonthArray(moment(this.report_start_data).locale('de'))
        }
        const data = JSON.parse(JSON.stringify(this.report));
        data.type_document = 'report'
        data.contract_id = this.contract.contract_id
        data.start_meter_read_date = moment(this.report.start_meter_read_date).format('DD.MM.YYYY')
        data.end_meter_read_date = moment(this.report.end_meter_read_date).format('DD.MM.YYYY')
        const response = await this.$services.sendOfferData.callMethod(data)
        const result = await response.json()
        if (typeof result === 'string') {
          this.report_doc_url = result
          this.successGenerate.report = true;
          setTimeout(() => {
            this.successGenerate.report = false;
          }, 5000)
        } else {
          this.report_doc_url = 'error'
          setTimeout(() => {
            this.report_doc_url = ''
          }, 5000)
        }
      }

    },
    async getAddresses(event, plz = '', city = '') {
      this.addresses.postcode = [];
      this.addresses.city = [];
      this.addresses.street = [];
      setTimeout(async () => {
        const response = await fetch(`https://public.opendatasoft.com/api/explore/v2.1/catalog/datasets/georef-germany-postleitzahl/records?limit=100${plz !== '' && plz ? '&refine=plz_code:' + '"' + plz + '"' : ''}${city !== '' && city ? '&refine=plz_name:' + '"' + city + '"' : ''}${event ? '&where=' + '\"' + event + '\"' : ''}`);
        const result = await response.json();
        for (const address in result.results) {
          const plzCode = result.results[address].plz_code;
          const lanName = result.results[address].lan_name;
          const plzName = result.results[address].plz_name;

          if (!this.addresses.postcode.includes(plzCode)) {
            this.addresses.postcode.push(plzCode);
          }

          if (!this.addresses.city.includes(plzName)) {
            this.addresses.city.push(plzName);
          }

        }
      }, 1);
    },
    async getIbanData(event) {
      const value = event.target.value
      const response = await this.$services.getBankInfoAndBic.callMethod({iban: value})
      const result = await response.json()
      if ('error' in result) {
        this.iban.text = result.error
        this.iban.valid = false
      } else {
        this.iban.valid = true
        this.counters.bic = result.bic
        this.counters.banks_name = result.bank_name

      }
    },
    createAll() {
      this.$emit('createAll')
    },
    createCustomer() {
      this.$emit('createCustomer')
    },

    openConfirm(typeDelete) {
      this.isConfirmExit = true
      this.isTypeDelete = typeDelete
    },
    closePopup() {
      this.isNewUser = false
      this.$v.customer.$reset()
      this.isActive = 'address'
      this.isActiveCounter = 'Counter Data'
      this.isConfirmExit = false
      if (this.isTypeDelete === 'normal') {
        this.$emit('closePopup')
      } else {
        this.$emit('closeAllPopup')
      }
    },
    stepContact() {
      if (this.$v.customer.type_client.$invalid &&
        this.$v.customer.addressing_customers.$invalid &&
        this.$v.customer.surname.$invalid &&
        this.$v.customer.name.$invalid &&
        this.$v.customer.accommodation_street.$invalid &&
        this.$v.customer.accommodation_house_number.$invalid &&
        this.$v.customer.accommodation_postcode.$invalid &&
        this.$v.customer.accommodation_city.$invalid) {
        this.$v.customer.type_client.$touch()
        this.$v.customer.addressing_customers.$touch()
        this.$v.customer.surname.$touch()
        this.$v.customer.name.$touch()
        this.$v.customer.accommodation_street.$touch()
        this.$v.customer.accommodation_house_number.$touch()
        this.$v.customer.accommodation_postcode.$touch()
        this.$v.customer.accommodation_city.$touch()
        return
      } else {
        this.isActive = 'contact'
      }
    },
    showCounters() {
      if (this.$v.customer.$invalid) {
        this.$v.customer.$touch()
        return
      } else {
        this.$emit('showCounters')
      }
    },
    async getPaginationCountersPopup() {
      this.searchCountersPopup = false
      try {
        let result = await this.getCounters(this.countNextPageCountersPopup)
        this.counters = [...this.counters, ...result]
        this.countNextPageCountersPopup++
      } catch (e) {
        this.isLoadingCountersPopup = false
        this.checkHeightCountersPopup = true
      }
    },
    handleScrollTableCounters() {
      if (this.isPopupEditCustomer) {
        this.$emit('handleScrollTableCounters', this.$refs.scrollPopupCounters)
      }
    },
    handleScrollTableContracts() {
      if (this.isPopupEditCustomer) {
        this.$emit('handleScrollTableContracts', this.$refs.scrollPopupContracts)
      }
    },
    openSystemFile() {
      this.$refs.systemPopup.click()
    },
    callValidation(name) {
      this.$v[name].$touch()
    },
    searchOperators(event) {
      this.$emit('searchOperators', event)
    }
  },
  async created() {
    this.debounceGetCountersPopup = debounce(this.getPaginationCountersPopup, 200)
  },
  watch: {
    async isPopupCustomerEditContracts(to) {
      if (to) {
        setTimeout(async () => {
          await this.getContractDocuments()
        }, 1000)
      }
    },
    isPopupCustomerContracts(to) {
      this.isActiveContract = 'Kunde'
      this.offer_doc_url = ''
      this.report_doc_url = ''
      this.report_start_data = null
      this.report_amount_month = ''
      this.report_type = 'equal_amount'
      this.report = {
        monthly_amounts: [],
        start_meter_read_date: '',
        start_meter_read_value: '',
        end_meter_read_date: '',
        end_meter_read_value: '',
      }
      this.offerToggleOperator = false
      this.offer = {
        offer_counters_type: null,
        offer_cost_monthly: null,
        offer_cost_per_day: null,
        qty_month: null,
        bonus: null,
        offer_operator: null
      }
      this.offer_cost_per_night = null
    },
    'offer.offer_counters_type'(to) {
      this.$v.offer.$reset()
      this.$v.offer_cost_per_night.$reset()
    },
    'report_type'(to) {
      this.report.monthly_amounts = []
      this.report_start_data = null
      this.report_amount_month = null
    },
    telegramCustomerId(to) {
      this.messageNewUser = `Hallo, Sie werden von Energia LLC kontaktiert, wir senden Ihnen einen Link zu einem Telegram-Bot. Ihnen wurde eine eindeutige Nummer zugewiesen - ${to}.
Nachfolgend finden Sie Anweisungen, wie Sie den Bot verwenden können:
1. Folgen Sie dem Link und schreiben Sie "/start" an den Bot.
2. Senden Sie dann die eindeutige Nummer "${to}" als Antwort auf eine Nachricht und der Telegram-Bot wird mit Ihnen verbunden.
https://t.me/test_energy_1_bot`
    },
    checkAddressCounter(to) {
      if (to) {
        this.counters.counter_postcode = this.customer.accommodation_postcode
        this.counters.counter_city = this.customer.accommodation_city
        this.counters.counter_street = this.customer.accommodation_street
        this.counters.counter_house_number = this.customer.accommodation_house_number
      } else {
        this.counters.counter_postcode = ''
        this.counters.counter_city = ''
        this.counters.counter_street = ''
        this.counters.counter_house_number = ''
      }
    },
    async isPopupCustomer(to) {
      if (to) {
        await this.getAddresses()
      }
    }
  }
}
</script>

<style lang="stylus">
.report

  &__input
    min-width 25%
    margin-left: -1px !important

    label
      white-space: nowrap

    &:first-child
      input
        margin-left: 0 !important
        border-radius 4px 0 0 4px

    &:last-child
      input
        border-radius 0 4px 4px 0

    input
      border-radius 0
      padding-left: 8px !important
      padding-right: 8px !important


  &__generate
    display flex
    align-items center

    a
      margin-left: 8px

  &__table
    margin-bottom: 16px

    tr:before
      border-color #b6bed8
      border-radius 4px

    th
      font-weight: 500
      padding: 4px

    td
      width: 50%

      &:first-child
        padding: 8px !important

    input
      width: calc(100% + 2px)
      height: 32px
      outline none
      border 1px solid #b6bed8
      padding-left: 16px
      border-radius 0 4px 4px 0

  &__td
    &-input
      padding: 0 !important
</style>

<style lang="stylus">
.telegram-text
  display flex
  flex-direction column
  align-items center

  .blue-button
    margin-top: 32px
</style>

<style lang="stylus">
@import "@/assets/stylus/variables.styl"

.create-customer
  position fixed
  left: 50%
  top: 50%
  transform translate(-50%, -50%)
  max-width 1140px
  width: 100%
  height 90vh
  max-height 700px
  background-color #F7F8FD
  z-index 100
  border-radius 4px 4px 0 4px
  padding: 24px 38px 36px

  &__group-button
    max-width 1440px
    width: 100%
    margin: 0 auto
    position fixed
    left: 50%
    transform translateX(-50%)
    bottom: 6px
    display flex
    justify-content flex-end
    z-index 1000

    button + button
      margin-left: 16px

    button
      padding: 9px 24px !important

  &__table
    max-height 100%

  &__td
    &-status
      span
        margin-left 12px
        width: 16px
        height: 16px
        background #fdab3d
        border-radius 100%
        display block

    &-counter
      div
        display flex
        flex-direction column
        align-items flex-start

        span
          color $blue
          font-weight: 500

        p
          color #999999
          margin-top: 4px

    &-address
      line-height 1.3

    &-start_update
      div
        display flex
        flex-direction column
        align-items flex-start

        .start
          color #999999
          margin-bottom: 4px

  &__breadcrumb
    display flex
    align-items center
    font-size: 24px
    font-weight: 500
    margin-bottom: 24px

    h4
      color #999999

    span
      display block
      width: 12px
      height: 12px
      border-bottom 2px solid #999999
      border-right 2px solid #999999
      transform rotate(-45deg)
      margin: 0 16px

  &__navbar
    border-bottom 1px solid #B6BED8
    display flex
    justify-content space-between

    a
      font-size: 20px
      margin-left: 32px
      color #B6BED8
      padding-bottom: 13px
      transition 0.2s
      border-bottom 2px solid #F7F8FD

      &:first-child
        margin-left: 0

    &-new-counter
      font-size: 16px !important
      color $blue !important
      display flex
      align-items center

      span
        margin-right: 10px
        width: 11px
        height: 11px
        position relative

        &:after
        &:before
          content ''
          display block
          width: 11px
          height: 2px
          background $blue
          border-radius 8px
          position absolute
          top: 4px

        &:after
          transform rotate(-90deg)


    &-link-active
      border-bottom 2px solid $blue !important
      color $blue !important
      text-shadow 2px 2px 4px rgba(31, 85, 250, 0.3)

    &-link-disable
      opacity 0.5
      cursor not-allowed

    &-link-invalid
      color #c33 !important
      text-shadow 2px 2px 4px rgba(255, 59, 59, 0.3) !important

  &__form
    display flex
    align-items flex-start
    justify-content space-between
    padding-top: 32px
    height calc(100% - 84px)

  &__block

    &-group-buttons
      display flex
      flex-direction column
      align-items center
      max-width 201px
      width: 100%
      margin-top: 20px

      button
        display flex
        justify-content center
        width: 100%

        &:first-child
          margin-bottom: 18px

      a
        margin-top: 32px
        color #5A5A5A

    &-group-inputs
      width 655px

    &_50_50
      display flex
      align-items flex-start

      div
        width: 100%

      div + div
        margin-left: 16px

    &_65_35
      display flex
      align-items flex-start

      div
        width: 100%

      div + div
        width: 45%
        margin-left: 16px

    &_35_65
      display flex
      align-items flex-start

      & > div
        width: 45%

      & > div + div
        width: 100%
        margin-left: 16px


</style>

<style lang="stylus">

.confirm-exit
  position fixed
  left: 50%
  top: 50%
  transform translate(-50%, -50%)
  max-width 1000px
  width: 500px
  height 250px
  background-color #F7F8FD
  z-index 200
  border-radius 4px 4px 0 4px
  padding: 24px 38px 36px

  &__show
    z-index 190 !important

  h2
    text-align center

  p
    text-align center
    font-size: 18px
    margin-top: 18px
    line-height 1.5

  div
    display flex
    align-items center
    justify-content center
    position absolute
    bottom: 32px
    left: 0
    width: 100%

    button + button
      margin-left: 16px
</style>

<style lang="stylus">


.create-counter
  position fixed
  left: 50%
  top: 50%
  transform translate(-50%, -50%)
  max-width 1000px
  width: 100%
  height 90vh
  max-height 918px
  background-color #F7F8FD
  z-index 110
  border-radius 4px 4px 0 4px
  padding: 24px 38px 36px

  &__checkbox
    display flex
    align-items center
    font-style italic
    font-size: 14px
    color #999999

    div
      width: 14px
      margin-right: 8px

  &__line
    width: 100%
    height: 1px
    background #B6BED8
    margin-bottom: 18px

  &__form
    margin-top: 40px
    display flex
    justify-content space-between
    height calc(100% - 144px)

    .create-customer__block-group-buttons
      margin-left: 0 !important

  &__block
    width: 580px
    height calc(90vh - 228px)
    overflow auto
</style>

<style lang="stylus">
@import "@/assets/stylus/variables.styl"
.create-documents
  position fixed
  left: 50%
  top: 50%
  transform translate(-50%, -50%)
  max-width 1000px
  width: 100%
  height 90vh
  max-height 773px
  background-color #F7F8FD
  z-index 116
  border-radius 4px 4px 0 4px
  padding: 24px 38px 36px

  &__open-file
    text-align center
    margin-top: 24px
    display block
    border 1px solid $blue
    padding: 12px 16px
    border-radius 4px


  &__block-group-buttons
    display flex
    align-items center
    flex-direction column
    margin-top: 18px

    button
      padding: 14px 70px

    a
      color #5A5A5A
      margin-top: 32px

  &__upload-file
    img
      position absolute
      right 8px !important
      top: 43px !important
      left auto !important

    input
      cursor pointer !important
      border 1px solid #b6bed8 !important
      padding-right: 42px

    div
      position absolute
      left: 0
      right: 0
      bottom: 0
      top: 0
      z-index 1
      cursor pointer

  .create-customer__breadcrumb
    font-size: 16px

    span
      width: 8px
      height: 8px
</style>

<style lang="stylus">
.doc-active
  background #00C875 !important

.doc-close
  background #c33 !important

.general-class-customer
  .table
    td:first-child
      padding: 4px

  .show-counter
    z-index 102

  .show-contracts
    z-index 114

.fixed-button
  position fixed
  bottom: -36px
  right: 0
  z-index 1000
  padding: 10px 24px !important

</style>
