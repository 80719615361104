<template>
    <base_layout>
        <transition name="opacity">
            <message v-if="isMessage" @resetMessage="resetMessage" :success="successMessage"
                     :message="addMessage"/>
        </transition>
        <div class="content">
            <div class="telegram">
                <div class="customers__instruments">
                    <div class="customers__title-and-filters">
                        <h1 class="title-page">Telegram bot</h1>
                    </div>
                    <div class="group-btn">
                        <button @click.prevent="$refs.popupBirthday.togglePopup()" class="blue-button">Geburtstag öffnen
                            Vorlage
                        </button>
                        <button @click.prevent="$refs.popupTelegramBot.togglePopup()" class="blue-button"><span></span>
                            Ein Ereignis erstellen
                        </button>
                    </div>
                </div>
                <div v-if="mainLoader" :class="{'fadein-animation': !mainLoader}" class="big-loader loader">
                    <span></span>
                </div>
                <div v-else :class="{'fadein-animation': !mainLoader}" ref="scrollContainer"
                     class="table">
                    <table v-if="eventList.length" cellspacing="0" cellpadding="0">
                        <thead>
                        <tr>
                            <td class="text-transform-uppercase" style="padding-left: 24px !important;">ID</td>
                            <td class="text-transform-uppercase">Typ Kunde</td>
                            <td class="text-transform-uppercase">Deadline</td>
                            <td class="text-transform-uppercase">Anzahl der Tage</td>
                            <td class="text-transform-uppercase">Nachricht</td>
                            <td class="text-transform-uppercase">Aktion</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="(item, index) in eventList"
                            :key="`event_${index}`"
                        >
                            <td class="table__column-id">
                                <a href="#" @click.prevent="openEditTaskBot(item)">#{{ item.id }}</a>
                            </td>
                            <td>{{ item.type_client }}</td>
                            <td>{{ deadlineTransform(item.deadline, item.type_deadline) }}</td>
                            <td>{{ item.days_before_deadline }}</td>
                            <td style="width: 45%;">{{ item.message_text }}</td>
                            <td style="position: relative">
                                <a @click.prevent="deleteWindow = index" style="position: relative; z-index: 1;"
                                   href="#">
                                    <img src="@/assets/images/trash_icon.svg" alt="trash icon">
                                </a>
                                <div v-if="deleteWindow === index" class="warning-delete warning-delete-small">
                                    Sind Sie sicher, dass Sie den Client entfernen möchten?
                                    <div>
                                        <button @click.prevent="deleteTaskBot(item.id)">Ja</button>
                                        <button @click.prevent="deleteWindow = null">Nein</button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div v-else-if="eventList.length === 0" class="warn-blank-table">Die Tabelle enthält keine Daten
                    </div>
                    <div v-else class="loader"><span></span></div>
                </div>
            </div>
        </div>
        <create-event-bot
            @createTaskBot="createTaskBot"
            @editTaskBot="editTaskBot"
            @resetDataEvent="_resetDataEvent"
            @changeCustomerId="changeCustomerId"
            v-model="customer_id"
            :new-event="newEvent"
            ref="popupTelegramBot"
        />
        <Birthday
          @errorMessage="showMessage(false, 'Die Vorlage konnte nicht geändert werden, versuchen Sie es erneut.')"
          @successMessage="showMessage(true, 'Die Vorlage wurde erfolgreich geändert.')"
          ref="popupBirthday"
        />
    </base_layout>
</template>

<script>
import Base_layout from "@/layouts/base_layout.vue";
import CreateEventBot from "@/components/popups/createEventBot.vue";
import Birthday from "@/components/popups/Birthday.vue";
import message from "@/components/message.vue";
import success_error_state from "@/mixins/components/success_error_state";
import dateFilter from "@/filters/date.filter";
import notification from "@/mixins/notification";

export default {
    name: "telegram-bot_page",
    components: {message, Birthday, CreateEventBot, Base_layout},
    mixins: [success_error_state, notification],
    data() {
        return {
            deleteWindow: null,
            mainLoader: false,
            customer_id: '',
            newEvent: {
                type_client: null,
                type_deadline: null,
                deadline: null,
                qtyDay: null,
                message_text: ''
            },
            eventList: []
        }
    },
    methods: {
        changeCustomerId(val) {
          this.customer_id = val
        },
        deadlineTransform(deadline, type_deadline) {
            if (type_deadline === 'custom_data') {
                return dateFilter(deadline, 'date')
            } else if (type_deadline === 'deadline_task') {
                return 'Auf die Frist in der Aufgabenliste'
            } else if (type_deadline === 'end_contract') {
                return 'Bei Abschluss der Verträge'
            }
        },
        _resetDataEvent() {
            this.newEvent = {
                type_client: null,
                type_deadline: null,
                deadline: null,
                qtyDay: null,
                message_text: ''
            }
        },
        _serializedTaskBot() {
            const obj = {
                days_before_deadline: Number(this.newEvent.qtyDay),
                message_text: this.newEvent.message_text,
            }

            if (this.newEvent.type_client === 'one_customer') {
              obj.type_client = this.customer_id
            } else {
              obj.type_client = this.newEvent.type_client
            }
            if (this.newEvent.type_deadline === 'custom_data') {
                obj.deadline = this.newEvent.deadline
            } else {
                obj.deadline = this.newEvent.type_deadline
            }
            if (this.$refs.popupTelegramBot.isEditPopup) {
                obj.task_id = this.newEvent.task_id
            }
            return obj
        },
        async initEventTaskBot(type, errorMessage, successMessage) {
            try {
                const response = await this.$services[type].callMethod(this._serializedTaskBot())
                const result = await response.json()
                await this.getTaskBot()
                if (result.status === 'error') {
                    this.showMessage(false, errorMessage)
                } else {
                    this.showMessage(true, successMessage)
                }
            } catch (e) {
                this.showMessage(false, errorMessage)
                console.error('[ERROR]' + e)
            } finally {
                this._resetDataEvent()
                this.$refs.popupTelegramBot.togglePopup()
            }

        },
        async createTaskBot() {
            await this.initEventTaskBot(
                'createTaskBot',
                'Die Erstellung eines neuen Ereignisses ist fehlgeschlagen, versuchen Sie es erneut.',
                'Ereignis erfolgreich erstellt'
            )
        },
        async editTaskBot() {
            await this.initEventTaskBot(
                'editTaskBot',
                'Das Ereignis konnte nicht geändert werden, versuchen Sie es erneut.',
                'Das Ereignis wurde erfolgreich geändert.'
            )
        },
        async openEditTaskBot(item) {
            this.$refs.popupTelegramBot.togglePopup()
            this.$refs.popupTelegramBot.isEditPopup = true
            this.newEvent = {
                type_client: item.type_client,
                qtyDay: item.days_before_deadline,
                task_id: item.id,
                message_text: item.message_text,
                type_deadline: item.type_deadline,
            }
            if (item.type_deadline === 'custom_data') {
                this.newEvent.deadline = dateFilter(item.deadline, 'date')
            }
        },
        async getTaskBot() {
            const response = await this.$services.getTaskBot.callMethod()
            this.eventList = await response.json()
        },
        async deleteTaskBot(id) {
            try {
                const response = await this.$services.deleteTaskBot.callMethod({task_id: id})
                const result = await response.json()
                if (result.status === 'error') {
                    this.showMessage(false, 'Das Ereignis konnte nicht gelöscht werden, versuchen Sie es erneut.')
                } else {
                    this.showMessage(true, 'Das Ereignis wurde erfolgreich gelöscht.')
                }
                await this.getTaskBot()
            } catch (e) {
                this.showMessage(false, 'Das Ereignis konnte nicht gelöscht werden, versuchen Sie es erneut.')
            } finally {
                this.deleteWindow = null
            }
        }
    },
    async mounted() {
        this.mainLoader = true
        await this.getTaskBot()
        this.mainLoader = false
    }
}
</script>

<style scoped lang="stylus">

.telegram
    & .table
        margin-top: 58px
        & td, th
            vertical-align baseline

</style>