import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import services from "@/plugins/services";
import Vuelidate from "vuelidate";

import dateFilter from "@/filters/date.filter";
import typeUserFilter from "@/filters/typeUser.filter";
import documentSizeFilter from "@/filters/documentSize.filter";

import events from "@/plugins/events";
import notification from "@/mixins/notification";

import Datepicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css';
import en from 'vue2-datepicker/locale/en';

Vue.prototype.$datepickerLocale = en;

Vue.use(Vuelidate)
Vue.use(services)
Vue.use(events)
Vue.filter('date', dateFilter)
Vue.filter('typeUser', typeUserFilter)
Vue.filter('size', documentSizeFilter)
Vue.component('datepicker', Datepicker);

router.beforeEach(async (to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const token = localStorage.getItem('auth_token');
    await store.dispatch('user/fetchUser')
    if (requiresAuth && !token) {
        next('/auth');
    } else {
        next();
    }
})

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
