<template>
    <button class="blue-button" @click="copyText">Text kopieren</button>
</template>

<script>
export default {
  props: {
    copy: ''
  },
  methods: {
    copyText() {
      const textarea = document.createElement("textarea");
      textarea.value = this.copy;

      document.body.appendChild(textarea);
      textarea.select();

      document.execCommand("copy");
      document.body.removeChild(textarea);

      console.log("Текст скопирован: " + this.copy);
    }
  }
};
</script>

<style>
</style>
