<template>
  <div>
    <div v-if="isPopup" @click="togglePopup" class="mask"></div>
    <transition v-if="isPopup" name="popup">
      <div class="popup">
        <button @click="togglePopup" class="popup__close-btn"></button>
        <h2 v-if="isPopupEdit">Benutzer bearbeiten</h2>
        <h2 v-else>Benutzer erstellen</h2>
        <div class="mt-24 w100">
          <base-field-input label="Benutzername" :src_img="require('@/assets/images/popup-assign_icon.svg')">
            <input class="show-icon" placeholder="Benutzername" type="text" v-model="user.username">
            <div v-if="($v.user.username.$dirty && !$v.user.username.required)"
                 class="popup-customer__error">
              Dieses Feld ist obligatorisch
            </div>
          </base-field-input>
        </div>
        <div class="mt-24 w100">
          <base-field-input label="Name" :src_img="require('@/assets/images/popup-assign_icon.svg')">
            <input class="show-icon" placeholder="Name" type="text" v-model="user.first_name">
            <div v-if="($v.user.first_name.$dirty && !$v.user.first_name.required)"
                 class="popup-customer__error">
              Dieses Feld ist obligatorisch
            </div>
          </base-field-input>
        </div>
        <div class="mt-24 w100">
          <base-field-input label="Vorname" :src_img="require('@/assets/images/popup-assign_icon.svg')">
            <input class="show-icon" placeholder="Vorname" type="text" v-model="user.last_name">
            <div v-if="($v.user.last_name.$dirty && !$v.user.last_name.required)"
                 class="popup-customer__error">
              Dieses Feld ist obligatorisch
            </div>
          </base-field-input>
        </div>
        <div v-if="isPopupEdit" class="mt-24 w100" style="margin-bottom: 16px;">
          <div style="display: flex; align-items: center; transform: translateY(-4px); justify-content: flex-end">
            <span style="margin-right: 8px; font-size: 14px;">Möchten Sie Ihr Passwort ändern?</span>
            <div class="checkbox">
              <input v-model="isShowPassword" id="toggleTypeOfTask" type="checkbox">
              <label style="margin: 0;" for="toggleTypeOfTask"></label>
            </div>
          </div>
        </div>
        <div class="mt-24 w100" v-if="(isShowPassword && isPopupEdit) || isNewUser">
          <base-field-input label="Passwort" :src_img="require('@/assets/images/settings-password_icon.svg')">
            <input class="show-icon" placeholder="Passwort" type="text" v-model="password">
            <div v-if="($v.password.$dirty && !$v.password.required)"
                 class="popup-customer__error">
              Dieses Feld ist obligatorisch
            </div>
          </base-field-input>
        </div>
        <hr>
        <div class="settings__upload-image">
          Bild hochladen
          <button @click="$refs.image.click()" class="blue-button">
            <img src="@/assets/images/documents-upload_icon.svg" alt="">
            Hochladen
          </button>
          <span style="max-width: 200px; white-space: nowrap; overflow: hidden;">{{ nameImage }}</span>
          <input style="display: none" ref="image" @change="handleFileChange" type="file">
        </div>
        <div class="popup__group-btn">
          <a @click.prevent="togglePopup" href="#">Cancel</a>
          <button v-if="isPopupEdit" @click.prevent="editUser" class="blue-button">
            <span></span>
            Benutzer bearbeiten
          </button>
          <button v-else @click.prevent="createUser" class="blue-button">
            <span></span>
            Neuer Benutzer
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import BaseFieldInput from "@/components/fields/BaseFieldInput.vue";
import validations from "@/mixins/settings/validations";

/**
 * @typedef {Object} User
 * @property {string} first_name - First name of the user.
 * @property {string} last_name - Last name of the user.
 * @property {string} name - User name.
 * @property {string} password - User password.
 * @property {string} image - User image.
 * @property {number} user_id - User ID.
 */

/** @type {User} */
const defaultUser = {
  first_name: null,
  last_name: null,
  name: null,
  password: null,
  image: null,
};

export default {
  name: 'createUser',
  components: {BaseFieldInput},
  mixins: [validations],
  data() {
    return {
      isNewUser: false,
      isShowPassword: false,
      isPopup: false,
      isPopupEdit: false,
      user: {...defaultUser},
      nameImage: null,
      password: null
    }
  },
  methods: {
    /**
     * Toggle the user creation popup.
     */
    togglePopup() {
      this.isPopup = !this.isPopup;
      this.$v.user.$reset();
      this.$v.password.$reset();

      if (!this.isPopup) {
        this.isPopupEdit = false;
        this.isNewUser = false;
        this.user = {...defaultUser};
        this.password = null
        this.isShowPassword = false;
      } else {
        this.isNewUser = true;
      }
    },
    /**
     * Handle user creation.
     */
    async createUser() {
      try {
        let result = await this.initEventUser('createUser');
        if (result.status === 'error') {
          this.$emit('userCreateError');
        } else {
          this.$emit('userCreateSuccess');
        }
      } catch (e) {
        if (!this.$v.user.$dirty) {
          this.$emit('userCreateError');
        }
      }
    },
    /**
     * Handle user editing.
     */
    async editUser() {
      try {
        let result = await this.initEventUser('editUser');
        if (result.status === 'error') {
          this.$emit('userEditError');
        } else {
          this.$emit('userEditSuccess');
        }
      } catch (e) {
        this.$emit('userError');
      }
    },

    /**
     * Initialize user event.
     * @param {string} service - Service for the user event.
     * @returns {Promise<Object>} - Promise with the result of the user event.
     */
    async initEventUser(service) {
      this.$v.user.$touch();
      this.$v.password.$touch();

      if (this.$v.user.$invalid || (this.isShowPassword && this.$v.password.$invalid)) {
        return;
      }

      try {
        const formData = new FormData();
        const file = this.$refs.image.files[0];
        if (!this.password || this.password === '') {
          delete this.user.password
        } else {
          this.user.password = this.password
        }
        formData.append('user', JSON.stringify(this.user));
        formData.append('file', file);

        let response = await this.$services[service].callMethod(formData);
        this.$emit('getUsers', await this.getUsers());
        return await response.json();
      } catch (error) {
        console.error(`[ERROR] ${error.message} Failed to create user`);
      } finally {
        this.togglePopup();
        this.user = {...defaultUser};
        this.password = null
      }
    },

    /**
     * Open the user edit popup.
     * @param {User} user - User to edit.
     */
    async editOpenPopup(user) {
      this.isPopupEdit = true;
      this.isPopup = true;
      this.user.username = user.username;
      this.user.first_name = user.first_name;
      this.user.last_name = user.last_name;
      this.user.user_id = user.id;
    },

    /**
     * Handle file change event.
     */
    handleFileChange() {
      const selectedFile = this.$refs.image.files[0];
      this.nameImage = selectedFile.name;
    },

    /**
     * Get the list of users.
     * @returns {Promise<Object>} - Promise with the list of users.
     */
    async getUsers() {
      try {
        let response = await this.$services.getUsers.callMethod();
        return await response.json();
      } catch (e) {
        console.error('[ERROR] ' + e.status)
      }
    },

    /**
     * Delete a user.
     * @param {number} id - User ID to delete.
     */
    async deleteUser(id) {
      try {
        this.$emit('deleteWindow');
        let response = await this.$services.deleteUser.callMethod({ user_id: id });
        if (await response.json().status === 'error') {
          this.$emit('userDeleteError');
        } else {
          this.$emit('userDeleteSuccess');
        }
        this.$emit('getUsers', await this.getUsers());
      } catch (e) {
        this.$emit('userDeleteError');
      }
    },
  }
}
</script>

<style lang="stylus">

</style>