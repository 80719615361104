<template>
  <base-layout>
    <div style="position: relative; min-height: calc(100vh - 116px)">
      <div v-if="mainLoader" :class="{'fadein-animation': !mainLoader}" class="big-loader loader"><span></span>
      </div>
      <div v-else :class="{'fadein-animation': !mainLoader}" class="content dashboard">
        <div style="width: 100%;" class="tasks__title-and-buttons">
          <h1 class="title-page">Statistics</h1>
        </div>
        <div class="dashboard__switch-period">
          <div class="switch-container">
            <input v-model="toggleSwitch" type="checkbox" id="switch" name="switch"/>
            <label for="switch" class="switch-label"></label>
          </div>
          <p>Anzeige nach Monat</p>
        </div>
        <div class="dashboard__period-date">
          <div class="dashboard__input">
            <base-field-input label="ANFANGSDATUM">
              <datepicker
                v-model="period.start_period_date"
                :type="toggleSwitch ? 'month' : 'year'"
                :placeholder="toggleSwitch ? 'YYYY.MM' : 'YYYY'"
                :format="toggleSwitch ? 'YYYY.MM': 'YYYY'"
              ></datepicker>
            </base-field-input>
          </div>
          <span>-</span>
          <div class="dashboard__input">
            <base-field-input label="ENDE-DATUM">
              <datepicker
                v-model="period.end_period_date"
                :type="toggleSwitch ? 'month' : 'year'"
                :placeholder="toggleSwitch ? 'YYYY.MM' : 'YYYY'"
                :format="toggleSwitch ? 'YYYY.MM': 'YYYY'"
              ></datepicker>
            </base-field-input>
          </div>
          <button @click.prevent="getStatistics" class="blue-button">Statistiken abrufen</button>
        </div>
        <div v-if="showPeriod" class="dashboard__get-period">
          Statistics for the period <span v-if="!toggleSwitch">01.01.</span>{{ formatDate(period.start_period_date) }} -
          <span v-if="!toggleSwitch">31.12.</span>{{ formatDate(period.end_period_date) }}
        </div>
        <div v-else class="dashboard__get-period">
          Statistiken für den gesamten Zeitraum
        </div>
        <div class="table dashboard__table">
          <table cellspacing="0" cellpadding="0">
            <thead>
            <tr>
              <td class="text-transform-uppercase">Typ Kunde</td>
              <td class="text-transform-uppercase">Anzahl der Kunden</td>
              <td class="text-transform-uppercase">Anzahl der Gaszähler</td>
              <td class="text-transform-uppercase">Verbrauch von Gaszählern</td>
              <td class="text-transform-uppercase">Anzahl der Stromzähler</td>
              <td class="text-transform-uppercase">Verbrauch von Stromzählern</td>
              <td class="text-transform-uppercase">Gesamtzahl der Zähler</td>
            </tr>
            </thead>
            <tbody class="dashboard__table">
            <tr>
              <td class="dashboard__table-td-type">
                <div>
                  <StatisticPrivate
                    width="16"
                  />
                  Privatkunden
                </div>
              </td>
              <td style="font-weight: 600;">
                {{ statistics.privat.privat_qty_customer }}
              </td>
              <td class="dashboard__table-td">
                <div>
                  <StatisticsGasCount/>
                  {{ statistics.privat.qty_gas }}
                </div>
              </td>
              <td class="dashboard__table-td">
                <div>
                  <StatisticsGasConsumption/>
                  {{ statistics.privat.amount_consumption_gas }}
                </div>
              </td>
              <td class="dashboard__table-td">
                <div>
                  <StatisticsEnergyCount/>
                  {{ statistics.privat.qty_electricity }}
                </div>
              </td>
              <td class="dashboard__table-td">
                <div>
                  <StatisticsEnergyConsumption/>
                  {{ statistics.privat.amount_consumption_electricity }}
                </div>
              </td>
              <td class="dashboard__table-td">
                <div>
                  <StatisticsTotalCount/>
                  {{ statistics.privat.qty_gas + statistics.privat.qty_electricity }}
                </div>
              </td>
            </tr>
            <tr>
              <td class="dashboard__table-td-type">
                <div style="color: rgb(81, 123, 251)">
                  <StatisticsCompany
                    width="16"
                  />
                  Geschäftskunden
                </div>
              </td>
              <td style="font-weight: 600;">
                {{ statistics.company.company_qty_customer }}
              </td>
              <td class="dashboard__table-td">
                <div>
                  <StatisticsGasCount/>
                  {{ statistics.company.qty_gas }}
                </div>
              </td>
              <td class="dashboard__table-td">
                <div>
                  <StatisticsGasConsumption/>
                  {{ statistics.company.amount_consumption_gas }}
                </div>
              </td>
              <td class="dashboard__table-td">
                <div>
                  <StatisticsEnergyCount/>
                  {{ statistics.company.qty_electricity }}
                </div>
              </td>
              <td class="dashboard__table-td">
                <div>
                  <StatisticsEnergyConsumption/>
                  {{ statistics.company.amount_consumption_electricity }}
                </div>
              </td>
              <td class="dashboard__table-td">
                <div>
                  <StatisticsTotalCount/>
                  {{ statistics.company.qty_gas + statistics.company.qty_electricity }}
                </div>
              </td>
            </tr>
            <tr>
              <td class="dashboard__table-td-type">
                <div style="color: #cc3333">
                  <StatisticsRlm
                    width="16"
                  />
                  RLM
                </div>
              </td>
              <td style="font-weight: 600;">
                {{ statistics.rlm.rlm_qty_customer }}
              </td>
              <td class="dashboard__table-td">
                <div>
                  <StatisticsGasCount/>
                  {{ statistics.rlm.qty_gas }}
                </div>
              </td>
              <td class="dashboard__table-td">
                <div>
                  <StatisticsGasConsumption/>
                  {{ statistics.rlm.amount_consumption_gas }}
                </div>
              </td>
              <td class="dashboard__table-td">
                <div>
                  <StatisticsEnergyCount/>
                  {{ statistics.rlm.qty_electricity }}
                </div>
              </td>
              <td class="dashboard__table-td">
                <div>
                  <StatisticsEnergyConsumption/>
                  {{ statistics.rlm.amount_consumption_electricity }}
                </div>
              </td>
              <td class="dashboard__table-td">
                <div>
                  <StatisticsTotalCount/>
                  {{ statistics.rlm.qty_gas + statistics.rlm.qty_electricity }}
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </base-layout>
</template>

<script>
import baseLayout from "@/layouts/base_layout";
import togglePopup from "@/mixins/components/togglePopup";
import BaseFieldInput from "@/components/fields/BaseFieldInput.vue";
import StatisticPrivate from "@/components/icons/StatisticPrivate.vue";
import StatisticsCompany from "@/components/icons/StatisticsCompany.vue";
import StatisticsRlm from "@/components/icons/StatisticsRlm.vue";
import StatisticsEnergyCount from "@/components/icons/StatisticsEnergyCount.vue";
import StatisticsEnergyConsumption from "@/components/icons/StatisticsEnergyConsumption.vue";
import StatisticsGasCount from "@/components/icons/StatisticsGasCount.vue";
import StatisticsGasConsumption from "@/components/icons/StatisticsGasConsumption.vue";
import moment from 'moment'
import SidebarArchive from "@/components/icons/SidebarArchive.vue";
import notification from "@/mixins/notification";
import StatisticsTotalCount from "@/components/icons/StatisticsTotalCount.vue";

export default {
  name: "dashboard_page",
  components: {
    StatisticsTotalCount,
    SidebarArchive,
    StatisticsGasConsumption,
    StatisticsGasCount,
    StatisticsEnergyConsumption,
    StatisticsEnergyCount,
    StatisticsRlm, StatisticsCompany, StatisticPrivate, baseLayout, BaseFieldInput
  },
  mixins: [togglePopup, notification],
  data() {
    return {
      toggleSwitch: false,
      user: null,
      statistics: {},
      period: {
        start_period_date: '',
        end_period_date: ''
      },
      showPeriod: false,
      mainLoader: true
    }
  },
  methods: {
    async eventInput(field) {
      this.showPeriod = false
      this.period[field] = ''
      await this.getStatistics()
    },
    async getStatistics() {
      let response
      try {
        if (this.period.start_period_date !== '' && this.period.end_period_date !== '') {
          const start_period_date = this.formatDate(this.period.start_period_date)
          const end_period_date = this.formatDate(this.period.end_period_date)
          response = await this.$services.getStatistics.callMethod({
            start_period_date, end_period_date
          })
          this.showPeriod = true
        } else {
          response = await this.$services.getStatistics.callMethod()
        }
        this.statistics = await response.json()
      } catch (e) {
      }
    },
    formatDate(date) {
      if (this.toggleSwitch) {
        return moment(date).format('MM.YYYY')
      } else {
        return moment(date).format('YYYY')
      }
    }
  },
  async mounted() {
    await this.getStatistics()
    this.mainLoader = false
  },

}
</script>

<style lang="stylus">

.dashboard
  display flex
  flex-direction column
  align-items flex-start

  &__switch-period
    margin-top: 32px
    display flex
    align-items center
    font-weight: 500

    p
      margin-left: 8px

  &__table
    padding-top: 16px
    margin-top: 16px
    border-top 1px solid #d3d3d3
    border-radius 0

    &-td
      div
        display flex
        align-items center
        font-weight: 600

        svg
          margin-right: 6px

      &-type
        div
          display flex
          align-items center
          color #00c875
          font-weight: 500

          svg
            margin-right: 6px

  &__input
    max-width 200px

  &__get-period
    font-size: 20px
    font-weight: 500

  &__period-date
    display flex
    align-items center
    margin-top: 16px

    span
      display block
      margin: 0 16px

    button
      margin-left: 32px

  &__statistics
    padding: 36px 28px
    max-width 48%
    width: 100%

    &:first-child
      margin-right: 24px

    &:last-child
      margin-top: 24px

    &-content
      display flex
      flex-wrap wrap
      justify-content flex-start
      margin-top: 32px

    &-row
      display flex
      justify-content space-between
      align-items center

      &:first-child
        margin-bottom: 40px

    &-items
      margin-top: 50px

    &-item
      display flex
      align-items center

      &-icon
        min-width: 40px
        min-height: 40px
        background rgba(#CC3333, 0.2)
        border-radius 100%
        margin-right: 20px
        display flex
        align-items center
        justify-content center

      &-data
        font-size: 18px
        font-weight: 700

        span
          display block
          margin-top: 2px
          color #999999
          font-size: 16px
          font-weight: 400

    &-type-and-icon
      display flex
      align-items center
      justify-content space-between

    &-type
      color #00C875
      font-weight: 700

      span
        margin-top: 18px
        display block
        font-size: 32px

    &-icon
      width: 60px
      height: 60px
      border-radius 100%
      background rgba(#00C875, 0.2)
      display flex
      align-items center
      justify-content center

</style>
