<template>
  <!--  <customer_layout @resetMessage="resetMessage" :add-message="addMessage" :is-message="isMessage" :success-message="successMessage">-->
  <base_layout>
    <transition name="opacity">
      <message v-if="isMessage" @resetMessage="resetMessage" :success="successMessage"
               :message="addMessage"/>
    </transition>
    <div class="content">
      <div class="customer__content">
        <div class="customer__left-content">
          <div class="white-container">
            <div class="documents__navigate-and-upload">
              <div class="documents__navigate">
                <a @click.prevent="returnCustomerPage" href="#">Dokumente</a>
                <span></span>
                <a style="color: #232323">{{ $route.params.folder }}</a>
              </div>
              <button @click="openSystemFile" class="blue-button">
                <img src="@/assets/images/documents-upload_icon.svg" alt="">
                Upload
              </button>
              <input @change="handleFileChange" name="file" multiple ref="systemPopup" type="file" style="display: none">
            </div>
            <div class="customer__documents-table documents__table">
              <table>
                <thead>
                <tr>
                  <td class="text-transform-uppercase">Name</td>
                  <td class="customer__documents-table-column-small text-transform-uppercase">Größe</td>
                  <td class="documents__td-action text-transform-uppercase">Aktion</td>
                </tr>
                <tr class="documents__tr-back-link">
                  <td @click="returnCustomerPage" class="customer__documents-table-column-name">
                    <div>
                      <img src="@/assets/images/documents-back_icon.svg" alt="">
                      Vorheriger Ordner
                    </div>
                  </td>
                  <td class="customer__documents-table-column-small">-</td>
                  <td class="documents__td-action">-</td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(file, index) in files" :key="'file' + index">
                  <td class="customer__documents-table-column-name documents__td-type-document">
                    <a target="_blank" :href="url + file.file">
                <span
                  class="documents__td-icon"
                  :class="{
                      'documents__td-word': getExtension(file.file) === 'docx',
                      'documents__td-excel': getExtension(file.file) === 'xlsx',
                      'documents__td-pdf': getExtension(file.file) === 'pdf',
                      'documents__td-png': getExtension(file.file) === 'png',
                      'documents__td-jpg': getExtension(file.file) === 'jpg',
                      'documents__td-jpg': getExtension(file.file) === 'jpeg',
                      'documents__td-zip': getExtension(file.file) === 'zip',
                    }"
                >{{ getExtension(file.file) }}</span>
                      {{ file.file.slice(6) }}
                    </a>
                  </td>
                  <td class="customer__documents-table-column-small">{{ file.file_size | size() }}</td>
                  <td style="position: relative" class="documents__td-action">
                    <a @click.prevent="deleteWindowDocument = index" style="position: relative; z-index: 1;" href="#">
                      <img src="@/assets/images/trash_icon.svg" alt="trash icon">
                    </a>
                    <div v-if="deleteWindowDocument === index" class="warning-delete warning-delete-small">
                      Sind Sie sicher, dass Sie den Client entfernen möchten?
                      <div>
                        <button @click.prevent="deleteDocument(file.id, index)">Ja</button>
                        <button @click.prevent="deleteWindowDocument = null">Nein</button>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="customer__right-content fadein-animation">
          <PersonalData
            :customer-data="customer_data"
            @editOpenCustomer="editOpenCustomer(customer_data)"
          />
        </div>
      </div>
    </div>
    <create-customer
      :check-height-contracts-popup="checkHeightContracts"
      :count-next-page-contracts-popup="countNextPageContracts"
      :is-loading-contracts-popup="isLoadingContracts"
      :search-contracts-popup="searchContracts"
      :check-height-counters-popup="checkHeightCountersPopup"
      :is-loading-counters-popup="isLoadingCountersPopup"
      :search-counters-popup="searchCountersPopup"
      :customer="customer"
      :contract="contract"
      :counters="counters"
      :operators="operators"
      :is-popup-edit-customer="isPopupEditCustomer"
      :is-popup-customer-edit-counters="isPopupCustomerEditCounters"
      :is-popup-customer-edit-contracts="isPopupCustomerEditContracts"
      :is-popup-customer="isPopupCustomer"
      :is-popup-customer-counters="isPopupCustomerCounters"
      :is-popup-customer-contracts="isPopupCustomerContracts"
      @showCounters="showPopup('isPopupCustomerCounters')"
      @closePopup="closePopup"
      @showContracts="showPopup('isPopupCustomerContracts')"
      @addCounter="addCounter"
      @addContractInCounter="addContractInCounter"
      @editCustomer="editCustomer"
      @showEditCounter="editOpenCounter"
      @showEditContract="editOpenContract"
      @editCounter="editCounter"
      @editContractInCounter="editContract"
      @searchOperators="searchOperatorsInDropdown"
      @handleScrollTableCounters="handleScrollTableCounters"
      @handleScrollTableContracts="handleScrollTableContracts"
      ref="createCustomer"
    />
  </base_layout>
</template>

<script>
import base_layout from "@/layouts/base_layout";
import success_error_state from "@/mixins/components/success_error_state";
import actionCustomer from "@/mixins/customer/action";
import togglePopup from "@/mixins/components/togglePopup";
import validations from "@/mixins/customers/validations";
import lazyLoadScrollTable from "@/mixins/components/lazyLoadScrollTable";
import createCustomer from "@/components/popups/createCustomer.vue";
import message from "@/components/message";
import PersonalData from "@/components/elements/PersonalData.vue";
import notification from "@/mixins/notification";

export default {
  name: "documents_page",
  components: {PersonalData, base_layout, createCustomer, message},
  mixins: [success_error_state, togglePopup, actionCustomer, validations, lazyLoadScrollTable, notification],
  data() {
    return {
      url: window.location.origin,
      customer_id: '',
      title: '',
      new_document: '',
      files: [],
      countNextPage: 1,
      isLoading: false,
      searchInputs: '',
      countSearchNextPage: 1,
      deleteWindowDocument: null
    }
  },
  async created() {
    if (document.title.includes('folder')) {
      document.title = document.title.replace(/folder/g, this.$route.params.folder);
    }
    this.customer_id = this.$route.params.id.split('_')[1]
    await this.getDocuments()
  },
  methods: {
    getExtension(file) {
      const extension = file.split('.').pop();
      return extension.toLowerCase();
    },
    returnCustomerPage() {
      this.$router.push({
        name: 'customer_page',
        params: {id: `${this.customer_data.client.archive ? 'archive_' : 'actual_'}${this.customer_id}`}
      })
    },
    openSystemFile() {
      this.$refs.systemPopup.click()
    },
    async handleFileChange(event) {
      const files = Array.from(event.target.files);
      const formData = new FormData()
      files.forEach((file, index) => {
        formData.append(`file_${index}`, file);
      })
      formData.append('folder_document_id', this.$route.params.folder.split('_')[1])

      try {
        const response = await this.$services.createDocument.callMethod(formData)
        const result = await response.json()
        if (result.status === 'error') {
          this.showMessage(false, `[Error] Failed to upload a document`)
        } else {
          this.showMessage(true, `The document has been successfully uploaded`)
        }
      } catch (e) {
        this.showMessage(false, `[Error] Failed to upload a document`)
      } finally {
        await this.getDocuments()
      }
    },
    async getDocuments(page = 1) {
      try {
        const dataObj = {
          folder_document_id: this.$route.params.folder.split('_')[1],
          page: page
        }
        const response = await this.$services.getDocuments.callMethod(dataObj)
        this.files = await response.json()
      } catch (e) {
        console.error('[ERROR] Failed to obtain documents' + e.status)
      }
    },
    async deleteDocument(doc, index) {
      try {
        this.deleteWindowDocument = null
        const response = await this.$services.deleteDocument.callMethod({
          document_id: doc,
        })
        const result = await response.json()
        if (result.status === 'error') {
          this.showMessage(false, `[Error] Failed to delete a document`)
        } else {
          this.showMessage(true, `The document has been successfully deleted`)
        }
        this.files.splice(index, 1)
      } catch (e) {
        this.showMessage(false, `[Error] Failed to delete a document`)
      }
    }
  },
  async mounted() {
    console.log(this.customer_data)
  }

}
</script>

<style lang="stylus">
@import "@/assets/stylus/variables.styl"

.documents
  &__table
    height calc(100vh - 340px)
    overflow auto

  &__tr-back-link
    border-bottom 1px solid #e6e6e6

    td
      padding-bottom: 16px !important

  &__td
    &-action
      width: 10%

      a
        padding-right: 16px
        color #FF0000

    &-type-document
      padding-left: 28px !important

      a
        font-weight: 400
        width: 28vw;
        white-space: nowrap
        text-overflow: ellipsis
        overflow: hidden
        display: inline-block
        vertical-align middle

    &-icon
      width: 24px
      height: 24px
      font-size: 8px
      font-weight: 700
      color white
      display inline-flex
      align-items center
      justify-content center
      margin-right: 24px
      background #c9c9c9

    &-pdf
      background #ba2d2d !important

    &-jpg
      background #ea3838 !important

    &-excel
      background #1d6f42 !important

    &-word
      background #0000ff !important

    &-png
      background #939300 !important

    &-zip
      background #FDAB3D !important

  &__navigate-and-upload
    display flex
    align-items center
    justify-content space-between
    margin-bottom: 7px

  &__navigate
    display flex
    align-items center

    a
      color #999999
      font-size: 20px
      font-weight: 500

    span
      position relative
      border-right 2px solid $black
      border-bottom 2px solid $black
      width: 8px
      height: 8px
      transform rotate(-45deg)
      margin: 0 16px

</style>
