<template>
  <div>
    <div v-if="isPopup" @click="togglePopup" class="mask"></div>
    <transition name="popup">
      <div v-if="isPopup" class="popup create-task">
        <button @click="togglePopup" class="popup__close-btn"></button>
        <h2 v-if="isPopupEdit">Aufgabe bearbeiten</h2>
        <h2 v-else>Neue Aufgabe</h2>
        <div class="create-task__content">
          <div class="create-task__left-content">
            <div class="create-customer__block_50_50">
              <div v-if="$route.name !== 'customer_page'" style="position: relative">
                <base-field-input label="Kunden suchen">
                  <input :disabled="$route.name === 'customer_page'"
                         :class="{'disabled-input': $route.name === 'customer_page'}"
                         v-model="searchCustomersField"
                         @input="searchCustomersInPopup" placeholder="Kunden suchen"
                         type="text">
                </base-field-input>
                <transition name="opacity">
                  <div v-if="customersForDropdown.length" style="margin-left: 0;" class="popup__search-dropdown">
                    <a @click.prevent="editIdNumberCustomer(customer.name, customer.surname, customer.id)"
                       :key="'dropdown' + customer.id" v-for="customer in customersForDropdown"
                       href="#">{{ customer.surname }} {{ customer.name }}</a>
                  </div>
                </transition>
              </div>
              <div v-if="$route.name !== 'customer_page'">
                <base-field-input label="Kundennummer">
                  <input @change="getCountersInCustomer" disabled class="disabled-input"
                         placeholder="Kundennummer" type="text"
                         v-model="newTask.customer_id">
                  <div v-if="($v.newTask.customer_id.$dirty && !$v.newTask.customer_id.required)"
                       class="popup-customer__error">
                    Dieses Feld ist obligatorisch
                  </div>
                </base-field-input>
              </div>
            </div>
            <div class="create-task__full-name" v-if="$route.name === 'customer_page'">
              <span>Kunde</span>
              <p>{{ fullName }}</p>
            </div>
            <div>
              <base-field-input label="Zählernummer"
                                :src_img="require(`@/assets/images/task-counter_icon.svg`)">
                <v-select
                  placeholder="Zählernummer"
                  class="vSelect"
                  v-model="newTask.counter_number"
                  :options="countersInCustomerComputed"
                ></v-select>
                <div v-if="($v.newTask.counter_number.$dirty && !$v.newTask.counter_number.required)"
                     class="popup-customer__error">
                  Dieses Feld ist obligatorisch
                </div>
              </base-field-input>
            </div>
            <div>
              <base-field-input label="Aufgabe"
                                :src_img="require(`@/assets/images/popup-type_icon.svg`)">
                <input
                  style="padding-left: 64px;" type="text"
                  v-model="newTask.type_of_task"
                  placeholder="Aufgabe"
                  v-if="toggleTypeOfTask"
                >
                <v-select
                  placeholder="Aufgabe"
                  class="vSelect"
                  v-model="newTask.type_of_task"
                  :options="optionsDocuments"
                  v-else
                >
                </v-select>
                <template v-slot:checkbox>
                  <div
                    style="display: flex; align-items: center; transform: translateY(-4px); font-style: italic; color: rgba(153, 153, 153, 1)">
                    <span style="margin-right: 8px; font-size: 14px;">Eigene Aufgabe verfassen</span>
                    <div class="checkbox">
                      <input v-model="toggleTypeOfTask" id="toggleTypeOfTask" type="checkbox">
                      <label @click="newTask.type_of_task = ''" style="margin: 0;" for="toggleTypeOfTask"></label>
                    </div>
                  </div>
                </template>
                <div v-if="($v.newTask.type_of_task.$dirty && !$v.newTask.type_of_task.required)"
                     class="popup-customer__error">
                  Dieses Feld ist obligatorisch
                </div>
              </base-field-input>
            </div>
            <div class="create-customer__block_50_50">
              <div>
                <base-field-input label="Ausführender Mitarbeiter"
                                  :src_img="require('@/assets/images/popup-assign_icon.svg')">
                  <v-select
                    placeholder="Ausführender Mitarbeiter"
                    class="vSelect"
                    v-model="newTask.user_id"
                    :options="userOptions"
                    label="label"
                    index="index"
                    :reduce="option => option.index"
                  ></v-select>
                  <div v-if="($v.newTask.user_id.$dirty && !$v.newTask.user_id.required)"
                       class="popup-customer__error">
                    Dieses Feld ist obligatorisch
                  </div>
                </base-field-input>
              </div>
              <div>
                <base-field-input label="Deadline"
                                  :src_img="require('@/assets/images/popup-deadline_icon.svg')">
                  <datepicker
                    class="show-icon-datepicker"
                    v-model="newTask.deadline"
                    format="DD.MM.YYYY"
                    title-format="DD.MM.YYYY"
                    placeholder="Date (DD.MM.YYYY)"
                    :lang="$datepickerLocale"
                  ></datepicker>
                  <div v-if="($v.newTask.deadline.$dirty && !$v.newTask.deadline.required)"
                       class="popup-customer__error">
                    Dieses Feld ist obligatorisch
                  </div>
                </base-field-input>
              </div>
            </div>
            <div>
              <base-field-input label="KOMMENTAR">
                <textarea v-model="newTask.comment"></textarea>
              </base-field-input>
            </div>
            <div v-if="isPopupEdit" class="create-customer__block_50_50">
              <div></div>
              <div>
                <base-field-input style="margin: 0;" label="">
                  <v-select
                    class="vSelect"
                    v-model="newTask.status"
                    :options="statusOptions"
                    label="label"
                    index="index"
                    :reduce="option => option.index"
                    :class="{'active-select': newTask.status === 'Completed' || newTask.status === 'Completed$ok', 'closed-select': newTask.status === 'Cancelled', 'opened-select': newTask.status === 'Opened'}"
                  ></v-select>
                </base-field-input>
              </div>
            </div>
          </div>
          <div class="create-task__right-content">
            <div>
              <div class="create-task__messages-block">
                <div class="create-task__messages-block-legend">
                  <span class="legend">Telegram bot</span>
                  <!--        && newTask.type_of_task === 'ZAEHLERDATEN' ВОЗМОЖНО НУЖНО БУДЕТ ДОБАВИТЬ ПОЗЖЕ           -->
                  <div v-if="isPopupEdit"
                       style="display: flex; align-items: center;">
                    <span style="margin-right: 8px; font-size: 14px; font-style: italic; color: rgba(153, 153, 153, 1)">Daten eingeben</span>
                    <div class="checkbox">
                      <input v-model="newTask.feedback" id="newTask.feedback" type="checkbox">
                      <label style="margin: 0;" for="newTask.feedback"></label>
                    </div>
                  </div>
                </div>
                <telegram-chat
                  :task="true"
                  :is-feedback-customer="newTask.feedback"
                  ref="chat"
                />
              </div>
              <div v-if="newTask.feedback">

                <div v-if="newTask.type_of_task === 'ZAEHLERDATEN'" id="payerReading">
                  <div style="margin-top: 20px;" class="create-customer__block_50_50">
                    <div>
                      <base-field-input label="ENDZÄHLERSTAND HT">
                        <input
                          type="text"
                          placeholder="Endzahlerstand HT"
                          v-model="payerReading.end_count"
                        >
                      </base-field-input>
                    </div>
                    <div v-if="typeCounter">
                      <base-field-input label="ENDZÄHLERSTAND NT">
                        <input
                          type="text"
                          placeholder="Endzahlerstand NT"
                          v-model="payerReading.end_count_night"
                        >
                      </base-field-input>
                    </div>
                  </div>
                </div>

                <div v-if="newTask.type_of_task === 'ABSCHLAGSPLAN AENDERUNG'">
                  <div style="margin-top: 20px;" class="create-customer__block_50_50">
                    <div>
                      <base-field-input label="Zahlung pro Monat">
                        <input
                          type="text"
                          placeholder="€"
                          v-model="change_plan.amount"
                        >
                      </base-field-input>
                    </div>
                  </div>
                </div>

                <div v-if="newTask.type_of_task === 'AENDERUNG BANKVERBINDUNG'">
                  <div style="margin-top: 20px;" class="create-customer__block_65_35">
                    <div>
                      <base-field-input label="Iban">
                        <input
                          type="text"
                          placeholder="Iban"
                          v-model="change_bank_data.iban"
                          @input="getIbanData"
                        >
                        <div v-if="!iban.valid" class="popup-customer__error">{{ iban.text }}</div>
                      </base-field-input>
                    </div>
                    <div>
                      <base-field-input label="Bic">
                        <input
                          type="text"
                          placeholder="Bic"
                          v-model="change_bank_data.bic"
                        >
                      </base-field-input>
                    </div>
                  </div>
                  <div class="create-customer__block_50_50">
                    <div>
                      <base-field-input label="Bank">
                        <input
                          type="text"
                          placeholder="Bank"
                          v-model="change_bank_data.bank_name"
                        >
                      </base-field-input>
                    </div>
                    <div>
                      <base-field-input label="KONTOINHABER">
                        <input
                          type="text"
                          placeholder="Kontoinhaber"
                          v-model="change_bank_data.account_owner"
                        >
                      </base-field-input>
                    </div>
                  </div>
                </div>

                <div v-if="newTask.type_of_task === 'AENDERUNG DER ABWEICHENDEN POSTADRESSE'">
                  <div style="margin-top: 20px;" class="create-customer__block_50_50">
                    <div>
                      <base-field-input label="VORNAME">
                        <input v-model="change_different_postal_address.surname" type="text" placeholder="Vorname">
                      </base-field-input>
                    </div>
                    <div>
                      <base-field-input label="NAME">
                        <input v-model="change_different_postal_address.name" type="text" placeholder="Name">
                      </base-field-input>
                    </div>
                  </div>
                  <div class="create-customer__block_35_65">
                    <div>
                      <base-field-input label="PLZ">
                        <v-select
                          class="vSelect"
                          v-model="change_different_postal_address.accommodation_postcode"
                          :options="addresses.postcode"
                          placeholder="PLZ"
                          @search="getAddresses($event, change_different_postal_address.accommodation_postcode, change_different_postal_address.accommodation_city)"
                          @open="getAddresses($event, change_different_postal_address.accommodation_postcode, change_different_postal_address.accommodation_city)"
                        >
                          <template #no-options="{ search, searching, loading }">
                            Der Download ist in Arbeit...
                          </template>
                        </v-select>
                      </base-field-input>
                    </div>
                    <div>
                      <base-field-input label="Ort">
                        <v-select
                          class="vSelect"
                          v-model="change_different_postal_address.accommodation_city"
                          :options="addresses.city"
                          placeholder="Ort"
                          @search="getAddresses($event, change_different_postal_address.accommodation_postcode, change_different_postal_address.accommodation_city)"
                          @open="getAddresses($event, change_different_postal_address.accommodation_postcode, change_different_postal_address.accommodation_city)"
                        >
                          <template #no-options="{ search, searching, loading }">
                            Der Download ist in Arbeit...
                          </template>
                        </v-select>
                      </base-field-input>
                    </div>
                  </div>
                  <div class="create-customer__block_65_35">
                    <div>
                      <base-field-input label="Straße">
                        <input v-model="change_different_postal_address.accommodation_street" type="text"
                               placeholder="Straße">
                      </base-field-input>
                    </div>
                    <div>
                      <base-field-input label="HNr.">
                        <input v-model="change_different_postal_address.accommodation_house_number" type="text"
                               placeholder="HNr.">
                      </base-field-input>
                    </div>
                  </div>
                </div>

                <div v-if="newTask.type_of_task === 'FIRMENDATEN AENDERUNG'">
                  <div style="margin-top: 20px;" class="create-customer__block_50_50">
                    <div>
                      <base-field-input label="FIRMA">
                        <input v-model="change_company_name.company_data" type="text" placeholder="Firma">
                      </base-field-input>
                    </div>
                    <div></div>
                  </div>
                </div>

                <div v-if="newTask.type_of_task === 'KUENDIGUNG'">
                  <div style="margin-top: 20px;" class="create-customer__block_50_50">
                    <div>
                      <base-field-input label="Datum der Kündigung des Vertrags"
                                        :src_img="require('@/assets/images/popup-deadline_icon.svg')">
                        <datepicker
                          class="show-icon-datepicker"
                          v-model="termination.data_contract"
                          format="DD.MM.YYYY"
                          title-format="DD.MM.YYYY"
                          placeholder="Date (DD.MM.YYYY)"
                          :lang="$datepickerLocale"
                        ></datepicker>
                      </base-field-input>
                    </div>
                  </div>
                </div>

                <div v-if="newTask.type_of_task === 'KURZBRIEF'">
                  <div style="margin-top: 20px;">
                    <base-field-input label="KOMMENTAR">
                      <textarea v-model="brief_letter.data"></textarea>
                    </base-field-input>
                  </div>
                </div>

                <div v-if="newTask.type_of_task === 'SONDERKUENDIGUNG AUFGRUND VON AGB AENDERUNG'">
                  <div style="margin-top: 20px;" class="create-customer__block_50_50">
                    <div>
                      <base-field-input label="Datum der Kündigung des Vertrags"
                                        :src_img="require('@/assets/images/popup-deadline_icon.svg')">
                        <datepicker
                          class="show-icon-datepicker"
                          v-model="termination_special.data"
                          format="DD.MM.YYYY"
                          title-format="DD.MM.YYYY"
                          placeholder="Date (DD.MM.YYYY)"
                          :lang="$datepickerLocale"
                        ></datepicker>
                      </base-field-input>
                    </div>
                  </div>
                </div>

                <div v-if="newTask.type_of_task === 'SONDERKUENDIGUNG PREISANPASSUNG'">
                  <div style="margin-top: 20px;" class="create-customer__block_50_50">
                    <div>
                      <base-field-input label="Datum der Kündigung des Vertrags"
                                        :src_img="require('@/assets/images/popup-deadline_icon.svg')">
                        <datepicker
                          class="show-icon-datepicker"
                          v-model="termination_price.data_contract"
                          format="DD.MM.YYYY"
                          title-format="DD.MM.YYYY"
                          placeholder="Date (DD.MM.YYYY)"
                          :lang="$datepickerLocale"
                        ></datepicker>
                      </base-field-input>
                    </div>
                  </div>
                </div>

                <div v-if="newTask.type_of_task === 'UMZUG KUNDE'">
                  <div style="margin-top: 20px;" class="create-customer__block_50_50">
                    <div>
                      <base-field-input label="Zählernummer">
                        <input v-model="move_customer.counter_number" type="text" placeholder="Zählernummer">
                      </base-field-input>
                    </div>
                    <div></div>
                  </div>
                  <div class="create-customer__block_35_65">
                    <div>
                      <base-field-input label="PLZ">
                        <v-select
                          class="vSelect"
                          v-model="move_customer.accommodation_postcode"
                          :options="addresses.postcode"
                          placeholder="PLZ"
                          @search="getAddresses($event, move_customer.accommodation_postcode, move_customer.accommodation_city)"
                          @open="getAddresses($event, move_customer.accommodation_postcode, move_customer.accommodation_city)"
                        >
                          <template #no-options="{ search, searching, loading }">
                            Der Download ist in Arbeit...
                          </template>
                        </v-select>
                      </base-field-input>
                    </div>
                    <div>
                      <base-field-input label="Ort">
                        <v-select
                          class="vSelect"
                          v-model="move_customer.accommodation_city"
                          :options="addresses.city"
                          placeholder="Ort"
                          @search="getAddresses($event, move_customer.accommodation_postcode, move_customer.accommodation_city)"
                          @open="getAddresses($event, move_customer.accommodation_postcode, move_customer.accommodation_city)"
                        >
                          <template #no-options="{ search, searching, loading }">
                            Der Download ist in Arbeit...
                          </template>
                        </v-select>
                      </base-field-input>
                    </div>
                  </div>
                  <div class="create-customer__block_65_35">
                    <div>
                      <base-field-input label="Straße">
                        <input v-model="move_customer.accommodation_street" type="text"
                               placeholder="Straße">
                      </base-field-input>
                    </div>
                    <div>
                      <base-field-input label="HNr.">
                        <input v-model="move_customer.accommodation_house_number" type="text"
                               placeholder="HNr.">
                      </base-field-input>
                    </div>
                  </div>
                </div>

                <div v-if="newTask.type_of_task === 'UNTERLAGEN ANFORDERUNG'">
                  <div class="create-customer__block_50_50" style="align-items: center; margin-top: 20px;">
                    <div class="create-documents__field">
                      <base-field-input label="OPERATOR">
                        <v-select
                          v-if="!offerToggleOperator"
                          placeholder="Operator"
                          class="vSelect"
                          v-model="documents_request.operator_name"
                          :options="operators"
                        ></v-select>
                        <input
                          v-else
                          type="text"
                          placeholder="Operator"
                          v-model="documents_request.operator_name"
                        >
                      </base-field-input>
                    </div>
                    <div class="create-documents__field">
                      <div
                        style="display: flex; align-items: center; font-style: italic; color: rgba(153, 153, 153, 1)">
                        <span style="margin-right: 8px; font-size: 14px;">Neuer Operator</span>
                        <div class="checkbox" style="width: auto">
                          <input v-model="offerToggleOperator" id="toggleOperators" type="checkbox">
                          <label @click="documents_request.operator_name = ''" style="margin: 0;"
                                 for="toggleOperators"></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="newTask.type_of_task === 'WIDERRUF DER KUENDIGUNG'">
                  <div style="margin-top: 20px;" class="create-customer__block_50_50">
                    <div>
                      <base-field-input label="Datum der Kündigung des Vertrags"
                                        :src_img="require('@/assets/images/popup-deadline_icon.svg')">
                        <datepicker
                          class="show-icon-datepicker"
                          v-model="revocation_of_termination.revocation_termination_from"
                          format="DD.MM.YYYY"
                          title-format="DD.MM.YYYY"
                          placeholder="Date (DD.MM.YYYY)"
                          :lang="$datepickerLocale"
                        ></datepicker>
                      </base-field-input>
                    </div>
                  </div>
                </div>

                <div v-if="newTask.type_of_task === 'WIDERRUF DES ENERGIEVERTRAGES'">
                  <div style="margin-top: 20px;" class="create-customer__block_50_50">
                    <div>
                      <base-field-input label="Datum der Kündigung des Vertrags"
                                        :src_img="require('@/assets/images/popup-deadline_icon.svg')">
                        <datepicker
                          class="show-icon-datepicker"
                          v-model="revocation_energy_contract.revocation_energy_contract_from"
                          format="DD.MM.YYYY"
                          title-format="DD.MM.YYYY"
                          placeholder="Date (DD.MM.YYYY)"
                          :lang="$datepickerLocale"
                        ></datepicker>
                      </base-field-input>
                    </div>
                  </div>
                </div>

                <div v-if="newTask.type_of_task === 'VORLAGE KUENDIGUNGWEGEN AUSZUG'">
                  <div style="margin-top: 20px;" class="create-customer__block_50_50">
                    <div>
                      <base-field-input label="Datum der Kündigung des Vertrags"
                                        :src_img="require('@/assets/images/popup-deadline_icon.svg')">
                        <datepicker
                          class="show-icon-datepicker"
                          v-model="template_termination_for_moving_out.due_to_the_move"
                          format="DD.MM.YYYY"
                          title-format="DD.MM.YYYY"
                          placeholder="Date (DD.MM.YYYY)"
                          :lang="$datepickerLocale"
                        ></datepicker>
                      </base-field-input>
                    </div>
                  </div>
                  <div class="create-customer__block_50_50">
                    <div>
                      <base-field-input label="Zählerstand">
                        <input type="text" placeholder="Zählerstand" v-model="template_termination_for_moving_out.meter_reading">
                      </base-field-input>
                    </div>
                    <div></div>
                  </div>
                </div>

              </div>
            </div>
            <div style="margin-top: 48px;">
              <div v-if="newTask.type_of_task && newTask.type_of_task !== '' && newTask.task_id"
                   class="create-task__document">
                <a v-if="newTask.path_to_doc && newTask.path_to_doc !== ''" target="_blank"
                   :href="url + newTask.path_to_doc">
                  Dokument öffnen
                </a>
                <button
                  :class="{'create-task__document-btn-disabled' : newTask.path_to_doc && newTask.path_to_doc !== ''}"
                  :disabled="newTask.path_to_doc && newTask.path_to_doc !== ''" @click.prevent="generateDocument">
                  Dokument generieren
                </button>
              </div>
              <div style="margin-top: 12px;" class="popup__group-btn">
                <a @click.prevent="togglePopup" href="#">Abbrechen</a>
                <button @click="sendOperator"
                        :disabled="newTask.send_operator || (!newTask.path_to_doc || newTask.path_to_doc === '')"
                        :class="['border-button', {'create-task__document-btn-disabled': newTask.send_operator || (!newTask.path_to_doc || newTask.path_to_doc === '')}]">
                  Versenden
                </button>
                <button v-if="isPopupEdit" @click.prevent="editTask" class="blue-button">
                  Speichern
                </button>
                <button v-else @click.prevent="createTask" class="blue-button">
                  Speichern
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import fieldErrorRequired from "@/components/fieldErrorRequired.vue";
import BaseFieldInput from "@/components/fields/BaseFieldInput.vue";
import taskValidations from '@/mixins/tasks/validations'
import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select'
import getUser from "@/mixins/users/getUser";
import moment from 'moment'
import TelegramChat from "@/components/elements/TelegramChat.vue";

import Vue from 'vue'

/**
 * @typedef {Object} newTask
 * @property {string} user_id - ID исполнителя.
 * @property {string} customer_id - ID клиента.
 * @property {string} type_of_task - Тип задачи.
 * @property {string} deadline - Дедлайн для задачи.
 * @property {string} counter_number - Номер счетчика.
 * @property {string} status - Статус задачи.
 * @property {string} comment - Комментарий к задаче.
 */

/**
 * @typedef {Object} customer
 * @property {string} name - Имя клиента.
 * @property {string} surname - Фамилия клиента.
 * @property {string} id - ID клиента.
 */

/**
 * @typedef {Object} user
 * @property {string} id - ID пользователя.
 * @property {string} first_name - Имя пользователя.
 * @property {string} last_name - Фамилия пользователя.
 */

export default {
  name: "createTask",
  components: {
    TelegramChat,
    vSelect,
    BaseFieldInput,
    fieldErrorRequired,
  },
  mixins: [
    taskValidations,
    getUser
  ],
  props: {
    fullName: null
  },
  data() {
    return {
      url: window.location.origin,
      offerToggleOperator: false,
      operators: [],


      payerReading: {
        end_count: null,
        end_count_night: null
      },
      change_plan: {
        amount: null
      },
      change_bank_data: {
        bic: null,
        iban: null,
        bank_name: null,
        account_owner: null
      },
      change_different_postal_address: {
        surname: null,
        name: null,
        accommodation_city: null,
        accommodation_street: null,
        accommodation_house_number: null,
        accommodation_postcode: null,
      },
      change_company_name: {
        company_data: null
      },
      termination: {
        data_contract: null
      },
      termination_special: {
        data: null
      },
      termination_price: {
        data_contract: null
      },
      brief_letter: {
        data: null
      },
      move_customer: {
        accommodation_city: null,
        accommodation_street: null,
        accommodation_house_number: null,
        accommodation_postcode: null,
        counter_number: null
      },
      documents_request: {
        operator_name: null
      },
      revocation_of_termination: {
        revocation_termination_from: null
      },
      revocation_energy_contract: {
        revocation_energy_contract_from: null
      },
      template_termination_for_moving_out: {
        meter_reading: null,
        due_to_the_move: null
      },


      addresses: {
        postcode: [],
        city: [],
        street: []
      },
      iban: {
        valid: true,
        text: null
      },
      countersInCustomer: [],
      type_of_tasks: [],
      customersForDropdown: [],
      statusOptions: [
        {
          label: 'Geöffnet',
          index: 'Opened'
        },
        {
          label: 'Abgeschlossen',
          index: 'Completed'
        },
        {
          label: 'Vervollständigen + Erstellen',
          index: 'Completed$ok'
        }
      ],
      users: [],
      searchCustomersField: '',
      newTask: {
        user_id: '',
        customer_id: '',
        type_of_task: '',
        deadline: '',
        counter_number: '',
        status: 'Opened',
        comment: '',
        feedback: false,
        tasks_info: null,
        archive: false,
        send_operator: false,
        path_to_doc: null
      },
      isPopup: false,
      isPopupEdit: false,
      toggleTypeOfTask: false,
      optionsDocuments: [
        'ABSCHLAGSPLAN AENDERUNG',
        'AENDERUNG BANKVERBINDUNG',
        'AENDERUNG DER ABWEICHENDEN POSTADRESSE',
        'FIRMENDATEN AENDERUNG',
        'KUENDIGUNG',
        'KURZBRIEF',
        'SONDERKUENDIGUNG AUFGRUND VON AGB AENDERUNG',
        'SONDERKUENDIGUNG PREISANPASSUNG',
        'UMZUG KUNDE',
        'UNTERLAGEN ANFORDERUNG',
        'VORLAGE KUENDIGUNGWEGEN AUSZUG',
        'WIDERRUF DER KUENDIGUNG',
        'WIDERRUF DES ENERGIEVERTRAGES',
        'ZAEHLERDATEN'
      ],
    }
  },
  computed: {
    typeCounter() {
      return this.newTask.counter_number.includes('Tag/Nacht');
    },
    countersInCustomerComputed: {
      get() {
        let array = []
        if (this.countersInCustomer.length === 1) {
          this.newTask.counter_number = `${this.countersInCustomer[0].counters_type} ${this.countersInCustomer[0].counters_number}`
          return [`${this.countersInCustomer[0].counters_type} ${this.countersInCustomer[0].counters_number}`]
        }
        for (const counter in this.countersInCustomer) {
          array.push(`${this.countersInCustomer[counter].counters_type} ${this.countersInCustomer[counter].counters_number}`)
        }
        return array
      },
      set(value) {
        this.countersInCustomer = value
      }
    },
    userOptions() {
      const array = []
      if (this.users.length === 1) {
        this.newTask.user_id = `${this.users[0].first_name} ${this.users[0].last_name}`
        return [{
          index: this.users[0].id,
          label: `${this.users[0].first_name} ${this.users[0].last_name}`
        }]
      }
      for (const user in this.users) {
        const object = {}
        object.index = this.users[user].id
        object.label = `${this.users[user].first_name} ${this.users[user].last_name}`
        array.push(object)
      }
      return array
    },
    newTaskWatcher() {
      const {type_of_task, counter_number, deadline} = this.newTask;
      return `${type_of_task}-${counter_number}-${deadline}`;
    }
  },
  methods: {
    /**
     * Переключает видимость всплывающего окна.
     */
    async togglePopup() {
      this.$v.newTask.$reset()
      this.isPopup = !this.isPopup
      if (this.isPopup) {
        if (this.newTask.customer_id) {
          await this.getCountersInCustomer(this.newTask.customer_id)
          setTimeout(() => {
            this.$refs.chat.getMessages(this.newTask.customer_id)
          }, 300)
        }
      } else {
        this.isPopupEdit = false
        this.resetForm()
      }
    },
    /**
     * Поиск клиентов во всплывающем окне.
     */
    async searchCustomersInPopup() {
      if (this.searchCustomersField.length) {
        let response = await this.$services.searchCustomersInPopup.callMethod({
          search: this.searchCustomersField,
          page: 1
        })
        this.customersForDropdown = await response.json()
      } else {
        this.customersForDropdown = []
        this.newTask.customer_id = null
        this.$refs.chat.messages = null
      }
    },
    /**
     * Изменяет ID клиента.
     * @param {string} name - Имя клиента.
     * @param {string} surname - Фамилия клиента.
     * @param {string} id - ID клиента.
     */
    editIdNumberCustomer(name, surname, id) {
      this.searchCustomersField = `${surname} ${name}`
      this.newTask.customer_id = id
      this.customersForDropdown = []
      this.newTask.counter_number = ''
      this.getCountersInCustomer(id)
      console.log(this.$refs)
      this.$refs.chat.getMessages(id)
    },
    /**
     * Получает данные счетчиков для клиента.
     * @param {string} id - ID клиента.
     */
    async getCountersInCustomer(id) {
      let response = await this.$services.getCountersInCustomer.callMethod({customer_id: id, page: 1})
      this.countersInCustomer = await response.json()
    },
    async createTask() {
      await this.initEventTask('createTask')
    },
    async editTask() {
      await this.initEventTask('editTask')
    },
    /**
     * Инициализирует событие для задачи.
     * @param {string} event - Тип события ('createTask' или 'editTask').
     */

    async initEventTask(event) {
      let isRepeat = false
      if (this.$v.newTask.$invalid) {
        this.$v.$touch();
        return;
      }

      try {
        this.newTask.deadline = moment(this.newTask.deadline).format('DD.MM.YYYY');

        if (this.newTask.status.includes('$ok') && !this.newTask.archive) {
          this.newTask.archive = true
          const newTask = {...this.newTask}
          newTask.deadline = moment(newTask.deadline, 'DD.MM.YYYY')
          newTask.deadline.add(1, 'years')
          newTask.deadline = moment(newTask.deadline).format('DD.MM.YYYY')
          console.log(newTask.deadline)
          newTask.status = 'Opened'
          newTask.feedback = false
          newTask.archive = false
          newTask.send_operator = false
          newTask.tasks_info = null
          newTask.path_to_doc = null
          delete newTask.task_id

          const response = await this.$services.createTask.callMethod(newTask)
          console.log(await response.json())
        }

        const responseTask = await this.$services[event].callMethod(this.newTask);

        const resultTask = await responseTask.json()

        console.log(resultTask)


        if (this.newTask.type_of_task === 'ZAEHLERDATEN') {
          const taskBotPayload = {
            type_client: this.newTask.customer_id,
            deadline: this.newTask.deadline,
            days_before_deadline: 3,
            message_text: this.newTask.comment,
            task_id: event === 'createTask' ? resultTask.task_id : this.newTask.tasks_info,
          };
          await this.handleTaskBot(taskBotPayload, event);
        }

        await this.handleMeterReading();

        this.resetForm();

        this.handleResultStatus(resultTask);
      } catch (error) {
        console.error(error);
        this.$emit('taskError');
        this.resetForm();
      } finally {
        this.togglePopup();
      }
    },

    async getAddresses(event, plz = '', city = '') {
      this.addresses.postcode = [];
      this.addresses.city = [];
      this.addresses.street = [];
      setTimeout(async () => {
        const response = await fetch(`https://public.opendatasoft.com/api/explore/v2.1/catalog/datasets/georef-germany-postleitzahl/records?limit=100${plz !== '' && plz ? '&refine=plz_code:' + '"' + plz + '"' : ''}${city !== '' && city ? '&refine=plz_name:' + '"' + city + '"' : ''}${event ? '&where=' + '\"' + event + '\"' : ''}`);
        const result = await response.json();
        for (const address in result.results) {
          const plzCode = result.results[address].plz_code;
          const plzName = result.results[address].plz_name;

          if (!this.addresses.postcode.includes(plzCode)) {
            this.addresses.postcode.push(plzCode);
          }

          if (!this.addresses.city.includes(plzName)) {
            this.addresses.city.push(plzName);
          }

        }
      }, 1);
    },

    handleTaskBot(payload, event) {
      const methodName = event === 'createTask' ? 'createTaskBot' : 'editTaskBot';
      return this.$services[methodName].callMethod(payload);
    },

    handleMeterReading() {
      const arrayCounterNumber = this.newTask.counter_number.split(' ');
      const meterReading = {
        id: this.newTask.customer_id,
        counters_number: arrayCounterNumber.pop(),
        end_count: this.payerReading.end_count,
        end_count_night: this.typeCounter ? this.payerReading.end_count_night : undefined,
      };

      if (this.shouldSubmitMeterReading(meterReading)) {
        return this.$services.changeMeterReading.callMethod(meterReading);
      }
    },

    shouldSubmitMeterReading({end_count, end_count_night}) {
      return end_count && end_count !== '' && (!this.typeCounter || (end_count_night && end_count_night !== ''));
    },

    async getIbanData(event) {
      const value = event.target.value
      const response = await this.$services.getBankInfoAndBic.callMethod({iban: value})
      const result = await response.json()
      if ('error' in result) {
        this.iban.text = result.error
        this.iban.valid = false
      } else {
        this.iban.valid = true
        this.change_bank_data.bic = result.bic
        this.change_bank_data.bank_name = result.bank_name

      }
    },

    async generateDocument() {

      let resultGenerate;
      if (this.newTask.type_of_task === 'ZAEHLERDATEN') {
        resultGenerate = await this.generateCounterData();
      } else if (this.newTask.type_of_task === 'ABSCHLAGSPLAN AENDERUNG') {
        resultGenerate = await this.generateChangePlan();
      } else if (this.newTask.type_of_task === 'AENDERUNG BANKVERBINDUNG') {
        resultGenerate = await this.generateChangeBankData();
      } else if (this.newTask.type_of_task === 'AENDERUNG DER ABWEICHENDEN POSTADRESSE') {
        resultGenerate = await this.generateDifferentPostalAddress();
      } else if (this.newTask.type_of_task === 'FIRMENDATEN AENDERUNG') {
        resultGenerate = await this.generateChangeCompanyName();
      } else if (this.newTask.type_of_task === 'KUENDIGUNG') {
        resultGenerate = await this.generateTerminationData();
      } else if (this.newTask.type_of_task === 'KURZBRIEF') {
        resultGenerate = await this.generateBriefLetter();
      } else if (this.newTask.type_of_task === 'SONDERKUENDIGUNG AUFGRUND VON AGB AENDERUNG') {
        resultGenerate = await this.generateTerminationSpecial();
      } else if (this.newTask.type_of_task === 'SONDERKUENDIGUNG PREISANPASSUNG') {
        resultGenerate = await this.generateTerminationPrice();
      } else if (this.newTask.type_of_task === 'UMZUG KUNDE') {
        resultGenerate = await this.generateMoveCustomer();
      } else if (this.newTask.type_of_task === 'UNTERLAGEN ANFORDERUNG') {
        resultGenerate = await this.generateDocumentsRequest();
      } else if (this.newTask.type_of_task === 'WIDERRUF DER KUENDIGUNG') {
        resultGenerate = await this.generateRevocationOfTermination();
      } else if (this.newTask.type_of_task === 'WIDERRUF DES ENERGIEVERTRAGES') {
        resultGenerate = await this.generateRevocationEnergyContract();
      } else if (this.newTask.type_of_task === 'VORLAGE KUENDIGUNGWEGEN AUSZUG') {
        resultGenerate = await this.generateTemplateTerminationForMovingOut();
      }

      const path = `/doc/${resultGenerate.file_path}`
      Vue.set(this.newTask, 'path_to_doc', path)
      const responseSaveDocument = await this.$services.saveDocPathInTask.callMethod({
        task_id: this.newTask.task_id,
        path_to_doc: path
      })
      console.log(await responseSaveDocument.json())
    },

    async generateTemplateTerminationForMovingOut() {
      const responseGenerate = await this.$services.documentGeneration.callMethod({
        counter_number: this.newTask.counter_number,
        document: "template_termination_for_moving_out",
        customer_id: this.newTask.customer_id,
        due_to_the_move: moment(this.template_termination_for_moving_out.due_to_the_move).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        meter_reading: this.template_termination_for_moving_out.meter_reading
      })
      return await responseGenerate.json();
    },

    async generateRevocationEnergyContract() {
      const responseGenerate = await this.$services.documentGeneration.callMethod({
        counter_number: this.newTask.counter_number,
        document: "revocation_energy_contract",
        customer_id: this.newTask.customer_id,
        revocation_energy_contract_from: moment(this.revocation_energy_contract.revocation_energy_contract_from).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
      })
      return await responseGenerate.json();
    },

    async generateRevocationOfTermination() {
      const responseGenerate = await this.$services.documentGeneration.callMethod({
        counter_number: this.newTask.counter_number,
        document: "revocation_of_termination",
        customer_id: this.newTask.customer_id,
        revocation_termination_from: moment(this.revocation_of_termination.revocation_termination_from).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
      })
      return await responseGenerate.json();
    },

    async generateDocumentsRequest() {
      const responseGenerate = await this.$services.documentGeneration.callMethod({
        counter_number: this.newTask.counter_number,
        document: "documents_request",
        customer_id: this.newTask.customer_id,
        operator_name: this.documents_request.operator_name
      })
      return await responseGenerate.json();
    },

    async generateMoveCustomer() {
      const responseGenerate = await this.$services.documentGeneration.callMethod({
        counter_number: this.newTask.counter_number,
        document: "move_customer",
        customer_id: this.newTask.customer_id,
        accommodation_city: this.move_customer.accommodation_city,
        accommodation_street: this.move_customer.accommodation_street,
        accommodation_house_number: this.move_customer.accommodation_house_number,
        accommodation_postcode: this.move_customer.accommodation_postcode,
        new_counter_number: this.move_customer.counter_number,
      })
      return await responseGenerate.json();
    },

    async generateTerminationPrice() {
      const responseGenerate = await this.$services.documentGeneration.callMethod({
        counter_number: this.newTask.counter_number,
        document: "special_termination_price_adjustment",
        customer_id: this.newTask.customer_id,
        data_contract: moment(this.termination_price.data_contract).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
      })
      return await responseGenerate.json();
    },

    async generateTerminationSpecial() {
      const responseGenerate = await this.$services.documentGeneration.callMethod({
        counter_number: this.newTask.counter_number,
        document: "special_termination_due_to_changes_in_the_agb",
        customer_id: this.newTask.customer_id,
        data: moment(this.termination_special.data).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
      })
      return await responseGenerate.json();
    },

    async generateBriefLetter() {
      const responseGenerate = await this.$services.documentGeneration.callMethod({
        counter_number: this.newTask.counter_number,
        document: "brief_letter",
        customer_id: this.newTask.customer_id,
        data: this.brief_letter.data
      })
      return await responseGenerate.json();
    },

    async generateTerminationData() {
      const responseGenerate = await this.$services.documentGeneration.callMethod({
        counter_number: this.newTask.counter_number,
        document: "termination",
        customer_id: this.newTask.customer_id,
        data_contract: moment(this.termination.data_contract).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
      })
      return await responseGenerate.json();
    },

    async generateChangeCompanyName() {
      const responseGenerate = await this.$services.documentGeneration.callMethod({
        counter_number: this.newTask.counter_number,
        document: "company_data_change",
        customer_id: this.newTask.customer_id,
        company_data: this.change_company_name.company_data
      })
      return await responseGenerate.json();
    },

    async generateDifferentPostalAddress() {
      const responseGenerate = await this.$services.documentGeneration.callMethod({
        counter_number: this.newTask.counter_number,
        document: "change_the_different_postal_address",
        customer_id: this.newTask.customer_id,
        surname: this.change_different_postal_address.surname,
        name: this.change_different_postal_address.name,
        accommodation_street: this.change_different_postal_address.accommodation_street,
        accommodation_city: this.change_different_postal_address.accommodation_city,
        accommodation_house_number: this.change_different_postal_address.accommodation_house_number,
        accommodation_postcode: this.change_different_postal_address.accommodation_postcode,
      })
      return await responseGenerate.json();
    },

    async generateChangeBankData() {
      const responseGenerate = await this.$services.documentGeneration.callMethod({
        counter_number: this.newTask.counter_number,
        document: "change_bank_details",
        customer_id: this.newTask.customer_id,
        bic: this.change_bank_data.bic,
        iban: this.change_bank_data.iban,
        bank_name: this.change_bank_data.bank_name,
        account_owner: this.change_bank_data.account_owner,
      })
      return await responseGenerate.json();
    },

    async generateChangePlan() {
      const responseGenerate = await this.$services.documentGeneration.callMethod({
        counter_number: this.newTask.counter_number,
        document: "discount_plan_change",
        customer_id: this.newTask.customer_id,
        amount: this.change_plan.amount
      })
      return await responseGenerate.json();
    },

    async generateCounterData() {
      const responseMeterReading = await this.handleMeterReading()
      const resultMeterReading = await responseMeterReading.json()
      if (resultMeterReading.status === 'yes') {
        const responseGenerate = await this.$services.documentGeneration.callMethod({
          counter_number: this.newTask.counter_number,
          document: "counter_data",
          customer_id: this.newTask.customer_id
        })
        return await responseGenerate.json();
      }
    },

    async searchOperatorsInDropdown(event) {
      this.operators = []
      if (event.length) {
        let response = await this.$services.searchOperatorsInDropdown.callMethod({
          page: 1,
          search: event
        })
        let result = await response.json()
        for (const operator in result) {
          this.operators.push(result[operator].operator_name)
        }
      } else {
        await this.getOperatorsInDropdown()
      }
    },
    async getOperatorsInDropdown() {
      this.operators = []
      let response = await this.$services.getOperatorsInDropdown.callMethod()
      const result = await response.json()
      for (const oper in result) {
        this.operators.push(result[oper].operator_name)
      }
      return this.operators
    },

    resetForm() {
      this.newTask.user_id = ''
      this.newTask.type_of_task = ''
      this.newTask.deadline = ''
      this.newTask.counter_number = ''
      this.newTask.status = 'Opened'
      this.newTask.comment = ''
      this.newTask.feedback = false
      this.newTask.tasks_info = null
      this.newTask.archive = false
      this.newTask.send_operator = false

      if (this.$route.name !== 'customer_page') {
        this.newTask.customer_id = ''
      }

      delete this.newTask.path_to_doc
      this.payerReading.end_count = '';
      this.payerReading.end_count_night = '';
      this.countersInCustomerComputed = []
      this.customersForDropdown = []
      this.searchCustomersField = ''
    },

    async sendOperator() {
      this.newTask.send_operator = true
      const response = await this.$services.sendDocumentOperator.callMethod({
        task_id: this.newTask.task_id,
        send_operator: this.newTask.send_operator,
        attachments: this.newTask.path_to_doc.slice(1),
        counter_number: this.newTask.counter_number
      })
      this.$emit('clickFilter')

      console.log(await response.json())
    },

    handleResultStatus(resultTask) {
      if (resultTask.status === 'error') {
        this.$emit('taskError');
      } else {
        this.$emit('taskSuccess');
        this.$emit('clickFilter');
      }
    },
    /**
     * Редактирует открытую задачу.
     * @param {Object} task - Объект задачи.
     * @param {Array<customer>} task.customer - Клиент, связанный с задачей.
     * @param {Array<user>} task.user - Пользователь, связанный с задачей.
     * @param {newTask} task.task - Детали задачи.
     */
    async editOpenTask(task) {
      this.isPopupEdit = true
      this.newTask.task_id = task.task.id
      this.newTask.user_id = task.user[0].id
      this.newTask.customer_id = task.customer[0].id
      this.newTask.type_of_task = task.task.type_of_task
      this.newTask.deadline = new Date(moment(task.task.deadline, 'DD.MM.YYYY').format('MM.DD.YYYY'))
      this.newTask.counter_number = task.task.counter_number
      this.newTask.status = task.task.status
      this.newTask.comment = task.task.comment
      this.newTask.feedback = task.task.feedback
      this.newTask.send_operator = task.task.send_operator
      this.searchCustomersField = task.customer[0].surname + ' ' + task.customer[0].name
      this.newTask.tasks_info = task.task.tasks_info
      this.newTask.archive = task.task.archive
      if (task.contract.length) {
        this.payerReading.end_count = task.contract[0].end_count
        this.payerReading.end_count_night = task.contract[0].end_count_night
      }
      if (task.task.path_to_doc && task.task.path_to_doc !== '') {
        this.newTask.path_to_doc = task.task.path_to_doc
      }
      if (Number(task.task.count_message) >= 1) {
        await this.$services.checkNotification.callMethod({
          tasks_id: task.task.id
        })
        const storeMessages = this.$store.state.telegramMessages
        for (const message in storeMessages) {
          if (storeMessages[message].id === task.task.id) {
            storeMessages.splice(message, 1)
          }
        }
        this.$store.commit('setTelegramMessages', storeMessages)
        this.$emit('clickFilter')
      }
      this.togglePopup()
      setTimeout(() => {
        this.$refs.chat.getMessages(task.customer[0].id)
      }, 300)
    }
  },
  async mounted() {
    let response = await this.$services.getUsers.callMethod()
    this.users = await response.json()
  },
  created() {
    if (this.$route.name === 'customer_page') {
      this.newTask.customer_id = this.$route.params.id.split('_')[1]
    }
  },
  watch: {
    // Наблюдаем за изменениями вычисляемого свойства
    newTaskWatcher(newValue, oldValue) {
      // Проверяем, изменился ли тип задачи на интересующий нас
      if (this.newTask.type_of_task === 'ZAEHLERDATEN') {
        const arrayCounterNumber = this.newTask.counter_number.split(' ');
        this.newTask.comment = `
        Sehr geehrter Herr/Frau/Firma, es ist wieder so weit am ${this.newTask.deadline ? moment(this.newTask.deadline).format("DD.MM.YYYY") : '(((( Setzen Sie eine Frist ))))'} für die Ablesung von Stromzähler/Gaszähler ${arrayCounterNumber[arrayCounterNumber.length - 1]}. Bitte fotografieren Sie den Zähler ab und senden uns in diesem Chat zu. Wir bedanken uns für Ihre Mithilfe, damit können Sie sicher sein, dass Ihre Jahresabrechnungen immer korrekt abgerechnet werden.`
          .trim();
      } else {
        // this.newTask.comment = ``
      }
    },
    async 'newTask.type_of_task'(to) {
      if (to === 'UNTERLAGEN ANFORDERUNG') {
        await this.getOperatorsInDropdown();
      }
    }
  }
}
</script>


<style lang="stylus">
.create-task
  max-width 1124px !important

  &__document
    display flex
    justify-content flex-end
    align-items center

    &-btn-disabled
      opacity 0.5 !important
      cursor not-allowed !important

    button
      border-radius 4px
      padding: 8px 12px
      border 1px solid #227e39
      color #227e39
      background none
      margin-left: 12px

  &__left-content, &__right-content
    width: 50%

  &__right-content
    margin-left: 38px
    padding-left: 38px
    border-left: 1px solid rgba(182, 190, 216, 1)
    display flex
    flex-direction column
    justify-content space-between

    & .popup__group-btn
      button
        padding: 14px 20px

      button + button
        margin-left: 16px
        background rgba(0, 200, 117, 1)
        border-color rgba(0, 200, 117, 1)
        padding: 14px 40px

  &__messages-block
    &-legend
      display flex
      align-items center
      justify-content space-between

      .legend
        text-transform uppercase
        font-size: 14px
        font-weight: 600
        color #b6bed8

  &__chat
    width: 100%
    height: 198px
    border: 1px solid rgba(182, 190, 216, 1)
    background rgba(239, 239, 239, 1)
    margin-top: 8px
    border-radius 4px

  &__content
    display flex

  &__full-name
    margin-bottom: 16px
    display inline-flex
    flex-direction column

    p
      border 1px solid #b6bed8
      border-radius 4px
      padding: 8px 12px
      font-size: 20px
      font-weight: 500

    span
      font-weight: 600
      margin-bottom: 4px
      color #b6bed8
      text-transform uppercase
      font-size: 14px

</style>