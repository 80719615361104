<template>
  <section class="popup-bot">
    <div v-if="isShowPopup" @click="togglePopup" class="mask"></div>
    <transition name="popup">
      <div v-if="isShowPopup" class="popup-bot__inner popup">
        <button @click="togglePopup" class="popup__close-btn"></button>
        <h2 v-if="isEditPopup">Ändern Sie das Ereignis</h2>
        <h2 v-else>Neue Veranstaltung</h2>
        <div>
          <div class="create-customer__block_50_50">
            <div>
              <base-field-input label="KUNDENTYP">
                <v-select
                  placeholder="Kundentyp"
                  class="vSelect"
                  :options="typeClientOptions"
                  label="label"
                  :reduce="option => option.value"
                  v-model="newEvent.type_client"
                ></v-select>
              </base-field-input>
            </div>
            <div v-if="newEvent.type_client === 'one_customer'" style="position: relative">
              <base-field-input label="Kunden suchen">
                <input
                       v-model="searchCustomersField"
                       @input="searchCustomersInPopup" placeholder="Kunden suchen"
                       type="text">
              </base-field-input>
              <transition name="opacity">
                <div style="margin-left: 0;" v-if="customersForDropdown.length" class="popup__search-dropdown">
                  <a @click.prevent="editIdNumberCustomer(customer.name, customer.surname, customer.id)"
                     :key="'dropdown' + customer.id" v-for="customer in customersForDropdown"
                     href="#">{{ customer.surname }} {{ customer.name }}</a>
                </div>
              </transition>
            </div>
          </div>
          <div class="create-customer__block_50_50">
            <div>
              <base-field-input label="Auswahl eines Termins">
                <v-select
                  placeholder="Auswahl eines Termins"
                  class="vSelect"
                  :options="deadlineOptions"
                  label="label"
                  :reduce="option => option.value"
                  v-model="newEvent.type_deadline"
                ></v-select>
              </base-field-input>
            </div>
            <div v-if="newEvent.type_deadline === 'custom_data'">
              <base-field-input label="Deadline"
                                :src_img="require('@/assets/images/popup-deadline_icon.svg')">
                <input
                  style="padding-left: 62px;"
                  type="text"
                  v-model="newEvent.deadline"
                  @input="validation('newEvent', 'deadline')"
                  placeholder="Date (DD.MM.YYYY)"
                  @keydown="filterInput"
                >
              </base-field-input>
            </div>
          </div>
          <div>
            <base-field-input label="Wie viele Tage für die Benachrichtigung des Kunden">
              <input
                type="text"
                placeholder="Geben Sie die Anzahl der Tage ein..."
                @keydown="filterInput"
                v-model="newEvent.qtyDay"
              >
            </base-field-input>
          </div>
          <div>
            <base-field-input label="Text des Newsletters">
              <textarea v-model="newEvent.message_text" placeholder="Text eingeben..."></textarea>
            </base-field-input>
          </div>
        </div>
        <div class="popup__group-btn">
          <a @click.prevent="togglePopup" href="#">Abbrechen</a>
          <button v-if="isEditPopup" @click.prevent="$emit('editTaskBot')" class="blue-button">
            Ereignis modifizieren
          </button>
          <button v-else @click.prevent="$emit('createTaskBot')" class="blue-button">
            <span></span>
            Ein Ereignis erstellen
          </button>
        </div>
      </div>
    </transition>
  </section>
</template>

<script>
import fieldErrorRequired from "@/components/fieldErrorRequired.vue";
import BaseFieldInput from "@/components/fields/BaseFieldInput.vue";
import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select'
import eventDate from "@/mixins/components/_eventDate";
import eventInteger from "@/mixins/components/_eventInteger";

export default {
  name: "createEventBot",
  components: {BaseFieldInput, fieldErrorRequired, vSelect},
  mixins: [eventDate, eventInteger],
  props: {
    newEvent: {
      type_client: null,
      type_deadline: null,
      deadline: null,
      qtyDay: null,
      message_text: '',
    },
    customer_id: '',
  },
  data() {
    return {
      isEditPopup: false,
      isShowPopup: false,
      typeDeadline: null,
      customersForDropdown: [],
      searchCustomersField: '',
      deadlineOptions: [
        {
          label: 'Nach Frist in Aufgaben',
          value: 'deadline_task'
        },
        {
          label: 'Bei Abschluss von Verträgen',
          value: 'end_contract'
        },
        {
          label: 'Benutzerdefiniertes Datum',
          value: 'custom_data'
        },
      ],
      typeClientOptions: [
        {
          label: 'Privat',
          value: 'Privat'
        },
        {
          label: 'Firma',
          value: 'Firma'
        },
        {
          label: 'Alle von ihnen',
          value: 'All'
        },
        {
          label: 'Ein Kunde',
          value: 'one_customer'
        }
      ]
    }
  },
  methods: {
    async searchCustomersInPopup() {
      if (this.searchCustomersField.length) {
        let response = await this.$services.searchCustomersInPopup.callMethod({
          search: this.searchCustomersField,
          page: 1
        })
        this.customersForDropdown = await response.json()
      } else {
        this.customersForDropdown = []
      }
    },
    editIdNumberCustomer(name, surname, id) {
      this.searchCustomersField = `${surname} ${name}`
      this.$emit('changeCustomerId', id)
      this.customersForDropdown = []
    },
    togglePopup() {
      this.isShowPopup = !this.isShowPopup
      if (this.isEditPopup) this.isEditPopup = false
      this.$emit('resetDataEvent')
    }
  }
}
</script>

<style lang="stylus">

</style>