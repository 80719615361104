<template>
  <div :class="{'telegram-chat__messenger white-container': !task}">
    <h4 v-if="!task">Telegram message</h4>
    <div v-if="messages && messages.length"
         ref="chat"
         :class="['telegram-chat__chat', {'telegram-chat__chat-task': task, 'telegram-chat__chat-task-feedback': isFeedbackCustomer}]"
    >
      <div
        class="telegram-chat__message-wrap"
        v-for="(message, index) in messages"
        :key="`message_${index}`"
      >
        <div
          class="telegram-chat__message"
        >
          <div class="telegram-chat__message-content">
            {{ message.text_message }}
            <div v-if="message.document" class="telegram-chat__message-data">
              <span class="telegram-chat__message-action">
                <a class="telegram-chat__message-action-search" target="_blank"
                   :href="`${message.document}`"><img src="../../assets/images/search-plus.svg" alt=""></a>
                <span style="cursor: pointer;" @click="downloadFile(`${message.document}`)">
                <img src="../../assets/images/download.svg" alt="">
                  </span>
              </span>
              <img :src="`${url}${message.document}`" alt="">
            </div>
          </div>
          <span class="telegram-chat__message-date">{{ message.date | date("date") }}</span>
        </div>
      </div>
    </div>
    <div v-else-if="task && !messages"
         :class="['telegram-chat__chat', {'telegram-chat__chat-task': task, 'telegram-chat__chat-task-feedback': isFeedbackCustomer}]">
      <div style="background: #a2a2a2; color: white;" class="telegram-chat__message">
        Wählen Sie einen Client, um einen Chatraum zu öffnen
      </div>
    </div>
    <div v-else
         :class="['telegram-chat__chat', {'telegram-chat__chat-task': task, 'telegram-chat__chat-task-feedback': isFeedbackCustomer}]">
      <div style="background: #a2a2a2; color: white;" class="telegram-chat__message">
        Der Client hat noch keine Nachrichten an den Bot gesendet
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TelegramChat",
  data() {
    return {
      messages: null,
      url: window.location.origin
    }
  },
  props: {
    task: false,
    isFeedbackCustomer: false
  },
  methods: {
    async getMessages(id = null) {
      let client_id
      if (this.$route.name === 'customer_page' || this.$route.name === 'documents_page') {
        client_id = this.$route.params.id.split('_')[1]
      } else {
        client_id = id
      }
      const response = await this.$services.getMessages.callMethod({client_id})
      const result = await response.json()
      this.messages = result.reverse()
    },
    downloadFile(fileUrl) {
      fetch(fileUrl)
        .then(response => response.blob())
        .then(blob => {
          const a = document.createElement('a');
          a.href = window.URL.createObjectURL(blob);
          a.download = fileUrl;
          document.body.appendChild(a);
          a.style.display = 'none';
          a.click();
          window.URL.revokeObjectURL(a.href);
          document.body.removeChild(a);
        })
        .catch(error => {
          console.error('Произошла ошибка при скачивании файла:', error);
        });
    }
  },
  async created() {
    if (!this.task) {
      await this.getMessages()
    }
  }
}
</script>

<style lang="stylus">

.telegram-chat
  &__messenger
    margin-bottom: 32px
    padding: 20px
    max-width 430px

  &__chat
    margin-top: 16px
    width: 100%
    height: 300px
    background #efefef
    border-radius 3px
    padding: 8px
    overflow scroll
    border: 1px solid rgba(182, 190, 216, 1)

    &-task
      height: 408px !important

      &-feedback
        height: 198px !important

  &__message
    padding: 6px 12px
    border-radius 13px
    background white
    display inline-flex
    align-items flex-end
    font-size: 14px

    &-wrap
      margin-top: 16px

      &:first-child
        margin-top: 0

    &-data
      position relative

      &:hover
        & .telegram-chat__message-action
          opacity 1

    &-action
      transition 0.2s
      opacity 0
      position absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      display flex
      align-items center
      justify-content center
      background rgba(0, 0, 0, 0.4)

      &-search
        margin-right: 8px

    &-content
      line-height 1.4

      img
        max-width: 120px;
        max-height: 200px;


    &-date
      font-size: 10px
      color grey
      margin-left: 8px


</style>