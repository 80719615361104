<template>
  <svg :width="width" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.3333 9.42466C25.3333 14.6298 21.0552 18.8493 15.7778 18.8493C10.5004 18.8493 6.22222 14.6298 6.22222 9.42466C6.22222 4.21956 10.5004 0 15.7778 0C21.0552 0 25.3333 4.21956 25.3333 9.42466Z"
      fill="#00C875"/>
    <path
      d="M6.69401 19.2803C10.9491 22.6465 17.6459 24.7976 25.2251 19.2551C29.3238 20.5251 32 22.6187 32 24.9863C32 28.8599 24.8366 32 16 32C7.16345 32 0 28.8599 0 24.9863C0 22.6343 2.64103 20.5527 6.69401 19.2803Z"
      fill="#00C875"/>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      default: '32',
      type: String
    }
  }
}
</script>