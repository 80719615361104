<template>
  <base_layout @searchInput="searchInput">
    <transition name="opacity">
      <message @resetMessage="resetMessage" v-if="isMessage" :success="successMessage" :message="addMessage"/>
    </transition>
    <div class="content">
      <div class="tasks">
        <div class="tasks__title-and-buttons">
          <h1 class="title-page">Aufgaben</h1>
          <div class="group-btn">
            <transition name="opacity">
              <div v-if="showEditForm" style="display: flex; align-items: center; margin-right: 16px;">
                <button @click.prevent="editGroupTasks" style="margin-right: 16px;" class="blue-button">
                  Speichern Sie
                </button>
                <v-select
                  v-model="editGroupTaskEmployee"
                  :options="$refs.createTask.userOptions"
                  label="label"
                  index="index"
                  :reduce="option => option.index"
                  placeholder="Mitarbeiter wechseln"
                  class="tasks__select-group"
                ></v-select>
                <v-select
                  v-model="editGroupTaskStatus"
                  :options="$refs.createTask.statusOptions"
                  label="label"
                  index="index"
                  :reduce="option => option.index"
                  placeholder="Status ändern"
                  class="tasks__select-group"
                  style="margin-left: 16px;"
                  :class="{'active-select': editGroupTaskStatus === 'Completed', 'closed-select': editGroupTaskStatus === 'Cancelled', 'opened-select': editGroupTaskStatus === 'Opened'}"
                ></v-select>
              </div>
            </transition>
            <button @click.prevent="$refs.createTask.togglePopup" class="blue-button"><span></span>Neue Aufgabe
            </button>
          </div>
        </div>
        <div v-if="mainLoader" :class="{'fadein-animation': !mainLoader}" class="big-loader loader">
          <span></span></div>
        <div style="height: 77vh;" :class="{'fadein-animation': !mainLoader}" v-else>
          <div class="tasks__sort-and-filters">
            <div class="tasks__sort">
              <a @click.prevent="clickFilter('Opened')"
                 :class="{'tasks__sort-active': activeLinkFilter === 'Opened'}"
                 href="#">Geöffnet</a>
              <a @click.prevent="clickFilter('Completed')"
                 :class="{'tasks__sort-active': activeLinkFilter === 'Completed'}"
                 href="#">Abgeschlossen</a>
              <a @click.prevent="clickFilter()" :class="{'tasks__sort-active': activeLinkFilter === null}"
                 href="#">Alle Aufgaben</a>
            </div>
          </div>
          <div ref="scrollContainer" @scroll="handleScroll('debounceSearchTasks', 'debounceGetTasks')"
               class="table">
            <table v-if="tasks.length !== 0" cellspacing="0" cellpadding="0">
              <thead>
              <tr>
                <td class="table__column-checkbox">
                  <base-checkbox id="all" @click="toggleAllCheckbox('tasks', 'showEditForm')"
                                 :val-checkbox="allCheckbox"/>
                </td>
                <td class="text-transform-uppercase">ID</td>
                <td class="text-transform-uppercase">Erstellt</td>
                <td class="text-transform-uppercase">Kunde/Schalter</td>
                <td class="text-transform-uppercase">Aufgabe</td>
                <td class="text-transform-uppercase" v-if="user.user_type[0].user_type === 'admin'">Mitarbeiter</td>
                <td class="text-transform-uppercase">Verbleibende Zeit</td>
                <td class="text-transform-uppercase">Status</td>
                <td class="text-transform-uppercase">Nachricht</td>
                <td class="text-transform-uppercase">Aktion</td>
              </tr>
              </thead>
              <tbody>
              <tr :class="{'tr-checked': task.task.isChecked}" :key="'task' + task.task.id"
                  v-for="(task, index) in tasks">
                <td class="table__column-checkbox">
                  <base-checkbox :id="'input' + task.task.id"
                                 @click="toggleRowCheckbox(task.task, 'showEditForm')"
                                 :val-checkbox="task.task.isChecked"/>
                </td>
                <td class="table__column-id">
                  <a href="#" @click.prevent="$refs.createTask.editOpenTask(task)">#{{ task.task.id }}</a>
                </td>
                <td>{{ task.task.create_of_date | date('date') }}</td>
                <td>
                  <a href="#" @click.prevent="openCustomerPage(task.customer[0].id, task.task.id)">
                    {{ task.customer.length ? task.customer[0].name : '' }}
                    {{ task.customer.length ? task.customer[0].surname : '-' }}
                    /
                    {{ task.task.counter_number }}
                  </a>
                </td>
                <td>{{ task.task.type_of_task }}</td>
                <td>
                  <div class="table__column-employee">
                    <span>{{ _transformEmployee(task.user[0].first_name, task.user[0].last_name) }}</span>
                  </div>
                </td>
                <td class="table__column-deadline">
                  <div
                    :class="{'table__column-deadline-red': moment(task.task.deadline, 'DD.MM.YYYY').isBefore(moment())}">
                    <svg width="15" height="18" viewBox="0 0 15 18" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M7.7453 3.5094C4.01997 3.5094 1 6.52937 1 10.2547C1 13.98 4.01997 17 7.7453 17C11.4706 17 14.4906 13.98 14.4906 10.2547C14.4906 6.52937 11.4706 3.5094 7.7453 3.5094ZM7.7453 3.5094V1M7.7453 6.20752V10.2547L10.4434 11.6038"
                        stroke="#232323" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span>{{ task.task.deadline }}</span>
                  </div>
                </td>
                <td class="table__column-status"
                    :class="{
                      'table__column-status-open': task.task.status === 'Opened',
                      'table__column-status-cancel': task.task.status === 'Cancelled',
                      'table__column-status-complete': task.task.status === 'Completed'
                    }"
                >
                  <div>
                    <span></span>
                    {{ _transformStatus(task.task.status) }}
                  </div>
                </td>
                <td :class="{'table__column-message': Number(task.task.count_message) >= 1}"
                    style="position: relative; text-align: center">
                  <span>{{ task.task.count_message ? task.task.count_message : 0 }}</span>
                </td>
                <td>
                  <a @click.prevent="deleteWindow = index" style="position: relative; z-index: 1;" href="#">
                    <img src="@/assets/images/trash_icon.svg" alt="trash icon">
                  </a>
                  <div v-if="deleteWindow === index" class="warning-delete warning-delete-small">
                    Sind Sie sicher, dass Sie den Client entfernen möchten?
                    <div>
                      <button @click.prevent="deleteTask(task.task.id)">Ja</button>
                      <button @click.prevent="deleteWindow = null">Nein</button>
                    </div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="warn-blank-table" v-else>Die Tabelle enthält keine Daten</div>
            <div v-if="isLoading" class="loader"><span></span></div>
          </div>
        </div>
      </div>
    </div>
    <create-task
      ref="createTask"
      @clickFilter="clickFilter(activeLinkFilter)"
      @taskError="showMessage(false, `[Error] Gescheitert create/edit eine Aufgabe`)"
      @taskSuccess="showMessage(true, `Die Aufgabe wurde erfolgreich abgeschlossen created/edited`)"
    />
  </base_layout>
</template>

<script>
import Base_layout from "@/layouts/base_layout";
import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select'

import {debounce} from 'lodash'

// MIXINS
import togglePopup from "@/mixins/components/togglePopup";
import transformEmployee from "@/mixins/components/_transformEmployee";
import getUser from "@/mixins/users/getUser";
import success_error_state from "@/mixins/components/success_error_state";
import taskValidations from '@/mixins/tasks/validations'
import toggleCheckboxInTable from "@/mixins/components/toggleCheckboxInTable";
import lazyLoadScrollTable from "@/mixins/components/lazyLoadScrollTable";
import validations from "@/mixins/tasks/validations";
import transformStatus from "@/mixins/components/_transformStatus";
import tableTasks from "@/mixins/tasks/tableTasks";
import notification from "@/mixins/notification";

// COMPONENTS
import BaseFieldInput from "@/components/fields/BaseFieldInput.vue";
import BaseCheckbox from "@/components/fields/BaseCheckbox.vue";
import Message from "@/components/message";
import fieldErrorRequired from '@/components/fieldErrorRequired'

import moment from 'moment'
import CreateTask from "@/components/popups/createTask.vue";

export default {
  name: "tasks_page",
  components: {
    CreateTask,
    Message,
    Base_layout,
    BaseFieldInput,
    BaseCheckbox,
    fieldErrorRequired,
    validations,
    vSelect,
  },
  mixins: [
    tableTasks,
    transformStatus,
    togglePopup,
    transformEmployee,
    success_error_state,
    taskValidations,
    getUser,
    toggleCheckboxInTable,
    lazyLoadScrollTable,
    notification
  ],
  data() {
    return {
      user: null,
      users: null,
      activeLinkFilter: 'Opened',
      mainLoader: true,
      showEditForm: false,
      editGroupTaskEmployee: '',
      editGroupTaskStatus: '',
      moment: moment,
      deleteWindow: null,
    }
  },
  async mounted() {
    let response = await this.$services.getUsers.callMethod()
    this.users = await response.json()
    await this.checkUserTypeAndStatusInTasks()
    this.mainLoader = false
  },
  methods: {
    openCustomerPage(id, taskId) {
      this.$router.push({
        name: 'customer_page',
        params: {
          id: `actual_${id}`
        },
        query: {
          task_id: taskId
        }
      });
    },
    async deleteTask(id) {
      try {
        this.deleteWindow = null
        await this.$services.deleteTasks.callMethod({id: [id]})
        await this.clickFilter(this.activeLinkFilter)
        this.showMessage(true, 'Aufgabe erfolgreich gelöscht')
      } catch (e) {
        this.showMessage(false, '[Error] Die Aufgabe konnte nicht gelöscht werden, versuchen Sie es erneut!')
      }
    },
    async editGroupTasks() {
      let array = []
      for (let task in this.tasks) {
        if (this.tasks[task].task.isChecked)
          array.push(this.tasks[task].task.id)
      }
      let obj = {task_ids: array}
      if (this.editGroupTaskEmployee !== '') {
        obj.user_id = this.editGroupTaskEmployee
      }
      if (this.editGroupTaskStatus !== '') {
        obj.status = this.editGroupTaskStatus
      }
      try {
        await this.$services.editTasks.callMethod(obj)
        this.editGroupTaskEmployee = ''
        this.editGroupTaskStatus = ''
        this.showEditForm = false
        await this.clickFilter(this.activeLinkFilter)
      } catch (e) {
        console.error('[ERROR] Failed to delete tasks' + e.status)
      }
    }
  },
  watch: {
    '$store.state.telegramMessgae': {
      deep: true,
      handler(to) {
        console.log('KKEKEKEKE')
        console.log(to)
      }
    }
  }
}
</script>

<style lang="stylus">
@import "@/assets/stylus/popup.styl"

.tasks
  height: 100%

  &__select-group
    width: 200px
    height: 45px

    & .vs
      &__dropdown-toggle
        height: 100%
        border 1px solid #517bfb

      &__clear > svg,
      &__open-indicator
        fill #517bfb

      &__search
        border none !important

        &::placeholder
          color #517bfb !important

      &__dropdown-menu
        border-color #517bfb

    &.vs--open
      & .vs__selected
        position initial

  &__title-and-buttons
    display flex
    justify-content space-between
    align-items center

  &__change-select
    margin-right: 16px
    border 2px solid #517BFB
    padding: 11.5px 24px
    border-radius 4px
    color #517BFB
    cursor pointer
    outline none

  &__sort-and-filters
    margin-top: 50px
    margin-bottom: 40px
    display flex
    align-items center
    justify-content space-between
    border-bottom 1px solid #B6BED8
    padding-bottom: 8px

  &__sort
    &-active
      color $blue !important
      border-bottom 4px solid $blue
      text-shadow 2px 2px 4px rgba(31, 85, 250, 0.3)

    a
      color #999999
      font-size: 20px
      font-weight: 500
      margin-left: 32px
      padding-bottom: 4px

      &:first-child
        margin-left: 0

</style>
