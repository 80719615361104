import { render, staticRenderFns } from "./tasks_page.vue?vue&type=template&id=b164f382"
import script from "./tasks_page.vue?vue&type=script&lang=js"
export * from "./tasks_page.vue?vue&type=script&lang=js"
import style0 from "./tasks_page.vue?vue&type=style&index=0&id=b164f382&prod&lang=stylus"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports