import Vue from "vue";
import { debounce } from "lodash";

/**
 * @typedef {Object} Task
 * @property {boolean} isChecked - Indicates if the task is checked.
 * @property {Object} task - Task details.
 */

export default {
  data() {
    return {
      isLoading: false,
      countNextPage: 1,
      searchInputs: '',
      countSearchNextPage: 2,
      tasks: [],
    };
  },
  async created() {
    this.debounceGetTasks = debounce(this.checkUserTypeAndStatusInTasks, 200);
    this.debounceSearchTasks = debounce(this.searchPaginationInput, 200);
  },
  methods: {
    /**
     * Check and set customer_id in the given object if the route is 'customer_page'.
     * @param {Object} obj - Object to check and modify.
     */
    _checkPageForCustomerId(obj) {
      if (this.$route.name === 'customer_page') {
        obj.customer_id = this.customer_id;
      }
    },

    /**
     * Perform a search with pagination based on the input value.
     */
    async searchPaginationInput() {
      let response;
      let dataObj = {
        search: this.searchInputs,
        page: this.countSearchNextPage,
      };
      this._checkPageForCustomerId(dataObj);

      try {
        response = await this.$services.searchTasks.callMethod(dataObj);
        if (response.status === 400) {
          this.isLoading = false;
          this.checkHeight = true;
        }
        let result = await response.json();
        this.tasks = [...this.tasks, ...result];
        this.countSearchNextPage++;
      } catch (error) {
        console.error(`[ERROR]: ${error.message} Failed to perform search with pagination.`);
      }
    },

    /**
     * Handle the input for task search.
     * @param {Event} event - Input change event.
     */
    async searchInput() {
      const $event = event.target.value;
      this.searchInputs = $event;
      this.checkHeight = false;
      this.isLoading = false;
      this.search = true;
      this.countSearchNextPage = 2;
      let response;
      let dataObj;

      if (!$event.length) {
        this.clickFilter(this.activeLinkFilter);
      } else {
        dataObj = {
          search: $event,
          page: 1,
          customer_id: this.customer_id,
        };

        try {
          response = await this.$services.searchTasks.callMethod(dataObj);
          this.tasks = await response.json();
        } catch (error) {
          console.error(`[ERROR]: ${error.message} Something went wrong with the task search. Status: ${response.status}`);
        }
      }

      if (this.$route.name === 'customer_page') {
        this.searchInputFolders($event);
        this.searchInputCounters($event);
      }
    },

    /**
     * Handle filter click event.
     * @param {string} [filter=null] - Filter status.
     */
    async clickFilter(filter = null) {
      this.search = false;
      this.countNextPage = 2;
      this.checkHeight = false;
      this.isLoading = false;
      let dataObj = {
        page: 1,
        customer_id: this.customer_id,
      };

      this.activeLinkFilter = filter;

      if (filter !== null) {
        dataObj.status = filter;
      }

      this.tasks = await this.getTasks(dataObj);
    },

    /**
     * Check user type and status in tasks using debounced method.
     */
    async checkUserTypeAndStatusInTasks() {
      let result;
      let dataObj = {
        page: this.countNextPage,
      };

      this._checkPageForCustomerId(dataObj);

      if (this.activeLinkFilter !== null) {
        dataObj.status = this.activeLinkFilter;
      }

      result = await this.getTasks(dataObj);

      if (result !== undefined) {
        this.tasks = [...this.tasks, ...result];
      }

      this.countNextPage++;

      this.addIsCheckedBool();
      this.isLoading = false;
    },

    /**
     * Add isChecked property to each task in the tasks array.
     */
    addIsCheckedBool() {
      this.tasks.forEach((task) => {
        Vue.set(task.task, 'isChecked', false);
      });
    },

    /**
     * Get tasks based on the given object parameters.
     * @param {Object} obj - Object containing parameters for getting tasks.
     * @returns {Promise<Array<Task>>} - Promise with the list of tasks.
     */
    async getTasks(obj) {
      try {
        let response = await this.$services.getTasks.callMethod(obj);
        return await response.json();
      } catch (error) {
        this.isLoading = false;
        this.checkHeight = true;
        console.warn('[WARN]: Die Aufgaben sind vorbei!');
      }
    },
  },
};
