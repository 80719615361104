import id from "vue2-datepicker/locale/es/id";
import da from "vue2-datepicker/locale/es/da";
import store from "@/store";
import Vue from "vue";
import moment from 'moment'

export default {
    data() {
        return {
            isPopup: false,
            isPopupEditCustomer: false,
            isPopupCustomer: false,
            isPopupCustomerCounters: false,
            isPopupCustomerEditCounters: false,
            isPopupCustomerEditContracts: false,
            isPopupCustomerContracts: false,
            isPopupOperator: false,
            isPopupEditOperator: false,
            isPopupFolder: false
        }
    },
    methods: {
        async editOpenOperator(data) {
            this.isPopupOperator = true
            this.isPopupEditOperator = true
            this.operator = {
                operator_name: data.operator_name,
                operator_street: data.operator_street,
                operator_house_number: data.operator_house_number,
                operator_postcode: data.operator_postcode,
                operator_city: data.operator_city,
                operator_email: data.operator_email,
                operator_email_private: data.operator_email_private,
                operator_fax: data.operator_fax,
                operator_phone: data.operator_phone,
                operator_id: data.id
            }
        },
        checkContracts() {
            console.log(this.counters.contracts)
            for (const contract in this.counters.contracts) {
                if (this.counters.contracts[contract].contract_status === 'Active') {
                    return true
                }
            }
        },
        checkOptionStatusContract() {
            const statuses = this.$refs.createCustomer.statusContractOptions
            for (const status in statuses) {
                if (statuses[status].index !== 'Active') {
                    this.$refs.createCustomer.statusContractOptions.push({
                        label: 'Aktiver Status',
                        index: 'Active'
                    });
                }
            }
        },
        async editOpenContract(data) {
            console.log('openEditContract')
            if (this.checkContracts()) {
                const statuses = this.$refs.createCustomer.statusContractOptions;
                const activeStatusIndex = statuses.findIndex(status => status.index === 'Active');

                if (activeStatusIndex !== -1) {
                    statuses.splice(activeStatusIndex, 1);
                }
            } else {
                const hasActiveStatus = this.$refs.createCustomer.statusContractOptions.some(status => status.index === 'Active');

                if (!hasActiveStatus) {
                    this.$refs.createCustomer.statusContractOptions.push({
                        label: 'Aktiver Status',
                        index: 'Active'
                    });
                }
            }
            const {index, contract} = data
            this.isPopupCustomerEditContracts = true
            this.isPopupCustomerContracts = true
            await this.getOperatorsInDropdown()
            this.contract = {
                index: index,
                contract_id: contract.contract_id,
                counters_id: contract.counters_id,
                operator_name: contract.operator_name,
                customer_number: contract.customer_number,
                prior_year_consumption: contract.prior_year_consumption,
                contract_start_date: contract.contract_start_date,
                contract_end_date: contract.contract_end_date,
                start_count: contract.start_count,
                end_count: contract.end_count,
                start_count_night: contract.start_count_night,
                end_count_night: contract.end_count_night,
                condition_number: contract.condition_number,
                calorific_value: contract.calorific_value,
                consumption: contract.consumption,
                consumption_night: contract.consumption_night,
                contract_status: contract.contract_status,
                cost_per_day: contract.cost_per_day,
                cost_per_night: contract.cost_per_night,
                cost_monthly: contract.cost_monthly,
            }
        },
        async editOpenCounter(data, isMergeCustomer = false) {
            console.log(data)
            if (this.$v.customer.$invalid && !data.editTable && !data.newTable) {
                this.$refs.createCustomer.callValidation('customer')
                return
            }
            const editTable = data.editTable
            const newTable = data.newTable
            if (editTable) {
                const index = data.index
                const counter = data.counter
                if (this.$route.name === 'customer_page' && !isMergeCustomer) {
                    this.editOpenCustomer(this.customer_data)
                }
                this.showPopup('isPopupCustomer')
                this.showPopup('isPopupCustomerCounters')
                this.isPopupEditCustomer = true
                this.isPopupCustomer = true
                this.isPopupCustomerCounters = true
                this.isPopupCustomerEditCounters = true
                this.countNextPageContracts = 2
                this.checkHeightContracts = false
                this.isLoadingContracts = false
                this.searchContracts = false

                this.counters = {
                    index: index,
                    counters_id: counter.counters_id,
                    counters_type: counter.counters_type,
                    counters_number: counter.counters_number,
                    counter_street: counter.counter_street,
                    counter_house_number: counter.counter_house_number,
                    counter_postcode: counter.counter_postcode,
                    counter_city: counter.counter_city,
                    iban: counter.iban,
                    bic: counter.bic,
                    banks_name: counter.banks_name,
                    account_owner: counter.account_owner,
                    mao_id: counter.mao_id
                }

                if (this.$route.name === 'customer_page' || this.$route.name === 'documents_page') {
                    store.commit('setCounterId', this.counters.counters_id)
                    Vue.set(this.counters, 'contracts', await this.getContracts(counter.counters_id));
                } else {
                    this.counters.contracts = counter.contracts
                }
                this.isPopupCustomerEditCounters = true
            } else if (newTable) {
                this.editOpenCustomer(this.customer_data)
                this.showPopup('isPopupCustomerCounters')
            }
            this.isPopupCustomerCounters = true
        },
        async editOpenCustomer(customer) {
            this.isPopupEditCustomer = true
            this.isPopupCustomer = true
            if (this.searchCounters) {
                this.customer_data.counters = []
                this.countNextPageCounters = 1
                this.countSearchNextPageCounters = 2
                await this.getPaginationCounters()
            }
            this.customer = {
                type_client: customer.client.type_client,
                addressing_customers: customer.client.addressing_customers,
                company: customer.client.company,
                surname: customer.client.surname,
                name: customer.client.name,
                accommodation_street: customer.client.accommodation_street,
                accommodation_house_number: customer.client.accommodation_house_number,
                accommodation_postcode: customer.client.accommodation_postcode,
                accommodation_city: customer.client.accommodation_city,
                private_phone: customer.client.private_phone,
                personal_phone: customer.client.personal_phone,
                email: customer.client.email,
                email_private_password: customer.client.email_private_password,
                email_private: customer.client.email_private,
                telegram_id: customer.client.telegram_id,
                date_of_birth: customer.client.date_of_birth,
                recommender: customer.client.recommender,
                counters: customer.counters
            }
            this.customer.customer_id = customer.client.id
        },
        closePopup() {
            this.isPopup = false
            if (this.isPopupFolder) {
                this.isPopupFolder = false
                this.new_folder = {
                    folder_name: ''
                }
            }
            if (this.isPopupOperator) {
                this.isPopupEditOperator = false
                this.isPopupOperator = false
                this.operator = {
                    operator_name: '',
                    operator_street: '',
                    operator_house_number: '',
                    operator_postcode: '',
                    operator_city: '',
                    operator_email: '',
                    operator_phone: ''
                }
                if (this.operator.operator_id) {
                    delete this.operator.operator_id
                }
            }
            if (this.isPopupCustomerContracts) {
                const hasActiveStatus = this.$refs.createCustomer.statusContractOptions.some(status => status.index === 'Active');

                if (!hasActiveStatus) {
                    this.$refs.createCustomer.statusContractOptions.push({
                        label: 'Aktiver Status',
                        index: 'Active'
                    });
                }
            }
            if (this.isPopupCustomerEditContracts) {
                this.isPopupCustomerEditContracts = false
                this.isPopupCustomerContracts = false
            } else if (this.isPopupCustomerEditCounters && !this.isPopupCustomerEditContracts && !this.isPopupCustomerContracts) {
                this.isPopupCustomerEditCounters = false
                this.isPopupCustomerCounters = false
            } else if (this.isPopupCustomer && !this.isPopupCustomerCounters && !this.isPopupCustomerContracts) {
                this.isPopupCustomer = false
            } else if (this.isPopupCustomerCounters && !this.isPopupCustomerContracts && !this.isPopupCustomerEditContracts) {
                this.isPopupCustomerCounters = false
            } else if (this.isPopupCustomerContracts) {
                this.isPopupCustomerContracts = false
            }

            if (!this.isPopupCustomer) {
                this.customer = {
                    customer_id: '',
                    type_client: '',
                    addressing_customers: '',
                    company: '',
                    surname: '',
                    name: '',
                    accommodation_street: '',
                    accommodation_house_number: '',
                    accommodation_postcode: '',
                    accommodation_city: '',
                    private_phone: '',
                    personal_phone: '',
                    email: '',
                    email_private_password: '',
                    email_private: '',
                    telegram_id: '',
                    date_of_birth: '',
                    recommender: '',
                    counters: []
                }
                this.counters = {
                    counter_id: '',
                    counters_type: 'Type',
                    counters_number: '',
                    counter_street: '',
                    counter_house_number: '',
                    counter_postcode: '',
                    counter_city: '',
                    iban: 'DE',
                    bic: '',
                    banks_name: '',
                    account_owner: '',
                    mao_id: ''
                }
                this.contract = {
                    contract_id: '',
                    counters_id: '',
                    operator_name: '',
                    customer_number: '',
                    prior_year_consumption: '',
                    contract_start_date: '',
                    contract_end_date: '',
                    start_count: '',
                    end_count: '',
                    start_count_night: '',
                    end_count_night: '',
                    condition_number: '',
                    calorific_value: '',
                    consumption: '',
                    consumption_night: '',
                    contract_status: 'Pending',
                    cost_per_day: '',
                    cost_per_night: '',
                    cost_monthly: ''
                }
            }
        },
        async showPopup(name) {
            this[name] = true
            if (name === 'isPopupCustomerCounters') {
                delete this.counters.index
                this.counters = {
                    counter_id: '',
                    counters_type: 'Type',
                    counters_number: '',
                    counter_street: '',
                    counter_house_number: '',
                    counter_postcode: '',
                    counter_city: '',
                    iban: 'DE',
                    bic: '',
                    banks_name: '',
                    account_owner: '',
                    contracts: [],
                    mao_id: ''
                }
            } else if (this.isPopupCustomerContracts) {
                if (this.checkContracts()) {
                    const statuses = this.$refs.createCustomer.statusContractOptions;
                    const activeStatusIndex = statuses.findIndex(status => status.index === 'Active');

                    if (activeStatusIndex !== -1) {
                        statuses.splice(activeStatusIndex, 1);
                    }
                } else {
                    const hasActiveStatus = this.$refs.createCustomer.statusContractOptions.some(status => status.index === 'Active');

                    if (!hasActiveStatus) {
                        this.$refs.createCustomer.statusContractOptions.push({
                            label: 'Aktiver Status',
                            index: 'Active'
                        });
                    }
                }
                console.log('kek')
                await this.getOperatorsInDropdown()
                this.contract = {
                    contract_id: '',
                    counters_id: '',
                    operator_name: '',
                    customer_number: '',
                    prior_year_consumption: '',
                    contract_start_date: '',
                    contract_end_date: '',
                    start_count: '',
                    end_count: '',
                    start_count_night: '',
                    end_count_night: '',
                    condition_number: '',
                    calorific_value: '',
                    consumption: '',
                    consumption_night: '',
                    contract_status: 'Pending',
                    cost_per_day: '',
                    cost_per_night: '',
                    cost_monthly: ''
                }
                delete this.contract.index
                delete this.contract.contract_id
            }
        },
    }
}
