export default {
  methods: {
    openPopupArchive(id) {
      this.$refs.reasonForArchiving.isPopup = true
      this.$refs.reasonForArchiving.id = id
      if (this.$route.name === 'customers_page') {
        this.deleteWindow = null
      }
    },
  }
}