const Centrifuge = require("centrifuge");


class BaseEvents {
  connect() {
    const location = window.location;
    let host = location.protocol === 'https:' ? 'wss:' : 'ws:';
    host += '//' + location.hostname + (location.hostname === 'localhost' ? ':9000' : '');
    this.centrifuge = new Centrifuge(`${host}/connection/websocket`);
    this.centrifuge.on('connect', function (ctx) {
      console.log('Centrifugo connected: ', ctx);
    });
    this.centrifuge.on('disconnect', function (ctx) {
      console.log('Centrifugo disconnected: ' + ctx.reason);
    });
    this.centrifuge.connect();
  }

  subscribe(channelName, callback) {
    if (!this.centrifuge || !this.centrifuge.isConnected()) {
      this.connect();
    }
    return this.centrifuge.subscribe(channelName, message => callback(message.data));
  }

  describeAll() {
    this.centrifuge.disconnect();
  }
}

class messages extends BaseEvents {
  messageGetAll() {
    return (callback) => {
      const channelName = `messages__get-all`;
      return this.subscribe(channelName, data => callback(data));
    }
  }

  messageTask(taskId) {
    return (callback) => {
      const channelName = `messages__get-${taskId}`;
      return this.subscribe(channelName, data => callback(data));
    }
  }
}

export default {
  install(Vue) {
    Vue.prototype.$events = {
      messages: new messages()
    }
  }
}