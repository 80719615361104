import {required} from "vuelidate/lib/validators";

export default {
    validations: {
        newTask: {
            user_id: {required},
            customer_id: {required},
            type_of_task: {required},
            deadline: {required},
            counter_number: {required},
        }
    },
}
