<template>
  <div>
    <TelegramChat/>
    <div class="customer__data-customer white-container">
      <div class="customer__data-customer-section">
        <div class="customer__data-customer-title-and-edit">
          <h5>KUNDE</h5>
          <a v-if="!customerData.client.archive" @click.prevent="$emit('editOpenCustomer')" href="#">bearbeiten</a>
        </div>
        <p>{{ customerData.client.type_client }}</p>
        <p>{{ customerData.client.addressing_customers }}</p>
        <p>{{ customerData.client.company }}</p>
        <p>{{ customerData.client.name }} {{ customerData.client.surname }}</p>
        <p>{{ customerData.client.date_of_birth }}</p>
      </div>
      <div class="customer__data-customer-section">
        <div class="customer__data-customer-title-and-edit">
          <h5>ADRESSE</h5>
          <a v-if="!customerData.client.archive" @click.prevent="$emit('editOpenCustomer')" href="#">bearbeiten</a>
        </div>
        <p>{{ customerData.client.accommodation_street }} {{
            customerData.client.accommodation_house_number
          }}</p>
        <p>{{ customerData.client.accommodation_postcode }} {{ customerData.client.accommodation_city }}</p>

      </div>
      <div class="customer__data-customer-section">
        <div class="customer__data-customer-title-and-edit">
          <h5>KONTAKT</h5>
          <a v-if="!customerData.client.archive" @click.prevent="$emit('editOpenCustomer')" href="#">bearbeiten</a>
        </div>
        <p>C Email: <a :href="'mailto:' + customerData.client.email">{{ customerData.client.email }}</a></p>
        <p>P Email: <a :href="'mailto:' + customerData.client.email_private">{{ customerData.client.email_private }}</a></p>
        <p>Privat: {{ customerData.client.private_phone }}</p>
        <p>Persönlich: {{ customerData.client.personal_phone }}</p>
      </div>
      <div class="customer__data-customer-section">
        <div class="customer__data-customer-title-and-edit">
          <h5>EMPFEHLUNG</h5>
          <a v-if="!customerData.client.archive" @click.prevent="$emit('editOpenCustomer')" href="#">bearbeiten</a>
        </div>

        <p>{{ customerData.client.recommender }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import TelegramChat from "@/components/elements/TelegramChat.vue";

export default {
  components: {TelegramChat},
  props: {
    customerData: {
      client: {
        type_client: String,
        addressing_customers: String,
        company: String,
        name: String,
        surname: String,
        date_of_birth: String,
        accommodation_street: String,
        accommodation_house_number: String,
        accommodation_postcode: String,
        accommodation_city: String,
        email: String,
        private_phone: String,
        personal_phone: String,
        email_private_password: String,
        email_private: String,
        telegram_id: String,
        recommender: String
      }
    }
  }
}
</script>

<style scoped lang="stylus">

</style>