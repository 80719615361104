<template>
  <div class="container">
    <div style="display: flex">
      <base-sidebar/>
      <div style="display: flex; flex-direction: column; width: 100%; padding-right: 2.8vw;">
        <header class="header">
          <div style="display: flex; align-items: center">
            <div class="header__input">
              <img v-if="$route.name !== 'settings_page'" src="@/assets/images/header-search_icon.svg" alt="">
              <input v-if="$route.name !== 'settings_page'" @input="$emit('searchInput')" placeholder="Search"
                     type="text">
            </div>
            <router-link to="/" tag="div" class="header__stat">
              <div class="header__stat-items">
                <div class="header__stat-item">
                  <div class="header__stat-item-up">
                    <div>
                      <StatisticPrivate
                        width="14"
                      />
                    </div>
                    Privatkunden:
                    <span> {{ statistics ? statistics.privat.privat_qty_customer : '' }}</span>
                  </div>
                  <div class="header__stat-item-down">
                    <div style="background: rgba(204, 51, 51, 0.2)">
                      <StatisticsEnergyCount/>
                      {{ statistics ? statistics.privat.qty_electricity : '' }}
                    </div>
                    <div style="background: rgba(81, 123, 251, 0.2)">
                      <StatisticsGasCount/>
                      {{ statistics ? statistics.privat.qty_gas : '' }}
                    </div>
                    <div style="background: rgba(221, 70, 4, 0.3)">
                      <StatisticsTotalCount/>
                      {{ statistics ? statistics.privat.qty_gas + statistics.privat.qty_electricity : '' }}
                    </div>
                  </div>
                </div>
                <div class="header__stat-item">
                  <div class="header__stat-item-up">
                    <div style="background: rgba(81, 123, 251, 0.2)">
                      <StatisticsCompany
                        width="12"
                      />
                    </div>
                    Geschäftskunden:
                    <span>{{ statistics ? statistics.company.company_qty_customer : '' }}</span>
                  </div>
                  <div class="header__stat-item-down">
                    <div style="background: rgba(204, 51, 51, 0.2)">
                      <StatisticsEnergyCount/>
                      {{ statistics ? statistics.company.qty_electricity : '' }}
                    </div>
                    <div style="background: rgba(81, 123, 251, 0.2)">
                      <StatisticsGasCount/>
                      {{ statistics ? statistics.company.qty_gas : '' }}
                    </div>
                    <div style="background: rgba(221, 70, 4, 0.3)">
                      <StatisticsTotalCount/>
                      {{ statistics ? statistics.company.qty_gas + statistics.company.qty_electricity : '' }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="header__stat-link">
                <Arrow/>
              </div>
            </router-link>
          </div>
          <div class="header__actions">
            <div class="header__notification-wrap">
              <div @click.prevent="showDropdownNotification = !showDropdownNotification" class="header__notification">
                <img src="@/assets/images/header-notification_icon.svg" alt="">
                <span v-if="$store.state.telegramMessages.length"
                      class="header__notification-count">{{ $store.state.telegramMessages.length }}</span>
              </div>
              <transition name="dropdown">
                <div v-if="showDropdownNotification && $store.state.telegramMessages.length"
                     class="header__notification-dropdown">
                  <div
                    v-for="(message, index) in $store.state.telegramMessages"
                    :key="`message_${index}}`"
                    @click.prevent="onRowClick(message.customer_id, message.id)"
                  >
                    <span>i</span> Es ist eine Nachricht von Kunde Nr. {{ message.customer_id }} zu Auftrag Nr.
                    {{ message.id }} eingegangen
                  </div>
                </div>
              </transition>
            </div>
            <div class="header__info">
              <div @click="logout" class="header__main-info">
                <div>
                  <span>
                    {{ _transformEmployee(user.user.first_name, user.user.last_name) }}
                  </span>
                </div>
                {{ user.user.first_name }} {{ user.user.last_name }}
              </div>
            </div>
          </div>
        </header>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import BaseSidebar from "@/components/elements/baseSidebar.vue";
import transformEmployee from "@/mixins/components/_transformEmployee";
import getUser from "@/mixins/users/getUser";
import StatisticPrivate from "@/components/icons/StatisticPrivate.vue";
import StatisticsEnergyCount from "@/components/icons/StatisticsEnergyCount.vue";
import StatisticsGasCount from "@/components/icons/StatisticsGasCount.vue";
import StatisticsTotalCount from "@/components/icons/StatisticsTotalCount.vue";
import StatisticsCompany from "@/components/icons/StatisticsCompany.vue";
import Arrow from "@/components/icons/Arrow.vue";
import {isArray} from "lodash";

export default {
  name: "base_layout",
  components: {
    Arrow,
    StatisticsCompany,
    StatisticsTotalCount,
    StatisticsGasCount, StatisticsEnergyCount, StatisticPrivate, BaseSidebar
  },
  mixins: [transformEmployee, getUser],
  data() {
    return {
      showDropdown: false,
      image: '',
      statistics: null,
      showDropdownNotification: false
    }
  },
  async created() {
    this.image = this.$store.state.staticUrl + this.user.user_type[0].image_url
    await this.getStatistics()
    const response = await this.$services.getNotification.callMethod()
    const result = await response.json()
    this.$store.commit('setTelegramMessages', result)
  },
  methods: {
    logout() {
      localStorage.removeItem('auth_token')
      this.$router.push({name: 'auth_page'})
    },
    async getStatistics() {
      const res = await this.$services.getStatisticsMini.callMethod()
      this.statistics = await res.json()
    },
    onRowClick(id, task_id) {
      this.$router.push(
        {
          name: 'customer_page',
          query: {task_id: task_id},
          params: {id: `actual_${id}`}
        }
      )
      if (this.$route.name === 'customer_page') {
        setTimeout(() => {
          window.location.reload()
        }, 200)
      }
    }
  }
}
</script>

<style lang="stylus">
@import "@/assets/stylus/variables.styl"

.header
  max-width 100%
  width: 100%
  padding: 28px 0
  display flex
  justify-content space-between
  align-items center

  &__input
    position relative
    max-width 300px
    width: 100%

    input
      height: 48px
      border-radius 8px
      border 1px solid
      border-color #E6E6E6
      outline none
      background none
      padding-left: 44.5px

      &::placeholder
        color #B3B3B3

    img
      position absolute
      left: 16px
      top: 50%
      transform translateY(-50%)
      z-index -1

  &__actions
    display flex
    align-items center

  &__stat
    cursor pointer
    display flex
    align-items center
    margin-left: 48px

    &-link
      width: 20px
      height: 20px
      border 1px solid black
      border-radius 100%
      margin-left: 32px
      display flex
      align-items center
      justify-content center

    &-items
      display flex
      align-items flex-start

    &-item
      display flex
      align-items flex-start
      flex-direction column

      &:first-child
        margin-right: 32px

      &-up
        display flex
        align-items center
        font-weight: 500
        white-space nowrap

        span
          margin-left: 4px
          font-weight: 700
          font-size: 20px


        div
          width: 24px
          height: 24px
          display flex
          align-items center
          justify-content center
          background rgba(#00C875, 0.2)
          border-radius 100%
          margin-right: 6px

      &-down
        display flex
        align-items center
        margin-top: 8px

        div
          border-radius 6px
          padding: 4px 8px
          display flex
          align-items center

          & + div
            margin-left: 12px

          svg
            margin-right: 4px


  &__notification
    cursor pointer
    display flex
    align-items center
    justify-content center
    background #F0F0F0
    border-radius 4px
    width: 40px
    height: 40px

    &-count
      border-radius 100%
      background red
      display flex
      align-items center
      justify-content center
      font-size: 12px
      width: 20px
      height: 20px
      color white
      position absolute
      top: -1px
      right: -4px

    &-wrap
      position relative

    &-dropdown
      position absolute
      top: 110%
      right: 0
      width: 400px
      box-shadow 0 0 9px 1px rgba(0, 0, 0, 0.2)
      background white
      border-radius 4px
      z-index 10
      overflow auto
      max-height 300px

      div
        padding: 8px 16px
        cursor pointer
        display flex
        align-items center

        span
          margin-right: 8px
          border-radius 100%
          min-width: 16px
          min-height: 16px
          display flex
          align-items center
          justify-content center
          border 1px solid red
          color red
          font-size: 13px

        &:hover
          background #f0f0f0

  &__info
    margin-left: 32px
    position relative


  &__main-info
    display flex
    align-items center
    font-size: 20px
    font-weight: 500
    cursor pointer

    div
      background #f0f0f0
      display flex
      align-items center
      justify-content center
      overflow hidden
      border-radius 4px
      margin-right: 12px
      width: 40px
      height: 40px

      img
        width: 40px
        -o-object-fit: cover;
        object-fit: cover;
        -o-object-position: center center;
        object-position: center center;

    .arrow
      margin-left: 8px

</style>
