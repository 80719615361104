<template>
    <div>
        <div v-if="isPopup" @click="togglePopup" class="mask"></div>
        <transition v-if="isPopup" name="popup">
            <div class="popup">
                <button @click="togglePopup" class="popup__close-btn"></button>
                <h2 v-if="params.includes('archive')">Aus dem Archiv entfernen</h2>
                <h2 v-else>Grund für die Archivierung</h2>
                <BaseFieldInput label="Wählen Sie einen Grund" v-if="!params.includes('archive')">
                    <v-select
                        class="vSelect"
                        :options="reasonOptions"
                        v-model="reason"
                    ></v-select>
                </BaseFieldInput>
                <BaseFieldInput label="Kommentar (fakultativ)" v-if="!params.includes('archive')">
          <textarea
              v-model="comment"
          ></textarea>
                </BaseFieldInput>
                <p style="font-size: 18px; font-weight: 500;" v-if="params.includes('archive')">
                    Sind Sie sicher, dass Sie den Client aus dem Archiv entfernen wollen?
                </p>
                <div class="popup__group-btn">
                    <a @click.prevent="togglePopup" href="#">Abbrechen</a>
                    <button @click.prevent="sendInArchive" class="blue-button">
                        <span></span>
                        <p v-if="params.includes('archive')">Aus dem Archiv entfernen</p>
                        <p v-else>Zum Archiv hinzufügen</p>
                    </button>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import BaseFieldInput from "@/components/fields/BaseFieldInput.vue";
import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select'

export default {
    name: "ReasonForArchiving",
    components: {BaseFieldInput, vSelect},
    props: {
        isArchive: {
            default: 'default'
        }
    },
    data() {
        return {
            isPopup: false,
            reasonOptions: ['one', 'two', 'three'],
            id: null,
            reason: null,
            comment: null,
            params: this.$route.params[this.$route.name === 'customer_page' ? 'id' : 'type']
        }
    },
    methods: {
        togglePopup() {
            this.isPopup = !this.isPopup
        },
        async sendInArchive() {
            try {
                const response = await this.$services.setArchiveCustomers.callMethod({
                    reason_for_client_leaving: this.reason,
                    comment: this.comment,
                    id: this.id
                })
                const result = await response.json()
                if (this.$route.name === 'customer_page') {
                    if (!this.params.includes('archive')) {
                        this.$router.push({
                            name: 'customer_page',
                            params: {
                                id: `archive_${this.id}`
                            }
                        })
                        this.params = `archive_${this.id}`
                    } else {
                        this.$router.push({
                            name: 'customer_page',
                            params: {
                                id: `actual_${this.id}`
                            }
                        })
                        this.params = `actual_${this.id}`
                    }
                }
                this.$emit('archiveSuccess')
                this.$emit('sendArchive')
            } catch (e) {
                this.$emit('archiveError')
            } finally {
                this.togglePopup()
            }
        }
    }
}
</script>

<style scoped lang="stylus">

</style>
<script setup>
</script>