import {required} from "vuelidate/lib/validators";
import router from "vue-router";

export default {
    validations: {
        customer: {
            type_client: {required},
            addressing_customers: {required},
            surname: {required},
            name: {required},
            accommodation_street: {required},
            accommodation_house_number: {required},
            accommodation_postcode: {required},
            accommodation_city: {required},
            personal_phone: {required},
            email: {required},
            date_of_birth: {required},
        },
        customer_address: {
            type_client: {required},
            addressing_customers: {required},
            surname: {required},
            name: {required},
            accommodation_street: {required},
            accommodation_house_number: {required},
            accommodation_postcode: {required},
            accommodation_city: {required},
        },
        customer_contact: {
            personal_phone: {required},
            email: {required},
            date_of_birth: {required},
        },
        counters: {
            counters_type: {required},
            counters_number: {required},
            counter_street: {required},
            counter_house_number: {required},
            counter_postcode: {required},
            counter_city: {required},
        },
        newTask: {
            user_id: {required},
            customer_id: {required},
            type_of_task: {required},
            deadline: {required},
            counter_number: {required},
        },
        type_of_task: {required},
        offer: {
            offer_counters_type: {required},
            offer_cost_monthly: {required},
            offer_cost_per_day: {required},
            qty_month: {required},
            offer_operator: {required},
            number_of_months_warranty: {required},
        },
        offer_cost_per_night: {required},
        report_start_data: {required},
        report_type: {required},
    },
}
