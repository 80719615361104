import Vue from "vue";

export default {
  created() {
    this.$events.messages.messageGetAll()(data => {
      this.$store.commit('setTelegramMessages', data)
      if (this.$route.name === 'tasks_page' || this.$route.name === 'customer_page') {
        for (const t in this.tasks) {
          if (this.tasks[t].task.id === data.id) {
            Vue.set(this.tasks[t].task, 'count_message', 1)
          }
        }
      }
    })
  }
}