<template>
  <svg version="1.0" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
       xmlns:xlink="http://www.w3.org/1999/xlink"
       :width="width" viewBox="0 0 64 64" enable-background="new 0 0 64 64"
       xml:space="preserve">
      <g>
        <path fill="#5A5A5A" d="M60,0H4C1.789,0,0,1.789,0,4v56c0,2.211,1.789,4,4,4h56c2.211,0,4-1.789,4-4V4C64,1.789,62.211,0,60,0z
           M58,42H45c-0.553,0-1,0.447-1,1v5H20v-5c0-0.553-0.447-1-1-1H6V6h52V42z"/>
        <path fill="#5A5A5A"
              d="M15,16h34c0.553,0,1-0.447,1-1s-0.447-1-1-1H15c-0.553,0-1,0.447-1,1S14.447,16,15,16z"/>
        <path fill="#5A5A5A"
              d="M15,28h34c0.553,0,1-0.447,1-1s-0.447-1-1-1H15c-0.553,0-1,0.447-1,1S14.447,28,15,28z"/>
        <path fill="#5A5A5A"
              d="M15,34h34c0.553,0,1-0.447,1-1s-0.447-1-1-1H15c-0.553,0-1,0.447-1,1S14.447,34,15,34z"/>
        <path fill="#5A5A5A"
              d="M15,22h34c0.553,0,1-0.447,1-1s-0.447-1-1-1H15c-0.553,0-1,0.447-1,1S14.447,22,15,22z"/>
      </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      default: '26'
    }
  }
}
</script>