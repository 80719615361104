<template>
    <aside :class="['sidebar', {'sidebar__close': $store.state.isSidebar}]">
        <div @click="$store.commit('setSidebar', $store.state.isSidebar = !$store.state.isSidebar)"
             class="sidebar__burger"><span></span></div>
        <ul>
            <router-link active-class="sidebar__active-link" tag="li" to="/customers/actual">
                <div>
                    <SidebarCustomers/>
                    Kunden
                    <span></span>
                </div>
            </router-link>
            <router-link active-class="sidebar__active-link" tag="li" to="/tasks/">
                <div>
                    <SidebarTasks/>
                    Aufgaben
                    <span></span>
                </div>
            </router-link>
            <router-link active-class="sidebar__active-link" tag="li"
                         to="/telegram-bot/">
                <div>
                    <SidebarTelegramBot/>
                    Telegram Bot
                    <span></span>
                </div>
            </router-link>
            <router-link exact active-class="sidebar__active-link" tag="li" to="/">
                <div>
                    <SidebarStatistics/>
                    Statistik
                    <span></span>
                </div>
            </router-link>
            <router-link active-class="sidebar__active-link" tag="li"
                         to="/customers/archive/">
                <div>
                    <SidebarArchive/>
                    Archiv
                    <span></span>
                </div>
            </router-link>
            <router-link active-class="sidebar__active-link" tag="li"
                         to="/settings/">
                <div>
                    <SidebarSettings/>
                    Einstellungen
                    <span></span>
                </div>
            </router-link>
        </ul>
    </aside>
</template>

<script>
import getUser from "@/mixins/users/getUser";
import SidebarArchive from "@/components/icons/SidebarArchive.vue";
import SidebarCustomers from "@/components/icons/SidebarCustomers.vue";
import SidebarTasks from "@/components/icons/SidebarTasks.vue";
import SidebarTelegramBot from "@/components/icons/SidebarTelegramBot.vue";
import SidebarStatistics from "@/components/icons/SidebarStatistics.vue";
import SidebarSettings from "@/components/icons/SidebarSettings.vue";

export default {
    name: "baseSidebar",
  components: {SidebarSettings, SidebarStatistics, SidebarTelegramBot, SidebarTasks, SidebarCustomers, SidebarArchive},
    mixins: [getUser],
    data() {
        return {
            isSidebar: false
        }
    }
}
</script>

<style lang="stylus">
@import "@/assets/stylus/variables.styl"

.sidebar
    max-width $size-sidebar
    width: 100%
    padding-top: 32px
    height: 100vh
    transition 0.2s
    overflow hidden

    &__close
        max-width 80px

    &__burger
        width: 24px
        height: 24px
        position relative
        margin-top: 100px
        transition 0.2s
        cursor pointer
        margin-left: 21px
        padding: 22px
        border-radius: 100%

        &:hover
            background: #f2f2f2

        span
            width: 24px
            height: 3px
            background $blue
            position absolute
            transform translate(-50%, -50%)
            top: 50%
            left: 50%

            &:after, &:before
                content ''
                display block
                width: 24px
                height: 3px
                background $blue
                position absolute

            &:after
                top: 8px

            &:before
                top: -8px

    &__active-link
        &:after
            content ''
            display block
            height: 40px
            width: 4px
            border-radius 0 2px 2px 0
            background $blue
            position absolute
            left: 0
            top: 50%
            transform translateY(-50%)

        div
            background #F6F8FE
            color $blue

            svg
                path
                    fill $blue

    ul
        margin-top: 50px

        li
            position relative
            cursor: pointer
            padding-right: 32px
            transition 0.2s

            div
                display flex
                align-items center
                padding: 16px 32px
                border-radius 8px
                font-weight: 500
                min-width 210px

                svg
                    margin-right: 24px

</style>
