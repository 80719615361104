import {required} from "vuelidate/lib/validators";

export default {
    validations: {
        user: {
            username: {required},
            last_name: {required},
            first_name: {required},
        },
        password: {required}
    }
}